import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import lock from "../../../assets/images/NewImage/verrouiller-alt.png";
import edit from "../../../assets/images/NewImage/editer.png";
import { useState } from "react";
import userEditProfile from "../../../assets/images/NewImage/user-avatar.png";
import { updateTheUserProfile } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import ReactToPrint from "react-to-print";
import wheelLogo from "../../../assets/images/NewImage/Vault_Wheel.png"
import { ComponentToPrint } from "../PrintCode";
import { DeliveryComponentToPrint } from "../deliveryqrprint";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function UserprofileHeader({ mData }) {
    const [userData, setUserData] = useState({});
    const [isPassword, setIsPassword] = useState(false);
    const [personalSelfieDoc, setPersonalSelfieDoc] = useState([])
    const [isChangePin, setIsChangePin] = useState(false)
    const [actionData, setActionData] = useState({})
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setPersonalSelfieDoc(selectedFile)
        if (selectedFile) {
            handleChange(selectedFile);
        }
    };

    useEffect(() => {
        setUserData(mData?.result);
        let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
        setActionData(jsonparse)
    }, [mData?.subResult?.Permissions])


    // update the user profile
    const handleChange = async (item) => {
        try {
            const formData = new FormData()
            formData.append("ProfileImage", item)
            const response = await updateTheUserProfile(formData)
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            fetchData();
        } catch (error) {
            console.log(error);
        }
    }
    const componentRef = React.useRef();
    const componentRef2 = React.useRef();

    return (
        <>
            <React.Fragment>

                <Row>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center flex-1">
                                        <div
                                            className="text-muted "
                                            style={{
                                                display: "flex",
                                                flexFlow: "column",
                                                gap: "10px",
                                            }}
                                        >
                                            <h3 className="userprofile_left_card_heading">
                                                {userData && userData.FullName}
                                            </h3>
                                            <h6 className="mb-1 userProfile_left_after_heading_text">
                                                Please set your profile image,
                                            </h6>

                                            <p className="mb-0 userProfile_left_after_heading_text">
                                                supported formate jepg, png, bmp, gif, or svg
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                            position: "relative",
                                        }}
                                    >
                                        <img
                                            src={userData?.ProfileImage ? `${API_BASE_URL}/${userData?.id}/profile/${userData?.ProfileImage}` : wheelLogo}
                                            alt="profile"
                                            className="avatar-md rounded-circle img-thumbnail fullwidth"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "fit",
                                            }}
                                        />
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <div>
                                                <img
                                                    src={userEditProfile}
                                                    alt="edit"
                                                    className="Editproflile_user"
                                                    onClick={handleImageClick}
                                                />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div
                                    className="d-flex justify-content-between mt-3 "
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <span className="pt-0" style={{ fontSize: "18px" }}>
                                            Wallet balance
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                            margina: "auto"
                                        }}
                                    >
                                        <span style={{
                                            color: "purple",
                                            fontSize: "18px",
                                            fontFamily: "Gilroy",
                                        }}>
                                            {/* {currenncyRaates.currency || "USD"} {currenncyRaates.currency === "USD" ? "$" : currenncyRaates.currency === "ZWD" ? "Z$" : "$"}{currenncyRaates.convertedAmount || userData.Balance} */}
                                            {userData?.Currency} {userData?.Balance}
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center flex-1">
                                        <div
                                            className="text-muted "
                                            style={{
                                                display: "flex",
                                                flexFlow: "column",
                                                gap: "15px",
                                            }}
                                        >
                                            <h3 className="userprofile_left_card_heading">
                                                {userData && userData.VPA}
                                            </h3>
                                            <h6 className="mb-1 userProfile_left_after_heading_text">
                                                Here&rsquo;s your unique QR Code
                                            </h6>

                                            <p className="mb-0 userProfile_left_after_heading_text me-3">
                                                Use this for sending and receving payments, You can
                                                print this also
                                            </p>
                                            <div className="d-flex gap-3">
                                                <ReactToPrint
                                                    trigger={() => (
                                                        <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                                            Print code
                                                        </Button>
                                                    )}
                                                    content={() => componentRef.current}
                                                />
                                                  <ReactToPrint
                                                    trigger={() => (
                                                        <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                                            Delivery print code
                                                        </Button>
                                                    )}
                                                    content={() => componentRef2.current}
                                                />  
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{ width: "200px", height: "200px" }}
                                        id="printContent"
                                    >
                                        <ReactToPrint content={() => componentRef.current} />
                                        <ComponentToPrint ref={componentRef} userData={userData} />
                                        <div style={{display:'none'}}>
                                        <ReactToPrint content={() => componentRef2.current} />
                                        <DeliveryComponentToPrint ref={componentRef2} userData={userData} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="d-flex justify-content-between mt-3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <img src={lock} alt="lock" />
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            Change password
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                            fontFamily: "Gilroy",
                                        }}
                                    >
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            **********
                                        </span>
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <img
                                                src={edit}
                                                alt="edit"
                                                onClick={() => setIsPassword(true)}
                                                style={{ width: "25px" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between mt-3 mb-3 "
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <img src={lock} alt=" lock" />
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            Reset pin
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            **********
                                        </span>
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <img
                                                src={edit}
                                                alt="edit"
                                                onClick={() => setIsChangePin(true)}
                                                style={{ width: "25px" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </>
    );
}
export default UserprofileHeader;

