import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import MiniWidget from "./GetMiniWidget";
import SalesRevenue from "./SalesRevenue";
import GetNoOfTransaction from "./getNoOfTransaction";
import PaymentInsights from "./PaymentInsights";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import Loader from "components/Common/Loader";
import PinModal from "./PinModal";

const permision = localStorage.getItem("paths");
function getdashboard() {
  document.title = "GET Platform | Dashboard ";
  const { dashboardWidgetData, transactionPerDay, gTotalTranscationPerDay, noOfTranscation, gNoOfTransaction, totalInsights, gTotalInsights, } = useData();
  const [isloading, setIsLoading] = useState(true);
  const [data2, setData2] = useState([]);

  let effact = "effact";
  useEffect(() => {
    if (permision) {
      setTimeout(() => {
        setIsLoading(false);
      }, 0.1);
    } else {
      // reloadCount++;
      // if (reloadCount >= maxReloadAttempts) {
      //   history.push("/logout")
      //   console.log("Maximum reload attempts reached. Logging out...");
      // } else {
      //   setTimeout(() => {
      //     // setIsLoading(true);
      //     window.location.reload();
      //   }, 2000);
      // }

      setTimeout(() => {
        setIsLoading(true);
        window.location.reload();
      }, 2000);
    }
  }, [effact]);


  useEffect(() => {
    if (dashboardWidgetData?.result3) {
      setData2(dashboardWidgetData.result3);
    }
  }, [dashboardWidgetData?.result3]);

  const formattedResult = transactionPerDay.map((item) => [
    new Date(item.TransactionDay).getTime(),
    parseFloat(item.TotalAmount),
  ]);

  const formattedOfTransaction = noOfTranscation?.map((item) => [
    new Date(item.TransactionDay).getTime(),
    item.NumberOfTransactions,
  ]);

  useEffect(() => {
    gTotalTranscationPerDay();
    gNoOfTransaction();
    gTotalInsights();
  }, []);

  useEffect(() => {
    setData2(totalInsights);
  }, [totalInsights]);

  let valume = [
    {
      name: "Revenue",
      data: [...formattedResult],
    },
  ];

  let valume1 = [
    {
      name: "Valum",
      data: [...formattedOfTransaction],
    },
  ];

  return (
    <>
      <React.Fragment>
        {isloading && <Loader />}
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Row>
                  <MiniWidget />
                </Row>
                <Row>
                  <SalesRevenue chartSeries={valume} />
                  <GetNoOfTransaction chartSeries={valume1} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <PaymentInsights data={data2} />
              </Col>
            </Row>
          </Container>
        </div>
        <PinModal />
      </React.Fragment>
    </>
  );
}
export default getdashboard;
