import React from "react";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ComponentToPrint = React.forwardRef(function MyComponent(props, ref) {
  return (
    <div ref={ref}>
      <img
        src={`${API_BASE_URL}/${props?.userData?.id}/qrCode/${props?.userData && props?.userData?.I_QRCode}`}
        alt="qrcode"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </div>
  );
});

ComponentToPrint.displayName = "ComponentToPrint";
