import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row, Table, } from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Edit from "../../assets/images/NewImage/edit.png";
import { fRolesForSelectBox, getPermissionForEdit, merchantPermissionsForSubAdmin, resetMerchantSubAdminPassword } from "Context/ContextApi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import cancel from "../../assets/images/NewImage/cancel.png"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";

const API_BASE_URL = process.env.REACT_APP_API_URL
function AddUserGroup() {
  const { mData } = useData()
  const { id } = useParams();
  const history = useHistory()
  const [isSuccessfulMessage, setIsSuccessfulMessage] = useState(false);
  const [Roles, setRoles] = useState([])
  const [validationErrors, setValidationErrors] = useState({});
  const [image, setImage] = useState(null)
  const [cancelImage, setCanelImage] = useState(false)
  const [isForm, setIsForm] = useState({
    UserName: "",
    Email: "",
    Mobile: "",
    Role: "",
    Description: "",
    Designation: "",
    Department: "",
    Pin: "",
    ConfirmPin: "",
    Image: null,
    Password: "",
    EmlID: "",
  })

  const [permissions, setPermissions] = useState({
    Dashboard: { view: false, action: false },
    Transaction: { view: false, action: false },
    Payments: { view: false, action: false },
    "Deposit money": { view: false, action: false },
    Deposit: { view: false, action: false },
    "Deposit History": { view: false, action: false },
    "Send money": { view: false, action: false },
    Send: { view: false, action: false },
    "Send History": { view: false, action: false },
    "Request money": { view: false, action: false },
    Request: { view: false, action: false },
    "Request History": { view: false, action: false },
    "Withdrawal money": { view: false, action: false },
    Withdrawal: { view: false, action: false },
    "Withdrawal History": { view: false, action: false },
    Profile: { view: false, action: false },
    Campaigns: { view: false, action: false },
    "Create Campaigns": { view: false, action: false },
    "Campaigns History": { view: false, action: false },
    "Campaigns Redeemed": { view: false, action: false },
    Verification: { view: false, action: false },
    Setting: { view: false, action: false },
  });

  const handleViewPermissionChange = (permission) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: {
        ...prevPermissions[permission],
        view: !prevPermissions[permission].view,
      },
    }));
  };

  const handleActionPermissionChange = (permission) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: {
        ...prevPermissions[permission],
        action: !prevPermissions[permission].action,
      },
    }));
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setIsForm({ ...isForm, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }

  const handleImageChange = async (e) => {
    const image = e.target.files[0];
    setImage(image)
    setIsForm({ ...isForm, Image: image })
  }

  const handleCancelImage = (imageKey) => {
    setIsForm({ ...isForm, [imageKey]: null });
    setImage(null)
  };

  const validateForm = () => {
    let errors = {};
    if (!isForm.UserName) {
      errors.UserName = "Full name is required";
    }
    if (!isForm.Email) {
      errors.Email = "Email is required";
    }
    if (!isForm.Mobile) {
      errors.Mobile = "Mobile is required";
    }
    if (!isForm.Role) {
      errors.Role = "Role is required"
    }
    if(!id){
    if (!isForm.Pin) {
      errors.Pin = "Please enter a pin";
    } else if (isForm.Pin.length < 4) {
      errors.Pin = "Pin must be at least 4 characters long";
    } else if (!/^\d{4}$/.test(isForm.Pin)) {
      errors.Pin = "Pin should be numeric and exactly 4 characters long";
    }
    if (!isForm.ConfirmPin) {
      errors.ConfirmPin = "Please confirm your pin";
    } else if (isForm.Pin !== isForm.ConfirmPin) {
      errors.ConfirmPin = "Pins do not match";
    }
  }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => { if (id) { fPermissionForEdit() } }, [id])

  const fPermissionForEdit = async () => {
    try {
      const formData = {
        id: id
      }
      const response = await getPermissionForEdit(formData)
      setIsForm({ ...isForm, ...response.result, Image: null })
      const jsonParse = JSON.parse(response.result.Permissions)
      setPermissions({ ...jsonParse })
      setImage(`${API_BASE_URL}/${mData?.result?.id}/adminProfile/${response.result?.Image}`)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => { fSelectBox() }, [])

  const fSelectBox = async () => {
    try {
      const response = await fRolesForSelectBox()
      setRoles(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const handleFormSubmit = async () => {
    try {
      const isValid = validateForm();
      if (isValid) {
        const data = {
          ...isForm,
          permissions: JSON.stringify(permissions)
        }
        const formData = new FormData()
        for (const [key, value] of Object.entries(data)) {
          if (value instanceof File) {
            formData.append(key, value);
          } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
            value.forEach((file, index) => {
              formData.append(`${key}`, file);
            });
          } else {
            formData.append(key, value);
          }
        }
        const response = await merchantPermissionsForSubAdmin(formData)
        setIsSuccessfulMessage(true);
        history.push("/manage-user-groups")
        toastr.success(response.message, "Success", {timeOut: 7000, progressBar: true, closeDuration:700, positionClass: "toast-top-right"});
      }
    } catch (error) {
      toastr.error(error?.response?.data?.message ||  error?.message, "Success", {timeOut: 7000, progressBar: true, closeDuration:700, positionClass: "toast-top-right" });
    }
  };

  useEffect(() => {
    if (isForm?.Image) {
      const reader = new FileReader();
      reader.readAsDataURL(isForm?.Image);
      reader.onload = () => {
        setImage(reader?.result);
      };
    }
  }, [isForm?.Image]);

  const handleResetThePassword = async () => {
    try {
      const formData = { id: id }
      const response = await resetMerchantSubAdminPassword(formData)
      toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true, 
        closeDuration:700,
        positionClass: "toast-top-right" 
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="Add user " />
          <Container>
            <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  <h1 className="text-center all_newPaeHeading title-color-common">ADD USERS</h1>
                  <h5 className="text-center all_sub_new_heading description-color">
                    Create new users to access GET Vault, decide their
                    hierarchy, manage access to information across dashboard
                    sections
                  </h5>
                </Col>
              </Row>
              <CardBody>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Full Name
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter Full Name"
                      name="UserName"
                      value={isForm.UserName}
                      onChange={handleChanges}
                      required
                    />
                    {validationErrors.UserName && <span className="Onbording-error-message">{validationErrors.UserName}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Email
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="email"
                      placeholder="Enter Email"
                      name="Email"
                      value={isForm.Email}
                      onChange={handleChanges}
                      required
                    />
                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Mobile
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      placeholder="Enter Number"
                      name="Mobile"
                      value={isForm.Mobile}
                      min={0}
                      onChange={handleChanges}
                      required
                    />
                    {validationErrors.Mobile && <span className="Onbording-error-message">{validationErrors.Mobile}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Select Role
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select form_in_offer_input_box"
                      name="Role"
                      value={isForm.Role}
                      onChange={handleChanges}
                      required
                    >
                      <option value="" defaultValue hidden>
                        Select
                      </option>
                      {Roles?.map((item, index) => (
                        <option key={index} value={item.Role}>{item.Role}</option>
                      ))}
                    </select>
                    {validationErrors.Role && <span className="Onbording-error-message">{validationErrors.Role}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label">
                    Designation
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter Designation"
                      name="Designation"
                      value={isForm.Designation}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"                  >
                    Department
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter Department"
                      name="Department"
                      value={isForm.Department}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"                  >
                    Emp ID
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter Eml ID"
                      name="EmlID"
                      value={isForm.EmlID}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Pin
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      placeholder="Enter Pin"
                      name="Pin"
                      value={isForm.Pin}
                      min={0}
                      max={9999}
                      maxLength={4}
                      onChange={handleChanges}
                    />
                    {validationErrors.Pin && <span className="Onbording-error-message">{validationErrors.Pin}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Confirm Pin
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      placeholder="Enter Confirm Pin"
                      name="ConfirmPin"
                      value={isForm.ConfirmPin}
                      min={0}
                      max={9999}
                      maxLength={4}
                      onChange={handleChanges}
                    />
                    {validationErrors.ConfirmPin && <span className="Onbording-error-message">{validationErrors.ConfirmPin}</span>}
                  </div>
                </div>
                {isForm.Password && (
                  <div className="mb-3 row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-3 col-form-label fomr_in_offer_label"                  >
                      Password
                    </label>
                    <div className="col-md-9 d-flex gap-3">
                      <input
                        className="form-control form_in_offer_input_box"
                        type="number"
                        placeholder="Password"
                        name="Password"
                        value={isForm.Password}
                      />
                      <div><Button onClick={handleResetThePassword}>Reset</Button></div>
                    </div>
                  </div>
                )}
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Description
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter description"
                      name="Description"
                      value={isForm.Description}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Image
                  </label>
                  <div className="col-md-9">
                    <Input
                      className="form-control form_in_offer_input_box"
                      type="file"
                      placeholder="Upload image"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                  </label>
                  <div className="col-md-9">
                    {image &&
                      <div className="offers_file_img" onMouseEnter={() => setCanelImage(!cancelImage)}
                        onMouseLeave={() => setCanelImage(!cancelImage)}>
                        <img src={image} alt="*" className="admin_profil_file_img_readingImage" />
                        {cancelImage && (<img src={cancel} alt="*" onClick={() => handleCancelImage('Image')} className="offers_file_img_cancel" />)}
                      </div>
                    }
                  </div>
                </div>

                <div>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="th-styled">Permissions </th>
                          <th className="th-styled">
                            View{" "}
                            <img
                              src={Edit}
                              style={{ width: "20px", marginLeft: "5px" }}
                            />
                          </th>
                          <th className="th-styled">
                            Action{" "}
                            <img
                              src={Edit}
                              style={{ width: "20px", marginLeft: "5px" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(permissions).map(([permission, { view, action }]) => (
                          <tr key={permission}>
                            <td className="tr-td-styled">{permission}</td>
                            <td>
                              <input
                                type="checkbox"
                                className="tr-td-input-check-box"
                                checked={view}
                                onChange={() => handleViewPermissionChange(permission)}
                              />
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="tr-td-input-check-box"
                                checked={action}
                                onChange={() => handleActionPermissionChange(permission)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <Row className="mt-5 mb-5">
                  <Col
                    lg="9"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "40%",
                      }}
                    >
                      <Link to="/manage-user-groups">
                        <Button className="procces_Button">Cancel</Button>
                      </Link>
                      <Button
                        className="procces_Button"
                        onClick={handleFormSubmit}
                      >
                        {id ? "Update":"Add"}
                      </Button>
                    </div>
                  </Col>
                  <Col
                    lg="3"
                  >
                    {isSuccessfulMessage && (
                      <h6
                        className="text-center"
                        style={{
                          fontFamily: "Gilroy",
                          fontSize: "18px",
                          color: "green",
                        }}
                      >
                        New role created &#10004;
                      </h6>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AddUserGroup;
