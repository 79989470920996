import React from 'react';

const RefundPaymentDetails = ({ refndTransactionDetails }) => {
    let statusMessage = '';
    let statusClass = '';

    switch (refndTransactionDetails.Status) {
        case 'failed':
            statusMessage = 'Failed';
            statusClass = 'status-failed';
            break;
        case 'completed':
            statusMessage = 'Completed';
            statusClass = 'status-completed';
            break;
        case 'Cancelled':
            statusMessage = 'Cancelled';
            statusClass = 'status-cancelled';
            break;
        case 'pending':
            statusMessage = 'Pending';
            statusClass = 'status-pending';
            break;
        case 'Rejected':
            statusMessage = 'Rejected';
            statusClass = 'status-pending';
            break;
        case 'Expired':
            statusMessage = 'Expired';
            statusClass = 'status-pending';
            break;
        case 'void':
            statusMessage = 'void';
            statusClass = 'status-pending';
            break;
        default:
            statusMessage = 'Pending';
            statusClass = 'status-unknown';
            break;
    }

    return (
        <h3 className={`refund_payment_details_status ${statusClass}`}>
            {statusMessage}
        </h3>
    );
};

export default RefundPaymentDetails;