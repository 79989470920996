
import React, { useEffect, useState } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import ThemeForm from "../form";
import { Card, Col, Container, Row, CardBody } from "reactstrap";
import { getThemesList } from "Context/ContextApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ThemesUpdate = () => {
    const id = useParams();
    const [data, setData] = useState({})

    useEffect(() => {
        fetchTheMerchantTransacrionHistory(id)
    }, [id])

    const fetchTheMerchantTransacrionHistory = async (uId) => {
        try {
            const formData = {
                id: uId.id,
            }
            const response = await getThemesList(formData)
            setData(response.result)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Update theme " />
                    <Container>
                        <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            <Row>
                                <Col md={{ offset: 3, size: 6 }}>
                                    <h1 className="text-center all_newPaeHeading title-color-common">UPDATE USERS</h1>
                                    <h5 className="text-center all_sub_new_heading description-color">
                                        Update theme to access GET Vault, decide their
                                        hierarchy, manage access to information across dashboard
                                        sections
                                    </h5>
                                </Col>
                            </Row>
                            {data &&<ThemeForm data={data} />}
                        </Card>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ThemesUpdate;
