import { sendQrToUserForPayment } from 'Context/ContextApi';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const NumberInputModal = () => {
    const [modal, setModal] = useState(false);
    const [number, setNumber] = useState('');

    const toggle = () => setModal(!modal);

    const handleNumberChange = (e) => {
        setNumber(e.target.value);
    };

    const handleSubmit = async () => {
        if (!number) {
            toastr.warning("Number is required");
            return;
        } else if (isNaN(number) || number <= 0) {
            toastr.warning("Please enter a valid number greater than 0");
            return;
        }
        try {
            const formData = {
                Mobile: number
            }
            const response = await sendQrToUserForPayment(formData)
            toastr.success(response.message)
            setNumber('')
            toggle();
        } catch (error) {
            console.log(error);

        }
    };

    return (
        <div>
            <Button color="primary" onClick={toggle}>
                Request payment
            </Button>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Request Payment</ModalHeader>
                <ModalBody>
                    <Input
                        type="number"
                        placeholder="Enter number"
                        value={number}
                        onChange={handleNumberChange}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default NumberInputModal;
