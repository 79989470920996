import React, { useEffect } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import { editRequestMoney, requestMoneyThroughScanPay } from "Context/ContextApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import InitiateScanPay from "..";

function EditScanPay() {
    const { id } = useParams();
    const { iPAddress, mData, userDataa, socketScanPay, setSocketScanPay } = useData();
    useEffect(() => {
        handleScanPay(id)
    }, [id])
    const handleScanPay = async (item) => {
        try {
            const formData = {
                id: item,
            };
            const response = await editRequestMoney(formData)
            setSocketScanPay(response.result)
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }


    return (
        <React.Fragment>
                <InitiateScanPay />
        </React.Fragment>
    );
}
export default EditScanPay;
