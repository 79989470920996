
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import { deleteTheme, getThemesList, updateThemeStatus } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useData } from "Context/ContextProvider";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Toggle from "react-toggle";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import pencile from "../../assets/images/NewImage/pencil.png";
import deleteimg from "../../assets/images/NewImage/supprimer.png";


const ThemesList = () => {
    const ref = useRef();
    const { userDataa } = useData();
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("");

    const sortedProducts = [...data];
    sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));

    useEffect(() => {
        fetchTheMerchantTransacrionHistory()
    }, [userDataa])


    const fetchTheMerchantTransacrionHistory = async () => {
        try {
            const formData = {
                id: '',
            }
            const response = await getThemesList(formData)
            setData(response.result)
        } catch (error) {
            console.log(error);
        }
    }

    const handleToggle = async (id, isChecked, reset) => {
        try {
            const formData = {
                id: id,
                Status: `${isChecked ? "ACTIVE" : "INACTIVE"}`,
                Reset: reset
            }
            const response = await updateThemeStatus(formData)
            toastr.success(response.message)
            fetchTheMerchantTransacrionHistory();
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
            console.log(error);
        }
    };

    const handleDeleteTheme = async (id) => {
        try {
            const formData = {
                id: id
            }
            const response = await deleteTheme(formData)
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            setData(response.result);
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        {
            dataField: "CreatedOn",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "Name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "ApplicableOn",
            text: "Applicable On",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cell, row) => (
                <div className="campaign_check_box_Active_InActive">
                    <Toggle
                        aria-labelledby='biscuit-label'
                        id={`toggle-${row.id}`}
                        defaultChecked={row.Status === "ACTIVE"}
                        onChange={(e) => handleToggle(row.id, e.target.checked)}
                    />
                </div>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cell, row) => (
                <span className="d-flex gap-3" >
                    <Link to={`/themes/update/${row.id}`} className="item-end">
                        <img src={pencile} alt="icon" style={{ width: "20px" }} />
                    </Link>
                    <img src={deleteimg} alt="delete" style={{ width: "25px", cursor: "pointer" }} onClick={() => handleDeleteTheme(row.id)} />
                </span>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: data.length,
        custom: true,
    };

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
                <Breadcrumbs title="GET" breadcrumbItem="Themes" />
                <Row>
                    <Col md={{ offset: 3, size: 6, }} >
                        <h2 className="text-center mt-5 mb-4 title-color-common" style={{ fontFamily: "Gilroy", }}>THEMES</h2>
                        <p className="text-center heading_description mb-5 description-color"> List of all themes</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", }} className="mb-4">
                            <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    gap: "20px",
                                }}
                                >
                                    <Link to="/themes/create" className="item-end">
                                        <Button
                                            type="submit"
                                            style={{ fontSize: "16px", fontFamily: "Gilroy" }}
                                            className="procces_Button"
                                        >
                                            + Create Themes
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "20px",
                            }}
                            >
                                <Button
                                    type="submit"
                                    style={{ fontSize: "16px", fontFamily: "Gilroy" }}
                                    className="procces_Button"
                                    onClick={() => handleToggle("", "", "reset")}
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                        <Card className="mt-5">
                            <CardBody>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField="id"
                                    columns={columns}
                                    data={data}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider keyField="id" columns={columns} data={data} search exportCSV>
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    // id="my-table"
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    //   selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    style={{
                                                                        fontWeight: "Gilroy",
                                                                    }}
                                                                    headerClasses={"custom-header-class"}
                                                                    rowStyle={{
                                                                        fontSize: "15px",
                                                                        fontFamily: "Gilroy",
                                                                        fontWeight: "400",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>

                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default ThemesList;
