
import React from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import ThemeForm from "../form";
import { Card, Col, Container, Row, CardBody } from "reactstrap";

const ThemesCreate = () => {
    return (
        <React.Fragment>
            <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Create theme " />
                    <Container>
                        <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            <Row>
                                <Col md={{ offset: 3, size: 6 }}>
                                    <h1 className="text-center all_newPaeHeading title-color-common">ADD USERS</h1>
                                    <h5 className="text-center all_sub_new_heading description-color">
                                        Create new users to access GET Vault, decide their
                                        hierarchy, manage access to information across dashboard
                                        sections
                                    </h5>
                                </Col>
                            </Row>
                            <ThemeForm />
                        </Card>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ThemesCreate;
