import { startPaymentRequest } from "Context/ContextApi"; 
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export const StartPaymentModal = ({ mData,  }) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    
    useEffect(() => {
        if(mData?.result && mData?.result?.ScanningStatus === 'DISABLE'){
            setShowPaymentModal(true)
        }
    }, [mData?.result]);

    const handleStartPayment = async () => {
        try {
            const formData = {
                ScanningStatus: 'ENABLE'
            };
            const response = await startPaymentRequest(formData);
            setShowPaymentModal(false); 
            toastr.success(response.message); 
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message); 
        }
    };

    const handleCancelPayment = () => {
        setShowPaymentModal(false);
    };

    return (
        <React.Fragment>
            <Modal centered isOpen={showPaymentModal} toggle={handleCancelPayment}>
                <ModalHeader toggle={handleCancelPayment}>Start Payment</ModalHeader>
                <ModalBody>
                    Are you sure you want to start taking payment for this order?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleStartPayment}>
                        Start Payment
                    </Button>
                    <Button color="secondary" onClick={handleCancelPayment}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
