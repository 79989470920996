import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { deleteOffers, gMerchantOffersForEdit, gMerchantType, updateAndAddMerchantOffers } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import cancel from "../../assets/images/NewImage/cancel.png"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useData } from "Context/ContextProvider";
// import CustomTooltip from "./CustomTooltip";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function CreateOffer() {
  const { id } = useParams();
  const history = useHistory();
  const fileInputRef = useRef(null);
  const { mData, iPAddress } = useData();
  const [validationErrors, setValidationErrors] = useState({});
  const [types, setTypes] = useState([]);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showOfferInput, setShowOfferInput] = useState(false);
  const [selectedFileKey, setSelectedFileKey] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDelete, setIsDelete] = useState('');
  const [error, setError] = useState('');
  const [cancelImage, setCanelImage] = useState(false);
  const [description, SetDescription] = useState()

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      ['blockquote', 'code-block'],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    'code-block'
  ];

  const [formData, setFormData] = useState({
    id: "",
    OffersName: "",
    Type: "",
    Method: "",
    StartDate: "",
    EndDate: "",
    DiscountPercentage: "",
    AllowedMaxDiscount: "",
    MinTransactionAmount: "",
    MaxTotalUsage: "",
    OfferCode: "",
    Image: null,
    Description: "",
    IPAddress: iPAddress
  });

  const effact = "effact"
  useEffect(() => { handleFetchMerchantType() }, [effact]);
  useEffect(() => { if (id) handleMerchantOffers(); }, [id, effact]);

  const handleEditorStateChange = (newEditorState) => {
    // setFormData({ ...formData, Description: newEditorState })
    SetDescription(newEditorState)
  };
  const handleMerchantOffers = async () => {
    try {
      const formData = { id: id }
      const response = await gMerchantOffersForEdit(formData);

      // setFormData({
      //   ...formData, ...response?.result, StartDate: response.result.StartDate.slice(0, 16),
      //   EndDate: response.result.EndDate.slice(0, 16), Image: null
      // });
      setFormData({
        id: response?.result?.id,
        OffersName: response?.result?.OffersName,
        Type: response?.result?.Type,
        Method: response?.result?.Method,
        StartDate: response?.result?.StartDate.slice(0, 16),
        EndDate: response?.result?.EndDate.slice(0, 16),
        DiscountPercentage: response?.result?.DiscountPercentage,
        AllowedMaxDiscount: response?.result?.AllowedMaxDiscount,
        MinTransactionAmount: response?.result?.MinTransactionAmount,
        MaxTotalUsage: response?.result?.MaxTotalUsage,
        OfferCode: response?.result?.OfferCode,
        // Description: response?.result?.Description,
      });
      SetDescription(response?.result?.Description)
      // setFormData({
      //   ...formData,
      //   Description: response?.result?.Description,
      // });

      setImageSrc(`${API_BASE_URL}/${mData?.result?.id}/campaigns/${response.result?.Image}`)
    } catch (error) {
      console.log(error);
    }
  }

  const handlechanges = (e) => {
    const { name, value } = e.target;
    let errors = { ...validationErrors };
    if (value.toLowerCase() === "plas") {
      setShowCustomInput(true);
      setFormData({ ...formData, Type: "" });
    } else if (value === "OfferCode") {
      setShowOfferInput(true);
      setFormData({ ...formData, Method: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    errors[name] = undefined;
    setValidationErrors(errors);
  };

  const handleCustomInputChange = (e) => {
    setFormData({
      ...formData,
      Type: e.target.value
    });
  };

  const handleUploadClick = (key) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setSelectedFileKey(key);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFileKey) {
      if (!(selectedFile instanceof Blob)) {
        console.error('Selected file is not a valid Blob object.');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const width = image.width;
          const height = image.height;
          if ((width === 320 && height === 100) || (width === 640 && height === 200)) {
            setFormData({ ...formData, [selectedFileKey]: selectedFile });
            setSelectedFileKey(null);
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              [selectedFileKey]: undefined,
            }));
          } else {
            setValidationErrors((prevErrors) => ({
              ...prevErrors,
              [selectedFileKey]: "Upload images in 320x100 pixels. For better resolution, opt for 640x200 pixels.",
            }));
          }
        };
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleCancelImage = (imageKey) => {
    setFormData({ ...formData, [imageKey]: null });
    setImageSrc(null)
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.OffersName.trim()) {
      errors.OffersName = "Offers Name is required";
    }
    if (!formData.Type.trim()) {
      errors.Type = "Type is required";
    }
    if (!formData.Method.trim()) {
      errors.Method = "Method is required";
    }
    if (!formData.StartDate.trim()) {
      errors.StartDate = "Start Date is required";
    }
    if (!formData.EndDate.trim()) {
      errors.EndDate = "EndDate is required"
    }
    if (!formData.AllowedMaxDiscount.trim()) {
      errors.AllowedMaxDiscount = "Allowed Maximum Discount is required"
    }
    if (!formData.MinTransactionAmount.trim()) {
      errors.MinTransactionAmount = "Minimum Transaction Amount is required"
    }
    if (parseFloat(formData.AllowedMaxDiscount) >= parseFloat(formData.MinTransactionAmount)) {
      errors.AllowedMaxDiscount = "Allowed Maximum Discount must be less than Minimum Transaction Amount";
    }
    if (!formData.MaxTotalUsage.trim()) {
      errors.MaxTotalUsage = "Maximum Total Usage is required"
    }
    if (!imageSrc) {
      errors.Image = "Please upload a document file";
    } else {
      const fileValidationError = validateFile(imageSrc);
      if (fileValidationError) {
        errors.Image = fileValidationError;
      }
    }
    // if (!formData.Description) {
    //   errors.Description = "Description is required"
    // }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateFile = (file) => {
    if (!file) {
      return "Please upload a file";
    }
    return null;
  };

  const handleAddAndUpdateOffers = async () => {
    try {
      const isValid = validateForm();
      if (isValid) {
        const isForm = {
          ...formData,
          Description: description
        }
        const isData = new FormData()
        for (const [key, value] of Object.entries(isForm)) {
          if (value instanceof File) {
            isData.append(key, value);
          } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
            value.forEach((file, index) => {
              isData.append(`${key}`, file);
            });
          } else {
            isData.append(key, value);
          }
        }
        const response = await updateAndAddMerchantOffers(isData)
        history.push("/campaigns")
        toastr.success(response.message, "Success", {
          timeOut: 7000,
          progressBar: true,
          closeDuration: 700,
          positionClass: "toast-top-right"
        });
        setFormData({
          id: "",
          OffersName: "",
          Type: "",
          Method: "",
          StartDate: "",
          EndDate: "",
          DiscountPercentage: "",
          AllowedMaxDiscount: "",
          MinTransactionAmount: "",
          MaxTotalUsage: ""
        });
      }
    } catch (error) {
      if (error) {
        toastr.error(error.response?.data?.message || error?.message)
      }
    }
  }

  const handleFetchMerchantType = async () => {
    try {
      const response = await gMerchantType();
      setTypes(response.result);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (formData.Image) {
      const reader = new FileReader();
      reader.readAsDataURL(formData.Image);
      reader.onload = () => {
        setImageSrc(reader.result);
      };
    }
  }, [formData.Image]);



  const handleConfirmDelete = async (id) => {
    try {
      if (isDelete !== 'delete') {
        setError("Enter 'delete' ");
        return;
      }
      const formData = {
        id: id,
        IPAddress: iPAddress
      }
      const response = await deleteOffers(formData)
      toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true,
        closeDuration: 700,
        positionClass: "toast-top-right"
      });
      history.push("/campaigns")
    } catch (error) {
      console.log(error);
    }
  }

  document.title = `GET Platform | ${id ? "Update - Campaigns" : "Create - Campaigns"} `
  return (
    <React.Fragment>

      <Modal centered isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          <label className=" fomr_in_offer_label">
            Confirmation
          </label>
        </ModalHeader>
        <ModalBody>
          <label
            className=" fomr_in_offer_label"
          >
            Enter DELETE to Confirm
          </label>

          <Input type="text" placeholder="Enter DELETE to Confirm" value={isDelete} onChange={(e) => setIsDelete(e.target.value)} />
          {error && <span className="Onbording-error-message">{error}</span>}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleConfirmDelete(id)}>Delete</Button>
          <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
        <div className="container-fluid">
          <Breadcrumbs title="Offer" breadcrumbItem="Create Offer" />
          <Container>
            <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  <h1 className="text-center all_newPaeHeading title-color-common">CREATE OFFER</h1>
                  <h5 className="text-center all_sub_new_heading description-color ">
                    Create and customize campaigns for your users for different occasions
                  </h5>
                </Col>
              </Row>
              <CardBody>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Offer name
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter offer name"
                      name="OffersName"
                      value={formData.OffersName}
                      onChange={handlechanges}
                    />
                    {validationErrors.OffersName && <span className="Onbording-error-message">{validationErrors.OffersName}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-search-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Type
                  </label>
                  <div className="col-md-9">
                    {!showCustomInput && (
                      <select
                        className="form-select form_in_offer_input_box"
                        name="Type"
                        value={formData.Type}
                        onChange={handlechanges}
                      >
                        <option defaultValue hidden>
                          Select the type
                        </option>
                        {types?.map((item, index) => (
                          <option key={index} value={item.Type}>{item.Type}</option>
                        ))}
                        <option value="Plas">Create Type</option>
                      </select>
                    )}
                    {showCustomInput && (
                      <input
                        type="text"
                        className="form-control form_in_offer_input_box"
                        placeholder="Enter the Type"
                        value={formData.Type}
                        onChange={handleCustomInputChange}
                      />
                    )}
                    {validationErrors.Type && <span className="Onbording-error-message">{validationErrors.Type}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-search-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Method
                  </label>
                  <div className="col-md-9">
                    <select className="form-select form_in_offer_input_box"
                      name="Method"
                      value={formData.Method}
                      onChange={handlechanges} >
                      <option defaultValue hidden>
                        Select the method
                      </option>
                      <option value="ScanAndPay">Scan And Pay</option>
                      <option value="GetMoney">Get Money</option>
                      <option value="OfferCode">Offer Code</option>
                    </select>
                    {validationErrors.Method && <span className="Onbording-error-message">{validationErrors.Method}</span>}
                  </div>
                </div>
                {showOfferInput && (
                  <div className="mb-3 row">
                    <label htmlFor="example-search-input" className="col-md-3 col-form-label fomr_in_offer_label">
                      Offer Code
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control form_in_offer_input_box"
                        placeholder="Enter the Type"
                        name="OfferCode"
                        value={formData.OfferCode}
                        onChange={handlechanges}
                      />
                      {validationErrors.OfferCode && <span className="Onbording-error-message">{validationErrors.OfferCode}</span>}
                    </div>
                  </div>
                )}
                <div className="mb-3 row">
                  <label
                    htmlFor="example-datetime-local-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Start date & time
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="datetime-local"
                      id="example-datetime-local-input"
                      name="StartDate"
                      value={formData.StartDate}
                      onChange={handlechanges}
                    />
                    {validationErrors.StartDate && <span className="Onbording-error-message">{validationErrors.StartDate}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-datetime-local-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    End date & time
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="datetime-local"
                      id="example-datetime-local-input"
                      name="EndDate"
                      value={formData.EndDate}
                      onChange={handlechanges}
                    />
                    {validationErrors.EndDate && <span className="Onbording-error-message">{validationErrors.EndDate}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-search-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    <span>Discount percentage </span>
                    <small className="">(If applicable)</small>
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      name="DiscountPercentage"
                      placeholder="Enter Discount Percentage"
                      value={formData.DiscountPercentage}
                      onChange={handlechanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-url-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Allowed maximum discount*
                    <small>(Amount)</small>
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      placeholder="Enter maximum discount"
                      type="number"
                      name="AllowedMaxDiscount"
                      value={formData.AllowedMaxDiscount}
                      onChange={handlechanges}
                    />
                    {validationErrors.AllowedMaxDiscount && <span className="Onbording-error-message">{validationErrors.AllowedMaxDiscount}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-tel-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    <span> Minimum transaction </span>
                    <small>(If applicable)</small>
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      placeholder="Enter Minimum Transaction"
                      name="MinTransactionAmount"
                      value={formData.MinTransactionAmount}
                      onChange={handlechanges}
                    />
                    {validationErrors.MinTransactionAmount && <span className="Onbording-error-message">{validationErrors.MinTransactionAmount}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-password-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    <span>Maximum total usage </span>
                    <small>(Number of times per user)</small>
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="number"
                      placeholder="Enter maximum total usage"
                      name="MaxTotalUsage"
                      value={formData.MaxTotalUsage}
                      onChange={handlechanges}
                    />
                    {validationErrors.MaxTotalUsage && <span className="Onbording-error-message">{validationErrors.MaxTotalUsage}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-password-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    <span>Image </span>
                  </label>
                  <div className="col-md-9">
                    <div className="onboarding_form_file_button">
                      <button onClick={() => handleUploadClick('Image')}>Upload</button>
                    </div>
                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                    {imageSrc &&
                      <div className="offers_file_img" onMouseEnter={() => setCanelImage(!cancelImage)}
                        onMouseLeave={() => setCanelImage(!cancelImage)}>
                        <img src={imageSrc} alt="*" className="offers_file_img_readingImage" />
                        {cancelImage && (<img src={cancel} alt="*" onClick={() => handleCancelImage('Image')} className="offers_file_img_cancel" />)}
                      </div>
                    }
                    {validationErrors.Image && <span className="Onbording-error-message">{validationErrors.Image}</span>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-password-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    <span>Image </span>
                  </label>
                  <div className="col-md-9">
                    <div className="create-offer-editor-div" >
                      <ReactQuill
                        modules={modules}
                        formats={formats}
                        className="ReactQuill_css"
                        value={description}
                        onChange={handleEditorStateChange}
                        placeholder="Description"
                      />
                    </div>
                    {validationErrors.Description && <span className="Onbording-error-message">{validationErrors.Description}</span>}
                  </div>
                </div>

                <Row className="mb-4 mt-5">
                  <Col md={{ offset: 3, size: 6 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button className="procces_Button" onClick={handleAddAndUpdateOffers}>Submit</Button>
                      {id && (
                        <Button className="procces_Button"
                          onClick={() => setModalOpen(!modalOpen)}
                        >Delete</Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CreateOffer;
// onClick={()=>handleDelete(id)}
