import React from "react";

const ProgressBar = ({ currentStep }) => {
  return (
    <>
      <div className="progress_bar_main mt-4">
        <div className={`progress_bar_one ${currentStep >= 1 ? "Progress_bar_active procces_Button" : ""}`}></div>
        <div className={`progress_bar_one ${currentStep >= 2 ? "Progress_bar_active procces_Button" : ""}`}></div>
        <div className={`progress_bar_one ${currentStep === 3 ? "Progress_bar_active procces_Button" : ""}`}></div>
      </div>
      {currentStep === 1 && (
        <p className="text-center mt-4 progress_bar_description_text">
          You can deposit to your wallets using our popular
          payment methods. Fill the details correctly & the
          amount you want to deposit.
        </p>
      )}
      {currentStep === 2 && (
        <p className="text-center mt-4 progress_bar_description_text">
          Check your deposit information before confirmation.
        </p>
      )}
    </>
  );
};

export default ProgressBar;

