import React from "react";
import { Modal, Button, FormGroup, Label, Input } from "reactstrap";

const PinModal = ({
  isOpen,
  pin,
  setPin,
  pinError,
  handleCheckAndDeposit,
  setPopupOpen,
  isPinButton,
}) => {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-content">
        <div className="modal-header border-bottom-0">
          <button
            type="button"
            className="btn-close"
            onClick={() => setPopupOpen(false)}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <h4 className="model_heading_text title-color-common">Verify Your PIN</h4>
                <FormGroup>
                  <Label for="pin" className="generate_pin_label">
                    PIN:
                  </Label>
                  <Input
                    type="password"
                    name="pin"
                    id="pin"
                    placeholder="Enter your PIN"
                    className="css_for_generatePin_input"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                  />
                  {pinError && <small className="text-danger">{pinError}</small>}
                </FormGroup>
                {isPinButton && (
                  <Button
                    type="button"
                    className="model_confirm_button procces_Button"
                    onClick={handleCheckAndDeposit}
                  >
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PinModal;
