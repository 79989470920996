import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory } from "react-router-dom";
import { useData } from "Context/ContextProvider";
import logosrc from "../../../assets/images/NewImage/Invoice-Logo.png";
import { addToWhiteListThisDevice, generateWhiteListOtp, merchanInformation, validateCaptchaOtp } from "Auth/ApiFunction";
import Cookies from "js-cookie";
import CaptchaLoginFooter from "components/Common/captcha-login-otp";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GenerateDeviceId } from "components/Common/generateDeviceId";

const WhiteList = () => {
    document.title = "GET Platform | White List";
    const { url } = useParams();
    const [deviceId, setDeviceId] = useState('');
    const { iPAddress, userInfo } = useData();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isDevice, setIsDevice] = useState(false)

    useEffect(() => {
        const deviceData = GenerateDeviceId();
        setDeviceId(deviceData);
    }, [!deviceId]);


    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value === "" || /^[a-zA-Z0-9]$/.test(value)) {
            const newOtp = otp.slice();
            newOtp[index] = value;
            setOtp(newOtp);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
            if (newOtp.every(char => char !== "")) {
                handleSubmit(newOtp);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const newOtp = otp.slice();
            newOtp[index] = "";
            setOtp(newOtp);
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, 6);
        if (/^[a-zA-Z0-9]{1,6}$/.test(pasteData)) {
            const newOtp = [...pasteData.split("")];
            setOtp(newOtp);
            if (newOtp.every(char => char !== "")) {
                handleSubmit(newOtp);
            }
        }
        e.preventDefault();
    };


    const handleSubmit = async (otpValueArray) => {
        try {
            const otpValue = otpValueArray.join("");
            if (otpValue.length < 6) {
                toastr.error("Please enter the full OTP.");
                return;
            }
            const formData = {
                otp: otpValue,
                IPAddress: iPAddress,
                DeviceId: deviceId,
                URL: url,
                Latitude: userInfo?.location?.lat,
                Longitude: userInfo?.location?.lon,
            };
            const response = await addToWhiteListThisDevice(formData);
            console.log(response);
            setIsDevice(response?.result)
            toastr.success(response?.message)
        } catch (error) {
            console.log(error);
            toastr.error(error?.response?.data?.message || error?.message);
            setOtp(new Array(6).fill(""));
        }
    };


    const handleRequestOTP = async () => {
        try {
            if (!deviceId) {
                toastr.error("Please refresh the page");
                return;
            }
            if (!url) {
                toastr.error("Please Enter valid url");
                return;
            }
            const formData = {
                DeviceId: deviceId,
                URL: url,
            };
            const response = await generateWhiteListOtp(formData);
            console.log(response);
            setIsDevice(response?.result)
            toastr.success(response?.message)
        } catch (error) {
            console.log(error);
            toastr.error(error?.response?.data?.message || error?.message);
        }
    };


    return (
        <React.Fragment>
            <div className="registration-main-section">
                <div className="captcha_otp_logo mt-5"> <a href="login"> <img src={logosrc} alt="logo" /> </a> </div>
                {!isDevice ? (
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <div className="login-form-heading-text-center mt-3"> <h1> <b> Check your app for a code </b></h1></div>
                            <div className="otp-box mt-5 ">
                                <Form className="captcha-container">
                                    <FormGroup className="d-flex justify-content-center captcha-otp-input" onPaste={handlePaste}>
                                        {otp.map((data, index) => (
                                            <Input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={data}
                                                onChange={(e) => handleChange(e, index)}
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                className="otp-field text-center"
                                            />
                                        ))}
                                    </FormGroup>
                                </Form>
                                <div className="request-button-on-white-list">
                                    <Button className="procces_Button" onClick={handleRequestOTP} >Request OTP</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={12}>
                            <div className="login-form-heading-text-center text-center mt-3"> <h1> <b> Device Whitelisted </b> </h1> 
                                <p><b>This device is already in the whitelist. You do not need to add it again.</b></p>
                                </div>
                        </Col>
                    </Row>
                )}
            </div>
            <CaptchaLoginFooter />
        </React.Fragment>
    );
};

export default WhiteList;
