import React, { useEffect, useRef, useState } from "react";
import './RegisterNewStyle.scss'
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import cancel from "../../assets/images/NewImage/cancel.png"
import { nationalidCheck, sendOtp, validationOtpcheck, verifyTheNationalId } from "Auth/ApiFunction";
import toastr, { error, success } from "toastr";
import "toastr/build/toastr.min.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FileUpload, { addressProofText, bussinessproofText, sentenceMap, trandingLicenceText } from "./RegisterFile";
import { fetchMerchantProfile, updateMerchantRegisterForm } from "Context/ContextApi";
import Merchanttlogo from "../../assets/images/NewImage/Invoice-Logo.png";



const CompleteRegistration2 = () => {
    const fileInputRef = useRef(null);
    const history = useHistory()
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedFileKey, setSelectedFileKey] = useState(null);
    const [accpect, setAccept] = useState("")
    const [signatoryText, setSignatoryText] = useState('');
    const [addresProof, setAddressProof] = useState('')
    const [bussinessProofText, setBussinessProoftext] = useState('')
    const [tradingLicenceText, setTradingLicenceText] = useState('')
    const [verifyOrNot, setVerifyOrNot] = useState(false)
    const [nationalId, setNationalId] = useState(null)
    const [nationalIdboolen, setNationalIdboolen] = useState(false)
    const [otp, setOtp] = useState('');
    const [isMobileVerified, setIsMobileVerified] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [hasBusiness, setHasBusiness] = useState(null);
    const [fetchResponse, setFetchResponse] = useState(true);
    const [otpRequestDisabled, setOtpRequestDisabled] = useState(false);
    const [timer, setTimer] = useState(0);

    const [isForm, setIsForm] = useState({
        MerchantType: "",
        Title: "",
        FullName: "",
        Alias: "",
        Email: "",
        Mobile: "",
        NationalIDno: "",
        AddressProofNo: "",
        CountryCode: "91",
        DocsStatus: null,

        PersonalAddressLine1: "",
        FlatNo: "",
        PersonalAddressLine2: "",
        PersonalNearby: "",
        PersonalCity: "",
        PersonalProviance: "",
        PersonalCountry: "ZIMBABWE",
        PersonalZipcode: "",

        BusinessName: "",
        BusinessAddressLine1: "",
        BusinessAddressLine2: "",
        BusinessNearby: "",
        BusinessCity: "",
        BusinessProviance: "",
        BusinessCountry: "ZIMBABWE",
        BusinessZipcode: "",
        UploadType: "",

        NationalIDImg1: null,
        NationalIDImg2: null,
        AddressProofImg1: null,
        AddressProofImg2: null,
        BusinessAddressProofImg1: null,
        BusinessAddressProofImg2: null,
        TradingLicenceImg1: null,
        TradingLicenceImg2: null,
        MemorandumArticleImg1: null,
        MemorandumArticleImg2: null,
        CertOfIncImg1: null,
        CertOfIncImg2: null,
        UtilityImg1: null,
        UtilityImg2: null,
    })
    const DocsStatus = JSON.parse(isForm?.DocsStatus)

    useEffect(() => {
        updateSignatoryText(isForm.MerchantType);
    }, [isForm.MerchantType]);

    const handlechanges = (e) => {
        const { name, value } = e.target;
        setIsForm({ ...isForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        updateSignatoryText(value);
    }

    useEffect(() => {
        let timer;

        if (fetchResponse) {
            fData();
            timer = setInterval(() => {
                window.location.reload();
            }, 3000);
        } else {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [fetchResponse]);


    const fData = async () => {
        try {
            const response = await fetchMerchantProfile()
            setFetchResponse(false)
            setIsForm({ ...isForm, ...response.result });
        } catch (error) {
            console.log(error);
        }
    }

    const updateSignatoryText = (selectedValue) => {
        const newText = sentenceMap[selectedValue] || 'National ID';
        setSignatoryText(newText);
        const addressText = addressProofText[selectedValue] || 'address proof';
        setAddressProof(addressText);
        const bussinessText = bussinessproofText[selectedValue] || 'bussiness proof';
        setBussinessProoftext(bussinessText)
        const tradingLicence = trandingLicenceText[selectedValue] || 'Trading Licence'
        setTradingLicenceText(tradingLicence)
    };

    const handleUploadClick = (key) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            setSelectedFileKey(key);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFileKey) {
            setIsForm({ ...isForm, [selectedFileKey]: selectedFile });
            setSelectedFileKey(null);
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [selectedFileKey]: undefined,
        }));
    };

    const handleCancelImage = (imageKey) => {
        setIsForm({ ...isForm, [imageKey]: null });
    };

    const ensureVPAFormat = (vpa) => {
        const trimmedVPA = vpa.trim();
        if (!trimmedVPA.endsWith('@GET')) {
            return trimmedVPA + '@GET';
        }
        return trimmedVPA;
    };

    const validCharactersRegex = /^[a-zA-Z0-9@]*$/;

    // validation 
    const validateForm = () => {
        // const formattedVPA = ensureVPAFormat(isForm.VPA);
        let errors = {};

        if (!isForm.NationalIDno.trim()) {
            errors.NationalIDno = "National ID number is required";
        }

        // if (!isForm.AddressProofNo) {
        //     errors.AddressProofNo = "Address Proof No is required";
        // }


        // if (!isForm.VPA.match(validCharactersRegex)) {
        //     errors.VPA = 'Only alphanumeric characters are allowed';
        //   } else if (isForm.VPA.length < 12 || isForm.VPA.slice(-4) !== '@GET') {
        //     errors.VPA = 'The VPA must be at least 8 characters long and end with "@GET"';
        //   }

        if (!isForm.PersonalAddressLine1) {
            errors.PersonalAddressLine1 = "Residential address is required";
        }

        if (!isForm.BusinessAddressLine1) {
            errors.BusinessAddressLine1 = "Business address is required";
        }
        if (!isForm.PersonalCity) {
            errors.PersonalCity = "City is required"
        }
        if (!isForm.PersonalProviance) {
            errors.PersonalProviance = "Proviance is required"
        }
        // if (!isForm.PersonalCountry) {
        //     errors.PersonalCountry = "Country is required"
        // }
        if (!isForm.PersonalZipcode) {
            errors.PersonalZipcode = "Zipcode is required"
        }
        // if (!isForm.BusinessNearby) {
        //     errors.BusinessNearby = "Land mark / Nearby area is required"
        // }
        if (!isForm.BusinessCity) {
            errors.BusinessCity = "Business City is required"
        }
        if (!isForm.BusinessProviance) {
            errors.BusinessProviance = "Business Proviance City is required"
        }
        if (!isForm.BusinessZipcode) {
            errors.BusinessZipcode = "Business Zipcode is required"
        }
        // if (!isForm.BusinessCountry) {
        //     errors.BusinessCountry = "Business Country is required"
        // }

        // if (!isForm.BusinessName.trim()) {
        //     errors.BusinessName = "Business Name / Company Name is required"
        // }

        // if (!isForm.NationalIDImg1) {
        //     errors.NationalIDImg1 = "Please upload a document file";
        // } else {
        //     const fileValidationError = validateFile(isForm.NationalIDImg1);
        //     if (fileValidationError) {
        //         errors.NationalIDImg1 = fileValidationError;
        //     }
        // }

        // if (!isForm.AddressProofImg1) {
        //     errors.AddressProofImg1 = "Please upload a document file";
        // } else {
        //     const fileValidationError = validateFile(isForm.AddressProofImg1);
        //     if (fileValidationError) {
        //         errors.AddressProofImg1 = fileValidationError;
        //     }
        // }
        // if (!isForm.TradingLicenceImg1) {
        //     errors.TradingLicenceImg1 = "Please upload a document file";
        // } else {
        //     const fileValidationError = validateFile(isForm.TradingLicenceImg1);
        //     if (fileValidationError) {
        //         errors.TradingLicenceImg1 = fileValidationError;
        //     }
        // }
        // if (!isForm.BusinessAddressProofImg1) {
        //     errors.BusinessAddressProofImg1 = "Please upload a document file";
        // } else {
        //     const fileValidationError = validateFile(isForm.BusinessAddressProofImg1);
        //     if (fileValidationError) {
        //         errors.BusinessAddressProofImg1 = fileValidationError;
        //     }
        // }
        // if (isForm.MerchantType === "PublicLimited") {
        //     if (!isForm.MemorandumArticleImg1) {
        //         errors.MemorandumArticleImg1 = "Please upload a document file";
        //     } else {
        //         const fileValidationError = validateFile(isForm.MemorandumArticleImg1);
        //         if (fileValidationError) {
        //             errors.MemorandumArticleImg1 = fileValidationError;
        //         }
        //     }
        // }
        // if (isForm.MerchantType === "PrivateLimited" || isForm.MerchantType === "Partnership") {
        //     if (!isForm.CertOfIncImg1) {
        //         errors.CertOfIncImg1 = "Please upload a document file";
        //     } else {
        //         const fileValidationError = validateFile(isForm.CertOfIncImg1);
        //         if (fileValidationError) {
        //             errors.CertOfIncImg1 = fileValidationError;
        //         }
        //     }
        // }
        // if (isForm.MerchantType === "SoleTrader") {
        //     if (!isForm.UtilityImg1) {
        //         errors.UtilityImg1 = "Please upload a document file";
        //     } else {
        //         const fileValidationError = validateFile(isForm.UtilityImg1);
        //         if (fileValidationError) {
        //             errors.UtilityImg1 = fileValidationError;
        //         }
        //     }
        // }
        // if (isForm.MerchantType === "NGO") {
        //     if (!isForm.ConstitutionImg1) {
        //         errors.ConstitutionImg1 = "Please upload a document file";
        //     } else {
        //         const fileValidationError = validateFile(isForm.ConstitutionImg1);
        //         if (fileValidationError) {
        //             errors.ConstitutionImg1 = fileValidationError;
        //         }
        //     }
        // }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmitForm = async () => {
        try {
            const isValid = validateForm();
            if (verifyOrNot === false) {
                toastr.warning("Upload Valid National Id ")
                return
            }
            console.log(isForm);
            if (accpect && isValid) {
                const formData = new FormData()
                for (const [key, value] of Object.entries(isForm)) {
                    if (value instanceof File) {
                        formData.append(key, value);
                    } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                        value.forEach((file, index) => {
                            formData.append(`${key}`, file);
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
                const response = await updateMerchantRegisterForm(formData)
                if (response.status === "success") {
                    toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
                    // history.push("/logout");
                }
                setTimeout(() => {
                history.push("/verification")
                }, 2000);
                // localStorage.removeItem("paths");
                // window.location.reload();
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                toastr.warning(error?.response?.data?.message)
            } else {
                toastr.warning(error?.message)
            }
        }
    }

    const handleNationalId = async (e) => {
        const image = e.target.files[0];
        setNationalId(image)
        await handleCheckNationalIdDoc(image);
        setIsForm({ ...isForm, NationalIDImg1: image })
    }

    const handleCheckNationalIdDoc = async (image) => {
        try {
            const formData = new FormData()
            formData.append("Image", image)
            const response = await nationalidCheck(formData)
            const enteredNationalIDno = isForm.NationalIDno;
            const apiNationalIDno = response.result.IdNumber;
            const cleanApiNationalIDno = apiNationalIDno ? apiNationalIDno.replace(/[-\s]/g, '') : '';
            const cleanEnteredNationalIDno = enteredNationalIDno ? enteredNationalIDno.replace(/[-\s]/g, '') : '';
            const firstElevenDigitsApi = cleanApiNationalIDno.substring(0, 11);
            const firstElevenDigitsEntered = cleanEnteredNationalIDno.substring(0, 11);
            const isMatching = firstElevenDigitsApi === firstElevenDigitsEntered;

            if (isMatching) {
                // toastr.success("National ID numbers are the same")
                alert("National ID numbers are the same")
                setNationalIdboolen(true)
                setIsForm({
                    ...isForm,
                    FullName: response.result.FirstName,
                })
            } else {
                alert("National ID numbers are different");
                setNationalIdboolen(true)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleVerify = async () => {
        try {
            const formData = { NationalId: isForm?.NationalIDno }
            const response = await verifyTheNationalId(formData)
            if (response.result === true) {
                toastr.success('National id is verifid', "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
                setVerifyOrNot(response.result)
            } else {
                toastr.error('Enter valid national Id number', "warning", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleMobileChange = (e) => {
        setMobile(e.target.value);
    }

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleRadioChange = (e) => {
        setHasBusiness(e.target.value);
    };

    // send otp
    const generateOtp = async () => {
        try {
            const formData = {
                Mobile: isForm.Mobile,
                countryCode: isForm.CountryCode || "91"
            }
            const response = await sendOtp(formData)
            toastr.success(response.message)
            setOtpSent(true);
            setOtpRequestDisabled(true);
            setTimer(60);
        } catch (error) {
            console.log(error);
        }
    }
    const verifyOtp = async () => {
        try {
            const formData = {
                otp: otp,
                Mobile: isForm.Mobile
            }
            const response = await validationOtpcheck(formData)
            setValidationErrors({ ...validationErrors, OTP: "" });
            toastr.success(response.message)
            setIsMobileVerified(true);
            setOtpSent(false);
           
        } catch (error) {
            if (error?.response?.data?.message) {
                toastr.warning(error?.response?.data?.message)
            } else {
                toastr.warning(response?.message)
            }
            console.log(error);
        }
    }

    
 
    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && otpRequestDisabled) {
            setOtpRequestDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer]);



    const getCurrentUploadFields = () => {
        switch (isForm.UploadType) {
            case 'MemorandumArticleImg1':
                return ['MemorandumArticleImg1', 'MemorandumArticleImg2'];
            case 'AddressProofImg1':
                return ['AddressProofImg1', 'AddressProofImg2'];
            case 'BusinessAddressProofImg1':
                return ['BusinessAddressProofImg1', 'BusinessAddressProofImg2'];
            case 'TradingLicenceImg1':
                return ['TradingLicenceImg1', 'TradingLicenceImg2'];
            case 'UtilityImg1':
                return ['UtilityImg1', 'UtilityImg2'];
            case 'ConstitutionImg1':
                return ['ConstitutionImg1', 'ConstitutionImg2'];
            default:
                return [];
        }
    };

    const renderUploadButtons = () => {
        const uploadFields = getCurrentUploadFields();
        return uploadFields.map((field, index) => (
            <Col sm="6" xs="6" key={index}>
                <div className="onboarding_form_gropup_right_side_end">
                    <div className="Registration_form_complete_for_file_right_side">
                        <div className="onboarding_form_file_button">
                            <span>*</span>
                            <button htmlFor="fileInput" onClick={() => handleUploadClick(field)}>Upload</button>
                        </div>
                        <Input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </div>
                    {isForm[field] && (
                        <div className="onborading_cancel_main">
                            <div className="registration_file_name_and_cancel">
                                <span>{isForm[field]?.name}</span>
                                <img src={cancel} alt="*" onClick={() => handleCancelImage(field)} />
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        ));
    };

    return (
        <React.Fragment>
            <div className="registration_main_section">
                <div className="onBording_logo">
                    <img src={Merchanttlogo} alt="logo" />
                    <Link to="/logout">
                        <span>logout</span>
                    </Link>
                </div>
                <Container>
                    <div className="registration_heading_text">
                        <h1>MERCHANT ONBOARDING</h1>
                    </div>

                    <Form>
                        <Row>
                            <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <h2>Personal Information</h2>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Full Name</label>
                                    <Input type="text" placeholder="Enter your full name" name="FullName" value={isForm.FullName} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} />
                                    {validationErrors.FullName && <span className="Onbording-error-message">{validationErrors.FullName}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label>Alias</label>
                                    <Input type="text" placeholder="Enter your alias" name="Alias" value={isForm.Alias} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} />
                                    {validationErrors.Alias && <span className="Onbording-error-message">{validationErrors.Alias}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Email</label>
                                    <Input type="text" placeholder="Enter your email" name="Email" value={isForm.Email} onChange={handlechanges} disabled valid />
                                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Mobile number</label>
                                    <div
                                        className="registration2_form_group_select_countryCode"
                                    >
                                        <select
                                            className="form-select"
                                            onChange={handlechanges}
                                            value={isForm.CountryCode}
                                        >
                                            <option value="91">+91</option>
                                            <option value="263">+263</option>
                                        </select>
                                        <Input type="text" placeholder="Enter your mobile number" name="Mobile" value={isForm.Mobile} disabled={DocsStatus?.PersonalInfo} onChange={handlechanges} valid={isMobileVerified} invalid={!isMobileVerified} />
                                    </div>
                                    {!isMobileVerified && (
                                        otpSent ? (
                                            <div>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    onChange={handleOtpChange}
                                                    style={{ marginTop: '10px' }}
                                                />
                                                <Button color="primary" onClick={verifyOtp} style={{ marginTop: '10px' }}>Verify OTP</Button>
                                                {validationErrors.Otp && <span className="Onbording-error-message">{validationErrors.Otp}</span>}
                                            </div>
                                        ) : (
                                            <Button color="primary" onClick={generateOtp} style={{ marginTop: '10px' }}>Generate OTP</Button>
                                        )
                                    )}
                                     
                                {otpRequestDisabled && <span >{ `Wait ${timer}s`}</span>}
                                    {validationErrors.Mobile && <span className="Onbording-error-message">{validationErrors.Mobile}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Address </label>
                                    <Input type="text" placeholder="Enter street address or P.O. BOX" name="PersonalAddressLine1" disabled={DocsStatus?.PersonalInfo} value={isForm.PersonalAddressLine1} onChange={handlechanges} />
                                    {validationErrors.PersonalAddressLine1 && <span className="Onbording-error-message">{validationErrors.PersonalAddressLine1}</span>}
                                </div>
                            </Col>
                            <Col md="6" sm="6" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Province</label>
                                    <Input type="text" placeholder="Enter province" name="PersonalProviance" value={isForm.PersonalProviance} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} />
                                    {validationErrors.PersonalProviance && <span className="Onbording-error-message">{validationErrors.PersonalProviance}</span>}
                                </div>
                            </Col>
                            <Col md="4" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Zipcode</label>
                                    <Input type="text" placeholder="Enter postal / zipcode" name="PersonalZipcode" value={isForm.PersonalZipcode} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} />
                                    {validationErrors.PersonalZipcode && <span className="Onbording-error-message">{validationErrors.PersonalZipcode}</span>}
                                </div>
                            </Col>
                            <Col md="4" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>City</label>
                                    <Input type="text" placeholder="Enter city / locality" name="PersonalCity" value={isForm.PersonalCity} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} />
                                    {validationErrors.PersonalCity && <span className="Onbording-error-message">{validationErrors.PersonalCity}</span>}
                                </div>
                            </Col>
                            <Col md="4" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Country</label>
                                    <select name="PersonalCountry" value={isForm.PersonalCountry} onChange={handlechanges} disabled={DocsStatus?.PersonalInfo} >
                                        <option value="" disabled hidden >Select country</option>
                                        <option value="ZIMBABWE">ZIMBABWE</option>
                                    </select>
                                    {validationErrors.PersonalCountry && <span className="Onbording-error-message">{validationErrors.PersonalCountry}</span>}
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" xs="12">
                                <Row>
                                    <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                        <div className="Registration_form_complete">
                                            <label><span>*</span>National Id</label>
                                            <Input type="text" placeholder="Enter National Id No" name="NationalIDno" value={isForm.NationalIDno} disabled={DocsStatus?.NationalId} onChange={handlechanges} />
                                            {validationErrors.NationalIDno && <span className="Onbording-error-message">{validationErrors.NationalIDno}</span>}
                                            <Button className={`verfy-button-for-nationalid  ${verifyOrNot ? "verifid-national-number" : ""}`} onClick={handleVerify}>{verifyOrNot ? "Verified" : "Verify"}</Button>
                                        </div>
                                    </Col>
                                    {/* <Col lg="6" md="6" sm="6" xs="6" className="pt-3">
                                        <div className="Registration_form_complete">
                                            <label><span>*</span>Image</label>
                                            <Input type="file" onChange={handleNationalId} disabled={DocsStatus?.NationalId} />
                                        </div>
                                    </Col> */}
                                </Row>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6" className="pt-3">
                                {/* <div className="Registration_form_complete">
                                    <label><span>*</span>Image</label>
                                    {nationalId && (<div className="register_national_image mt-2">
                                        <img src={URL.createObjectURL(nationalId)} alt="National ID Image" />
                                    </div>)}
                                </div> */}
                            </Col>
                            <Col lg="12" md="12" sm="12" xs="12" className="pt-0">
                                <div className="Registration_form_complete">
                                    <h2>Business Information</h2>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Business Name</label>
                                    <Input type="text" placeholder="Enter Business / Company Name" name="BusinessName" value={isForm.BusinessName} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo} />
                                    {validationErrors.BusinessName && <span className="Onbording-error-message">{validationErrors.BusinessName}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span> Address</label>
                                    <Input type="text" placeholder="Enter street address or P.O. BOX" name="BusinessAddressLine1" value={isForm.BusinessAddressLine1} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo} />
                                    {validationErrors.BusinessAddressLine1 && <span className="Onbording-error-message">{validationErrors.BusinessAddressLine1}</span>}
                                </div>
                            </Col>
                            <Col md="3" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Province</label>
                                    <Input type="text" placeholder="Enter province" name="BusinessProviance" value={isForm.BusinessProviance} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo} />
                                    {validationErrors.BusinessProviance && <span className="Onbording-error-message">{validationErrors.BusinessProviance}</span>}
                                </div>
                            </Col>
                            <Col md="3" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Zipcode</label>
                                    <Input type="text" placeholder="Enter postal / zipcode" name="BusinessZipcode" value={isForm.BusinessZipcode} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo} />
                                    {validationErrors.BusinessZipcode && <span className="Onbording-error-message">{validationErrors.BusinessZipcode}</span>}
                                </div>
                            </Col>
                            <Col md="3" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>City</label>
                                    <Input type="text" placeholder="Enter city / locality" name="BusinessCity" value={isForm.BusinessCity} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo} />
                                    {validationErrors.BusinessCity && <span className="Onbording-error-message">{validationErrors.BusinessCity}</span>}
                                </div>
                            </Col>
                            <Col md="3" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Country</label>
                                    <select name="BusinessCountry" value={isForm.BusinessCountry} onChange={handlechanges} disabled={DocsStatus?.BusinessInfo}>
                                        <option value="" disabled hidden>Select country</option>
                                        <option value="ZIMBABWE">ZIMBABWE</option>
                                    </select>
                                    {validationErrors.BusinessCountry && <span className="Onbording-error-message">{validationErrors.BusinessCountry}</span>}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <Col lg="12" md="12" sm="12" xs="12" className="pt-4">
                        <div className="Registration_form_complete">
                            <label>Do you have certificate of incorporation
                                <Input name="radio1" type="radio" value="yes" className="ms-3" disabled={DocsStatus?.BusinessDocs} onChange={handleRadioChange} />   <Label check>Yes</Label>
                                <Input name="radio1" type="radio" value="no" className="ms-3" disabled={DocsStatus?.BusinessDocs} onChange={handleRadioChange} />   <Label check>NO</Label></label>
                        </div>
                    </Col>
                    {hasBusiness === 'yes' && (
                        <Row className="mt-4">
                            <Col lg="7" md="7" sm="12" xs="12">
                                <div className="Registration_form_complete_for_file">
                                    <span>Certificate of Incorporation </span>

                                </div>
                            </Col>
                            <Col lg="5" md="5" sm="12" xs="12">
                                <Row>
                                    <Col sm="6" xs="6">
                                        <div className="onboarding_form_gropup_right_side_end">
                                            <div className="Registration_form_complete_for_file_right_side">
                                                <div className="onboarding_form_file_button">
                                                    <span>*</span>
                                                    <button htmlFor="fileInput"
                                                        onClick={() => handleUploadClick('CertOfIncImg1')}>Upload</button>
                                                </div>
                                                <input ref={fileInputRef}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange} />

                                            </div>
                                            {isForm.CertOfIncImg1 && <div className="onborading_cancel_main">
                                                <div className="registration_file_name_and_cancel">
                                                    <span>{isForm.CertOfIncImg1.name}</span>
                                                    <img src={cancel} alt="*"
                                                        onClick={() => handleCancelImage('CertOfIncImg1')}
                                                    />
                                                </div>
                                            </div>}

                                        </div>

                                    </Col>
                                    <Col sm="6" xs="6">
                                        <div className="onboarding_form_gropup_right_side_end">
                                            <div className="Registration_form_complete_for_file_right_side">
                                                <div className="onboarding_form_file_button">
                                                    <button onClick={() => handleUploadClick('CertOfIncImg2')}>Upload</button>
                                                </div>
                                                <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                            </div>
                                            {isForm.CertOfIncImg2 && <div className="onborading_cancel_main">
                                                <div className="registration_file_name_and_cancel">
                                                    <span>{isForm.CertOfIncImg2.name}</span>
                                                    <img src={cancel} alt="*"
                                                        onClick={() => handleCancelImage('CertOfIncImg2')} />
                                                </div>
                                            </div>}
                                        </div>
                                    </Col>
                                    <div className="file_errors_in_onboarding">
                                        {validationErrors.CertOfIncImg1 && <span className="Onbording-error-message">{validationErrors.CertOfIncImg1}</span>}
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {hasBusiness === 'no' && (
                        <Row className="mt-4">
                            <Col lg="7" md="7" sm="12" xs="12">
                                <div className="Registration_form_complete_for_file">
                                    <select name="UploadType" value={isForm.UploadType} onChange={handlechanges}>
                                        <option value="" disabled hidden>UPLOAD ANY OF THESE</option>
                                        <option value="MemorandumArticleImg1">MEMORANDUM ARTICLE</option>
                                        <option value="AddressProofImg1">ADDRESS PROOF</option>
                                        <option value="BusinessAddressProofImg1"> BUSINESS ADDRESS PROOF</option>
                                        <option value="TradingLicenceImg1"> TRANDING LICENCE</option>
                                        <option value="UtilityImg1">UTILITY</option>
                                        <option value="ConstitutionImg1">CONSTITUTION</option>
                                    </select>
                                    {isForm.UploadType === "AddressProofImg1" && (
                                        <div className={`registration_national_id_no_input_feild ${validationErrors.AddressProofNo ? "error_national_id_no" : ""}`}>
                                            <Input type="text" placeholder="Enter address proof NO" name="AddressProofNo" value={isForm.AddressProofNo} onChange={handlechanges} />
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col lg="5" md="5" sm="12" xs="12">
                                <Row>
                                    {renderUploadButtons()}
                                </Row>
                            </Col>
                        </Row>
                    )}

                    <div className="terms_and_condtion_registration">
                        <Input type="checkbox" name="accpect" value={accpect} onChange={(e) => setAccept(e.target.checked)} /><span>i accept the terms & conditons</span>
                    </div>
                    <div className="Registration_button">
                        <button onClick={handleSubmitForm}>NEXT</button>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CompleteRegistration2