export const gatewayModuleTypeId = async (item) => {
    switch (item) {
        case "VISA":
            return {
                id: "5c8fff17-dc02-48dd-a96e-17e8a09fcd43",
            };
        case "MASTER":
            return {
                id: "c31408f6-6045-48a5-ab4f-ae1b92bbc307",
            };
        case "ECOCASH":
            return {
                id: "1be3dcb6-7ed3-4a64-a736-c2b968086103",
            };
        case "TELECASH":
            return {
                id: "391d55fd-ebb6-4b29-aaa5-938d464577af",
            };
        case "ONEMONEY":
            return {
                id: "ef670cc7-1e08-470f-83da-dc29d2858070",
            };
        case "ZIMSWITCH":
            return {
                id: "0a7da9b3-210a-4800-8d11-fe6406292fd6",
            };
        case "CABS-VMC":
            return {
                id: "d1717238-94e4-47b0-9d1f-64c4d12c61e9",
            };
        case "CABS-MASTER":
            return {
                id: "2257c83b-3816-4a62-8391-bde114e049dc",
            };
        case "CABS-VISA":
            return {
                id: "5aafa67e-55f1-44b1-a354-def6be51b0da",
            };
        case "IVERI-VISA":
            return {
                id: "a1b654b8-678c-495e-8264-8133715ff95f",
            };
        case "IVERI-MASTER":
            return {
                id: "ed99defc-52af-486d-8463-574cd6b8c01b",
            };
        case "EFT-MASTER":
            return {
                id: "655761c7-e04a-4b7c-8548-bb04c2da4349",
            };
        case "EFT-VISA":
            return {
                id: "3170a683-01bb-488c-b32d-9d5f0d131e9e",
            }
        default:
            return {
                id: "5c8fff17-dc02-48dd-a96e-17e8a09fcd43",
            };
    }
}