import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import merchantWheel from "../../assets/images/NewImage/Vault_Wheel.png"
import merchantLogo from "../../assets/images/NewImage/merchant_Portal_Logo.png"
import { fetchUserProfile } from "Context/ContextApi";

const permision = localStorage.getItem("paths")
const token = JSON.parse(localStorage.getItem("authUsers"));
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loader: true,
      isloading:true,
      hitOneTime:false
    };
  }

  componentDidMount() {
    this.fetchData();
    this.checkPermission();
  }

  fetchData = async () => {
    try {
      if(!this.hitOneTime){
      const response = await fetchUserProfile();
      const jsonstring = response?.result?.subResult?.Permissions;
      localStorage.setItem("paths", jsonstring);
      const data = JSON.parse(response?.result?.subResult?.Permissions);
      this.setState({ data });
      setTimeout(() => {
        this.setState({ loader: false });
      }, 3000);
      this.setState({ hitOneTime: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  checkPermission = () => {
    if (permision) {
      setTimeout(() => {
        this.setState({ isloading: false });
      }, 0.1);
    }
  };

  render() {
    const { data,} = this.state;
    
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box" >
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={merchantWheel} alt="" height="50" width="50"/>
              </span>
              <span className="logo-lg">
                <img src={merchantWheel} alt=""  height="40" width="40" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={merchantWheel} alt=""  height="40" width="40" />
              </span>
              <span className="logo-lg">
                <img src={merchantWheel} alt="" height="50" width="50"/>
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" && token ? <SidebarContent token={token} data={data} fetchData={this.fetchData} /> :
              <div className="loader-body">
                <p className="loader"></p>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
