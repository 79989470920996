
import React, { useEffect, useState } from "react";
import {
    Button, Col, FormGroup, Input, Label, Modal, ModalHeader, Row, } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { handleMerchantSransactionStamentPay, } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const CURRENCY = process.env.CURRENCY;
const API_BASE_URL = process.env.REACT_APP_API_URL

const StatementAmount = ({ amount, taxes, isOpen, toggle, months, fetchStatement }) => {
    const [pin, setPin] = useState("")
    const [pinError, setPinError] = useState('');
    const [isPinButton, setIsPinbutton] = useState(true);
    const [pinPopup, setPinPopup] = useState(false);

    const handleCheckAndDeposit = () => {
        if (!pin || isNaN(pin) || pin.length !== 4) {
            setPinError('PIN must be a 4-digit number.');
            return;
        }
        setPinError('');
        handleStatmentBank()
    }

    const handleStatmentBank = async () => {
        setPinPopup(false)
        setIsPinbutton(false)
        const formData = {
            Months: months,
            Pin: pin,
        };
        try {
            const response = await handleMerchantSransactionStamentPay(formData)
            if (response.status === "success") {
                setPinPopup(false)
                toastr.success(response.message, "Success", {
                    timeOut: 7000,
                    progressBar: true,
                    closeDuration: 700,
                    positionClass: "toast-top-right"
                });
                setPin("")
                toggle()
                setIsPinbutton(true)
                fetchStatement();
            } else {
                toastr.warning(response.message, "warning", {
                    timeOut: 7000,
                    progressBar: true,
                    closeDuration: 700,
                    positionClass: "toast-top-right"
                });
                setPin('')
                setPinPopup(true)
                setIsPinbutton(true)
            }
        } catch (error) {
            toastr.warning(error.message)
            setPinPopup(true)
            setIsPinbutton(true)
        }
    }

    return (
        <>
            <Modal
                isOpen={pinPopup}
                role="dialog"
                autoFocus={true}
                centered
            >
                <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setPinPopup(false)}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-4">
                            <div className="row justify-content-center">
                                <div className="col-xl-8">
                                    <h4 className="model_heading_text">Verify Your pin</h4>
                                    <FormGroup>
                                        <Label for="pin" className="generate_pin_label">PIN:</Label>
                                        <Input type="password" name="pin" id="pin" placeholder="Enter your pin"
                                            className="css_for_generatePin_input" min="0"
                                            value={pin} onChange={(e) => setPin(e.target.value)} />
                                        {pinError && <small className="text-danger">{pinError}</small>}
                                    </FormGroup>
                                    {isPinButton && (
                                        <Button className="model_confirm_button" type="button" onClick={handleCheckAndDeposit}>
                                            Confirm
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={isOpen}
                role="dialog"
                autoFocus={true}
                centered
                fullscreen="xl"
                size="lg"
            >
                <ModalHeader toggle={toggle}>Payments</ModalHeader>
                <Row className="m-5">
                    <Col md={{ offset: 1, size: 10, }} >
                        <div className="deposit_money_secondStep_form">
                            <div>
                                <h5
                                    className="text-center"
                                    style={{ color: "#db9c45" }}
                                >
                                    {/* You are requesting money from */}
                                </h5>
                                {/* <h4 className="text-center">{sendForm.userName}</h4> */}
                            </div>
                            <div className="deposit_money_secondStep_form mt-3">
                                <div className="deposite_money_second_step_text  ">
                                    <span>PURCHASE </span>
                                    <span>{CURRENCY} {amount}</span>
                                </div>
                                <div className="deposite_money_second_step_text  ">
                                    <span>Tax </span>
                                </div>
                                {taxes?.result && taxes?.result[1]?.Tax?.map((item, index) => (
                                    <div className="transaction_details ps-4" key={index}>
                                        <div className="transaction_detailsForTaxDetals">
                                            <div className="transaction_detail_Title_name">
                                                <label>{item.Particular}</label>
                                                {item.Percentage && (
                                                    <small>{item.Percentage} %</small>
                                                )}
                                            </div>
                                            <div className="transaction_detail_Amount">
                                                <span>{CURRENCY} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="transaction_details deposite_money_second_step_text_border_line ">
                                    <span>Net Tax </span>
                                    <span>{CURRENCY} {taxes?.result && parseFloat(taxes?.result[2]?.Value)}</span>
                                </div>
                                <div className="transaction_details deposite_money_second_step_text_border_line">
                                    <span>Net Total </span>
                                    <span>{CURRENCY} {taxes?.result && parseFloat(taxes?.result[3]?.Value)}</span>
                                </div>
                            </div>
                            <Button
                                className="procces_Button"
                                onClick={() => { setPinPopup(true) }}
                            >
                                Confirm & Send
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default StatementAmount;
