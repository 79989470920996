import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AddAndCreatemerchantRoles, createQrCodeAndCounterForSubAdmin, fMerchantRoleEditData } from "Context/ContextApi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function CreateQrcode() {
  const history = useHistory()
  const [formData, setFormData] = useState({
    Counter:"",
    Description:""
  })
  const handleChanges = async (e) =>{
    const {name, value} = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleFormSubmit = async () => {
    try {
      if(!formData.Counter){
        toastr.warning("please enter The Counter")
        return
      }
      const response = await createQrCodeAndCounterForSubAdmin(formData)
      console.log(response);
      
      toastr.success(response.message)
          setFormData({ Counter:"", Description:"" })
          history.push("/qr-code-list")
    } catch (error) {
        toastr.error(error?.response?.data?.message || error?.message)
      console.log(error);
    }
  
  };
  document.title = "GET Platform  | Create qr code";
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="create qr code" />
          <Container>
            <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  <h1 className="text-center all_newPaeHeading title-color-common">CREATE QR CODE</h1>
                  <h5 className="text-center all_sub_new_heading description-color">
                    Create new QR code for Counter
                  </h5>
                </Col>
              </Row>
              <CardBody>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Enter Counter Number
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      maxLength={2}
                      placeholder="Enter Counter number"
                      name="Counter"
                      value={formData.Counter}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Description
                  </label>
                  <div className="col-md-9">
                    <textarea
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter description"
                      name="Description"
                      value={formData.Description}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                </div>

                <Row className="mt-5 mb-5">
                  <Col lg="9" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "40%",
                      }}
                    >
                      <Link to="/qr-code-list">
                        <Button className="procces_Button">Cancel</Button>
                      </Link>
                      <Button className="procces_Button" onClick={handleFormSubmit}>
                        Create
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CreateQrcode;
