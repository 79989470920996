import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";

import edit from "../../assets/images/NewImage/writing.png";
import "flatpickr/dist/themes/material_blue.css";
import deleteimg from "../../assets/images/NewImage/supprimer.png"
import { useHistory } from "react-router-dom";
import { deletePermission, fMerchantPermission } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";

const ManageUserGroups = () => {
  const history = useHistory();
  const {iPAddress} = useData();
  const [productData, setProductData] = useState([]);

  const handleClickEdit = (id) =>{
    history.push(`/add-user-group/${id}`)
  }

  useEffect(()=>{ fPermissions() },[])
const fPermissions = async () =>{
  try {
    const response = await fMerchantPermission()
    setProductData(response.result)
  } catch (error) {
    console.log(error);
  }
}

const handleDeletePermission = async (id)=>{
  try {
    const formData = {
      id:id
    }
    const response = await deletePermission(formData)
      toastr.success(response.message, "Success", {
      timeOut: 7000,
      progressBar: true, 
      closeDuration:700,
      positionClass: "toast-top-right" 
    });
    fPermissions();
  } catch (error) {
    console.log(error);
  }
}

  const columns = [
    {
      dataField: "Role",
      text: "Roles",
      sort: true,
    },
    {
      dataField: "UserName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "edit",
      text: "Action",
      sort: true,
      formatter: (cell, row) => (
        <span style={{ color: row.status === "Success" ? "green" : "red", display:"flex", gap:"5px" }}>

          <img src={edit} alt="edit" style={{ width: "25px", cursor:"pointer" }} onClick={()=>handleClickEdit(row.id)} />
          <img src={deleteimg} alt="delete" style={{width:"25px", cursor:"pointer"}} onClick={()=>handleDeletePermission(row.id)}/>
        </span>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length,
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  document.title = "GET Platform  | Manage users";
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container">
          <Breadcrumbs title="GET" breadcrumbItem="Manage users" />
          <Row>
            <Col md={{ offset: 3, size: 6 }}>
              <h1 className="text-center all_newPaeHeading title-color-common">
                MANAGE USERS
              </h1>
              <h5 className="text-center all_sub_new_heading description-color">
               View the list of all users you have created, hierarchy level & manage their profiles
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="10">
              <h5 className="mb-3 sub_heading_left_side_the_page text-start">
                All list of user group
              </h5>
            </Col>
            <Col lg="2" className="d-flex justify-content-end">
              <Link to="/add-user-group" className="item-end">
                <Button
                     className="procces_Button"
                  style={{
                    fontFamily: "Gilroy",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                 + New User
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Card className="mt-5">
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="12">
                                <div className="search-box me-2 mb-2  d-flex">
                                <div className="d-inlineo" >
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                  <div className="position-relative  text-md-right ms-auto">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-wrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    style={{
                                      fontWeight: "Gilroy",
                                    }}
                                    headerClasses={"custom-header-class"}
                                    rowStyle={{
                                      fontSize: "15px",
                                      fontFamily: "Gilroy",
                                      fontWeight: "400",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                               
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageUserGroups;
