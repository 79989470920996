import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import "../../pages/DepositMoney/DepositeMoneyStyle.css";
import print from "../../assets/images/NewImage/printing.png";
import success from "../../assets/images/NewImage/Invoice-Logo.png";
import ticket from "../../assets/images/NewImage/transaction-2.png";
import PrintOut from "pages/DepositMoney/PrintOut";
import ReactToPrint from "react-to-print";
import { Sendmoneyprint } from "./Sendmoneyprint";
import { checkTaxCalclations, sendMoneyToUserWallet } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";

function SendMoney() {
  const { iPAddress, mData, userDataa } = useData();
  const [showPrintUI, setShowPrintUI] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [pin, setPin] = useState("")
  const [popupOpen, setPopupOpen] = useState(false)
  const [pinError, setPinError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState({})
  const [taxes, setTaxes] = useState([])
  const [netTotal, setNetTotal] = useState('')
  const [netTotalAfterSuccess, setNetTotalAfterSuccess] = useState('')
  const [actionData, setActionData] = useState(null)
  const [isPinButton, setIsPinbutton] = useState(true)
  const [sendForm, setSendForm] = useState({
    currency: "",
    amount: "",
    remarks: "",
    userName: ""
  })

  useEffect(() => {
    let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
    setActionData(jsonparse)
  }, [mData?.subResult?.Permissions])

  const [validationErrors, setValidationErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm({ ...sendForm, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }

  const validateForm = () => {
    let errors = {};

    if (!sendForm.userName) {
      errors.userName = "Please enter a valid VPA.";;
    }
    if (!sendForm.amount || isNaN(sendForm.amount) || sendForm.amount <= 0) {
      errors.amount = "Please enter a valid amount greater than zero.";
    }

    if (!sendForm.remarks) {
      errors.remarks = "Enter Transaction description";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleProceed = nextStep => {
    if (nextStep >= 1 && nextStep <= 3 && validateForm()) {
      setCurrentStep(nextStep);
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };

  const handlePopup = () => {
    setPopupOpen(true)
  }
  
  const handleCheckAndDeposit = () => {
    if (!pin || isNaN(pin) || pin.length !== 4) {
      setPinError('PIN must be a 4-digit number.');
      return;
    }
    setPinError('');
    walletToBankTransafer()
  }
  const walletToBankTransafer = async () => {
    setIsPinbutton(false)
    const formData = {
      amount: sendForm.amount,
      currency: sendForm.currency,
      remarks: sendForm.remarks,
      userName: sendForm.userName,
      pin: pin,
      IPAddress: iPAddress
    };
    try {
      const response = await sendMoneyToUserWallet(formData)
      if (response.status === "success") {
        handleProceed(currentStep + 1);
        setPopupOpen(false)
        toastr.success(response.message, "Success", {
          timeOut: 7000,
          progressBar: true,
          closeDuration: 700,
          positionClass: "toast-top-right"
        });
        setTransactionDetails(response.transactionDetails)
        setSendForm({
          amount: "",
          remarks: "",
          userName: ""
        })
        setIsPinbutton(true)
      } else if (response.status === 'failed') {
        toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right" });
        setIsPinbutton(true)
      }
      setPin("")
      setNetTotalAfterSuccess(response.netTotal)
    } catch (error) {
      toastr.error(error?.response?.data?.message || error?.message)
      setPin("")
      setIsPinbutton(true)
    }
  }
  const componentRef = useRef();
  const handleResertForm = () => {
    setSendForm({
      amount: "",
      remarks: "",
      userName: ""
    })
  }

  // check Tax Calculations
  const fecthTaxesFrombackend = async () => {
    try {
      const formData = {
        Amount: parseFloat(sendForm.amount),
        Module: 'f08fbe27-7333-4b61-9dcb-30b21ea8ce26',
        Module_Type: '901a9c66-f904-4241-91b6-04c3453374c9',
        Nature: 'BUSINESS',
        Source: "WALLET"
      }
      const response = await checkTaxCalclations(formData)
      setTaxes(response.result)
      setNetTotal(response.netTotal)
    } catch (error) {
      console.log(error);
    }
  }

  document.title = "GET Platform  | Send Money"
  return (
    <>
      {showPrintUI && <PrintOut onClose={() => setShowPrintUI(false)} />}
      {!showPrintUI && (
        <React.Fragment>
          <Modal
            isOpen={popupOpen}
            role="dialog"
            autoFocus={true}
            centered
          >
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPopupOpen(false)}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="text-center mb-4">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <h4 className="model_heading_text title-color-common">Verify Your pin</h4>
                      <FormGroup>
                        <Label for="pin" className="generate_pin_label">PIN:</Label>
                        <Input type="password" name="pin" id="pin" placeholder="Enter your pin"
                          className="css_for_generatePin_input" min="0"
                          value={pin} onChange={(e) => setPin(e.target.value)} />
                        {pinError && <small className="text-danger">{pinError}</small>}
                      </FormGroup>
                      {isPinButton && (
                        <Button className="model_confirm_button procces_Button" type="button" onClick={handleCheckAndDeposit}>
                          Confirm
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div
            className="page-content"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            <div className="container-fluid">
              <Breadcrumbs title="GET" breadcrumbItem="Send Money" />
              <Container className="mt-5">
                <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                  <Row>
                    <Col
                      md={{
                        offset: 3,
                        size: 6,
                      }}
                    >
                      <h1 className="text-center mb-4 heading_for_progress_bar title-color-common">SEND MONEY</h1>
                      <p className="text-center steps_text_progress_bar">Step {currentStep} of 3</p>
                      {currentStep === 1 && (
                        <h4 className="text-center after_heading_description_In_progress title-color-common">Start transfer </h4>
                      )}
                      {currentStep === 2 && (
                        <h4 className="text-center after_heading_description_In_progress title-color-common">Confirm your transfer</h4>
                      )}
                      {currentStep === 3 && (
                        <h4 className="text-center after_heading_description_In_progress title-color-common">Money transfer complete</h4>
                      )}

                      <div className="progress_bar_main mt-4">
                        <div
                          className={`progress_bar_one ${currentStep >= 1 ? "Progress_bar_active procces_Button" : ""
                            }`}
                        ></div>
                        <div
                          className={`progress_bar_one ${currentStep >= 2 ? "Progress_bar_active procces_Button" : ""
                            }`}
                        ></div>
                        <div 
                          className={`progress_bar_one ${currentStep === 3 ? "Progress_bar_active procces_Button" : ""
                            }`}
                        ></div>
                      </div>
                      {currentStep === 1 && (
                        <p className="text-center mt-4 progress_bar_description_text">
                          Enter your recipient&rsquo;s VPA & then add amount
                          with currency. You can also provide a note for reference
                        </p>
                      )}
                      {currentStep === 2 && (
                        <p className="text-center mt-4 progress_bar_description_text">
                          Take a look before you send. Do not worry, if the
                          recipint does not have on account, we will get them set
                          up for free
                        </p>
                      )}
                    </Col>
                  </Row>
                  {currentStep === 1 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >
                        <div className="form_gruap_for_deposit">
                          <div className="form_group_for_inside_in_deposit">
                            <label>Recipient</label>
                            <input type="text" placeholder="Recipient" name="userName" value={sendForm.userName} onChange={handleChange} />
                            {validationErrors.userName && (
                              <span className="error-message">{validationErrors.userName}</span>
                            )}
                          </div>
                          <Col md="12" sm="12">
                            <div className="form_group_for_inside_in_deposit">
                              <label>Amount</label>
                              <input type="number" placeholder="Enter amount"
                                min="0" name="amount" value={sendForm.amount} onChange={handleChange} />
                              {validationErrors.amount && (
                                <span className="error-message">{validationErrors.amount}</span>
                              )}
                            </div>
                          </Col>

                          <div className="form_group_for_inside_in_deposit">
                            <label>Transaction description</label>
                            <textarea type="text" placeholder="Enter Transaction description" name="remarks" value={sendForm.remarks} onChange={handleChange} />
                            {validationErrors.remarks && (
                              <span className="error-message">{validationErrors.remarks}</span>
                            )}
                          </div>
                          {actionData === null || (actionData?.['Send money']?.action === true) ? (
                            <Button
                              className="procces_Button"
                              onClick={() => {
                                handleProceed(currentStep + 1);
                                fecthTaxesFrombackend()
                              }}
                            >
                              Proceed
                            </Button>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {currentStep === 2 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >
                        <div className="deposit_money_secondStep_form">
                          <h5
                            className="text-center title-color-common"
                          >
                            Recipient
                          </h5>
                          <h4 className="text-center">{sendForm.userName}</h4>
                          <div className="deposit_money_secondStep_form">
                            <div className="deposite_money_second_step_text  ">
                              <span>SEND MONEY </span>
                              <span>{userDataa.Currency} {parseFloat(taxes[0]?.Value)}</span>
                            </div>
                            <div className="deposite_money_second_step_text  ">
                              <span>Tax </span>
                            </div>
                            {taxes[1]?.Tax?.map((item, index) => (
                              <div className="transaction_details ps-4" key={index}>
                                <div className="transaction_detailsForTaxDetals">
                                  <div className="transaction_detail_Title_name">
                                    <label>{item.Particular}</label>
                                    {item.Percentage && (
                                      <small>{item.Percentage} %</small>
                                    )}
                                  </div>
                                  <div className="transaction_detail_Amount">
                                    <span>{userDataa.Currency} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="transaction_details deposite_money_second_step_text_border_line ">
                              <span>Net Tax </span>
                              <span>{userDataa.Currency} {parseFloat(taxes[2]?.Value)}</span>
                            </div>
                            <div className="transaction_details deposite_money_second_step_text_border_line">
                              <span>Net Total </span>
                              <span>{userDataa.Currency} {parseFloat(taxes[3]?.Value)}</span>
                            </div>
                          </div>
                          <Button
                            className="procces_Button mt-3"
                            onClick={() => {
                              handlePopup()
                            }}
                          >
                            Confirm & Deposit
                          </Button>
                          <span
                            className="text-center"
                            onClick={() => handleProceed(currentStep - 1)}
                          >
                            Back
                          </span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {currentStep === 3 && (
                    <Row>
                      <Col
                        md={{
                          offset: 3,
                          size: 6,
                        }}
                      >
                        <div className="deposite_money_step_three_main">
                          <img
                            src={success}
                            alt="success"
                            className="success_image"
                          />
                          <h5 className="text-center">Success!</h5>
                          <p className="text-center">
                            Money has been transferred successfully.
                          </p>
                          <div className="send_money_step_three_box_main">
                            <img
                              src={ticket}
                              alt="icon"
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                            <div className="send_money_step_three_box">
                              <h4>{sendForm.userName}</h4>
                              <h6>Transferred amount</h6>
                              <h2 className="title-color-common ">{userDataa.Currency} {parseFloat(netTotalAfterSuccess)}</h2>
                            </div>
                          </div>
                          <p className="text-center">
                            The recipient will be notified via an email or phone
                            number after money has been successfully transferred
                            to their account.
                          </p>
                          <div className="deposit_monet_step_three_button">
                            <ReactToPrint
                              trigger={() => (
                                <Button type="button" className="deposit_monet_step_three_print procces_Button">
                                  <img src={print} alt="print" />
                                  Print code
                                </Button>
                              )}
                              content={() => componentRef.current}
                            />
                            <Button
                              className="deposit_monet_step_three_deposit_again"
                              onClick={() => { handleProceed(0), handleResertForm() }}
                            >
                              Send again
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Container>
            </div>
          </div>
        </React.Fragment>)}
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <Sendmoneyprint ref={componentRef} transactionDetails={transactionDetails} />
      </div>
    </>
  );
}
export default SendMoney;
