import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Card, CardBody, Button } from "reactstrap";

const GetNoOfTransaction = ({chartSeries}) => {
  const [chartOptions, setChartOptions] = useState({
    chart: { toolbar: false },
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    markers: { size: 0, style: "hollow" },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      title: {
        text: "Months",
        style: {
            fontSize: '16px', 
            fontWeight: 'bold', 
            fontFamily: 'Gilroy', 
            color:"#db9c45"
          },
      },
    },

    yaxis: {
      title: {
        text: "Number of transactions",
        
      },
     
    },
    tooltip: { x: { format: "dd MMM yyyy" } },
    colors: ["#db9c45"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.6,
        opacityTo: 0.05,
        stops: [42, 100, 100, 100],
      },
    },
  });

  // const [chartSeries, setChartSeries] = useState([
  //   {
  //     name: "Volume",
  //     data: [
  //       [new Date("2023-01-01").getTime(), 300],
  //       [new Date("2023-02-01").getTime(), 400],
  //       [new Date("2023-03-01").getTime(), 350],
  //       [new Date("2023-04-01").getTime(), 500],
  //       [new Date("2023-05-01").getTime(), 490],
  //       [new Date("2023-06-01").getTime(), 210],
  //       [new Date("2023-07-01").getTime(), 700],
  //       [new Date("2023-08-01").getTime(), 510],
  //       [new Date("2023-09-01").getTime(), 400],
  //     ],
  //   },
  // ]);

  const [activeM, setActiveM] = useState(false);
  const [active6M, setActive6M] = useState(false);
  const [activeY, setActiveY] = useState(true);
  const [activeA, setActiveA] = useState(false);

  const updateChart = newXAxis => {
    setChartOptions(prevOptions => ({
      ...prevOptions,
      xaxis: newXAxis,
    }));
  };

  const updateChartMonthly = () => {
    updateChart({
      type: "datetime",
      min: new Date("2023-01-01").getTime(),
      max: new Date("2023-02-01").getTime(),
    });
    setActiveM(true);
    setActive6M(false);
    setActiveY(false);
    setActiveA(false);
  };

  const updateChartSixMonth = () => {
    updateChart({
      type: "datetime",
      min: new Date("2022-09-01").getTime(),
      max: new Date("2023-02-01").getTime(),
    });
    setActiveM(false);
    setActive6M(true);
    setActiveY(false);
    setActiveA(false);
  };

  const updateChartYearly = () => {
    updateChart({
      type: "datetime",
      min: new Date("2022-01-01").getTime(),
      max: new Date("2023-01-01").getTime(),
    });
    setActiveM(false);
    setActive6M(false);
    setActiveY(true);
    setActiveA(false);
  };

  const updateChartAll = () => {
    updateChart({ type: "datetime", min: undefined, max: undefined });
    setActiveM(false);
    setActive6M(false);
    setActiveY(false);
    setActiveA(true);
  };

  return (
    <Col xl="6">
      <Card>
        <CardBody>
          <h4 className="card-title mb-3 title-color-common" style={{fontFamily:"Gilroy", 
            // color:"#db9c45", // css change 
            fontSize:"21px"}}>Volume </h4>
          <div>
            <div id="sales-revenue-chart" className="apex-charts" dir="ltr">
              <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                <Button
                  color="light"
                  size="sm"
                  type="button"
                  className={activeM ? "active" : ""}
                  onClick={updateChartMonthly}
                  id="one_month"
                >
                  1M
                </Button>{" "}
                <Button
                  color="light"
                  size="sm"
                  type="button"
                  className={active6M ? "active" : ""}
                  onClick={updateChartSixMonth}
                  id="six_months"
                >
                  6M
                </Button>{" "}
                <Button
                  color="light"
                  size="sm"
                  type="button"
                  className={activeY ? "active" : ""}
                  onClick={updateChartYearly}
                  id="one_year"
                >
                  1Y
                </Button>{" "}
                <Button
                  color="light"
                  size="sm"
                  type="button"
                  className={activeA ? "active" : ""}
                  onClick={updateChartAll}
                  id="all"
                >
                  ALL
                </Button>
              </div>
              <div id="sales-revenue-chart-timeline">
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="area"
                  height={240}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GetNoOfTransaction;
