import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AddAndCreatemerchantRoles, fMerchantRoleEditData } from "Context/ContextApi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function AddRole() {
  const history = useHistory()
  const {id} = useParams()
  const [isSuccessfulMessage, setIsSuccessfulMessage] = useState(false);
  const [formData, setFormData] = useState({
    Role:"",
    Description:""
  })
  const handleChanges = async (e) =>{
    const {name, value} = e.target;
    setFormData({ ...formData, [name]: value });
  }
  useEffect(()=>{
    if(id){
      fetchTheEditData()
    }
  },[id])
  const fetchTheEditData = async ()=>{
    try {
      const formData ={
        id:id
      }
      const response = await fMerchantRoleEditData(formData)
      setFormData({...response.result})
    } catch (error) {
      console.log(error);
    }
  }


  const handleFormSubmit = async () => {
    try {
      if(!formData.Role){
        toastr.warning("please enter The Role")
        return
      }
      const response = await AddAndCreatemerchantRoles(formData)
      if(response.status === 'success'){
        setIsSuccessfulMessage(true);
        // setInterval(() => {
          setIsSuccessfulMessage(false);
          setFormData({
            Role:"",
            Description:""
          })
          history.push("/manage-roles")
        // }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  
  };
  document.title = "GET Platform  | Add role";
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="Add role" />
          <Container>
            <Card style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Row>
                <Col md={{ offset: 3, size: 6 }}>
                  <h1 className="text-center all_newPaeHeading title-color-common">ADD ROLE</h1>
                  <h5 className="text-center all_sub_new_heading description-color">
                    Create new roles to access GET Vault, define hierarchies,
                    create access related rules for every role to ensure saftly
                    & privacy of data
                  </h5>
                </Col>
              </Row>
              <CardBody>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Enter role
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter role"
                      name="Role"
                      value={formData.Role}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                  >
                    Description
                  </label>
                  <div className="col-md-9">
                    <input
                      className="form-control form_in_offer_input_box"
                      type="text"
                      placeholder="Enter description"
                      name="Description"
                      value={formData.Description}
                      onChange={handleChanges}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                </div>

                <Row className="mt-5 mb-5">
                  <Col lg="9" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "40%",
                      }}
                    >
                      <Link to="/manage-roles">
                        <Button className="procces_Button">Cancel</Button>
                      </Link>
                      <Button className="procces_Button" onClick={handleFormSubmit}>
                        Add
                      </Button>
                    </div>
                  </Col>
                  <Col
                    lg="3"
                  >
                    {isSuccessfulMessage && (
                      <h6
                        className="text-center"
                        style={{
                          fontFamily: "Gilroy",
                          fontSize: "18px",
                          color: "green",
                        }}
                      >
                        New role created &#10004;
                      </h6>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AddRole;
