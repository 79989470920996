import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Row, Col, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReactToPrint from "react-to-print";
import { useData } from "Context/ContextProvider";
import {
  checkTaxCalclations,
  fetchDynmicDepositMethods,
  fetchDynmicDepositType,
  fetchmerchantRefundPaynmentDetails,
  getDynmicDepositMethods,
  getMerchantBankDetailsForSelect,
  getMerchantBankDetailsUsingNameapi,
  initiateCABSCopyAndPayTransaction,
  initiateCbzTransaction,
  initiateEFTCopyAndPayTransaction,
  initiateZIMSWITCHCopyAndPayTransaction,
  paynowDeposit,
  postBankToWallet,
} from "Context/ContextApi";
import { CURRENCY } from "components/Common/Currency";
import RedirectPopup from "../RedirectPopup";
import PinModal from "./PinModal";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import ProgressBar from "./ProgressBar";
import CardForm from "./CardForm";
import Secure3DPage from "./RedirectCbzHtml";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { gatewayModuleTypeId } from "components/Common/taxation.moduletype";

function DepositMoneyIndex() {
  const { iPAddress, mData, userDataa } = useData();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [valueForBankDetails, setValueForBankDetails] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [transactionDetails, setTransactionDetails] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [taxes, setTaxes] = useState([]);
  const [netTotal, setNetTotal] = useState("");
  const [netTotalAfterSuccess, setNetTotalAfterSuccess] = useState("");
  const [actionData, setActionData] = useState({});
  const [isPinButton, setIsPinbutton] = useState(true);
  const [isRedirectOpen, setRedirectOpen] = useState(false);
  const [redirectData, setRedirectData] = useState();
  const [isCbzRedirectOpen, setIsCbzRedirectOpen] = useState(false);
  const [dynmicMethod, setDynmicMethod] = useState([]);
  const [dynmicTypes, setDynmicTypes] = useState([]);
  const [sendForm, setSendForm] = useState({
    currency: "",
    amount: "",
    bankName: "",
    CardNumber: "",
    Cvv: "",
    Expiry: "",
    CardName: "",
    Type:"",
  });
  const [bankDetails, setBankDetails] = useState([]);
  const [isCardFormOpen, setIsCardFormOpen] = useState(false);

  useEffect(() => {
    let jsonparse = mData?.subResult?.Permissions
      ? JSON.parse(mData?.subResult?.Permissions)
      : null;
    setActionData(jsonparse);
  }, [mData?.subResult?.Permissions]);

  useEffect(() => {
    fetchMerchantBankDetails();
    fetcdynmicMethods();
  }, []);

  useEffect(() => {
    fetchaBankDetails();
  }, [sendForm.bankName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm({ ...sendForm, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    if (value === "IVERI-VISA" || value === "IVERI-MASTER") {
      setIsCardFormOpen(true);
    }
    if(name === "bankName"){
     fetchGatewaysTypes(value)
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!sendForm.amount || isNaN(sendForm.amount) || sendForm.amount <= 0) {
      errors.amount = "Please enter a valid amount greater than zero.";
    }
    if (!sendForm.bankName) {
      errors.bankName = "Please select bank";
    }
    if (sendForm.bankName === "CBZCARD") {
      if (!sendForm.CardNumber) {
        errors.CardNumber = "Please enter a valid card number";
      }
      if (!sendForm.Expiry) {
        errors.Expiry = "Please enter a valid expiry";
      }
      if (!sendForm.CardName) {
        errors.CardName = "Please enter a card  holder name";
      }
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleProceed = (nextStep) => {
    const isValid = validateForm();
    if (nextStep >= 1 && nextStep <= 3 && isValid) {
      setCurrentStep(nextStep);
      setIsCardFormOpen(false)
    } else if (nextStep === 0) {
      setCurrentStep(1);
    }
  };

  const handlePopup = () => {
    setPopupOpen(true);
  };

  const handleCheckAndDeposit = () => {
    if (!pin || isNaN(pin) || pin.length !== 4) {
      setPinError("PIN must be a 4-digit number.");
      return;
    }
    setPinError("");
    walletToBankTransafer();
  };

  const walletToBankTransafer = async () => {
    try {
      setIsPinbutton(false);
      setNetTotalAfterSuccess("");
      const selectedBank = bankDetails[0];
      const formData = {
        amount: sendForm.amount,
        currency: sendForm.currency,
        bankId: selectedBank ? selectedBank.BankNameId : "",
        pin: pin,
        IPAddress: iPAddress,
        Getway_Name: "Paynow",
        Method: sendForm.bankName,
        CardNumber: sendForm.CardNumber,
        Cvv: sendForm.Cvv,
        Expiry: sendForm.Expiry,
        CardName: sendForm.CardName,
        method: "ecocash",
        TransactionId: id,
        ModuleType:sendForm.Type
      };
      let response;
      if (sendForm.bankName === "Paynow" || sendForm.bankName === "PaynowAccount") {
        response = await paynowDeposit(formData);
      } else if (sendForm.bankName === "CBZ" && (sendForm.Type === "IVERI-VISA" ||  sendForm.Type === "IVERI-MASTER") ) {
        response = await initiateCbzTransaction(formData);
      } else if (sendForm.bankName === "CBZ" && (sendForm.Type === "EFT-VISA" ||  sendForm.Type === "EFT-MASTER")) {
        response = await initiateEFTCopyAndPayTransaction(formData);
      } else if (sendForm.bankName === 'CABS') {
        response = await initiateCABSCopyAndPayTransaction(formData)
      } else {
        response = await postBankToWallet(formData);
      }

      if (response?.status === "success" && response?.cabs) {
        setIsCbzRedirectOpen(true);
        setRedirectData(response.cabs);
      }

      if (response?.status === "success" && response?.zimswitchResult) {
        setIsCbzRedirectOpen(true);
        setRedirectData(response.zimswitchResult);
      }

      if (response?.status === "success" && response?.threeSecure) {
        setIsCbzRedirectOpen(true);
        setRedirectData(response.threeSecure);
      }

      if (response?.paynowResult?.redirectUrl) {
        setRedirectOpen(true);
        setRedirectData(response.paynowResult);
      }

      if (response.status === "success") {
        setTimeout(() => {
          handleProceed(currentStep + 1);
        }, 1000);

        setPopupOpen(false);
        toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right", });

        setTransactionDetails(response.transactionDetails);
        setSendForm({
          bankName: "",
          amount: "",
        });

      } else if (response.status === "failed") {
        toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right", });
      }
      setPin("");
      setNetTotalAfterSuccess(response.netTotal);
      setIsPinbutton(true);
    } catch (error) {
      if (error?.response?.data?.message) {
        toastr.warning(error?.response?.data?.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right", });
      } else {
        toastr.warning(error);
      }
      setPin("");
      setIsPinbutton(true);
    }
  };

  const fetchMerchantBankDetails = async () => {
    try {
      const response = await getMerchantBankDetailsForSelect();
      setValueForBankDetails(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchaBankDetails = async () => {
    const formData = {
      bankName: sendForm.bankName,
    };
    try {
      const response = await getMerchantBankDetailsUsingNameapi(formData);
      setBankDetails(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const fecthTaxesFrombackend = async () => {
    try {
      const detectedType = cardTypeDetector(sendForm?.CardNumber);
      const moduletypeid = await gatewayModuleTypeId(sendForm.Type)
      const formData = {
        Amount: parseFloat(sendForm.amount),
        Module: "933ac49c-1e75-4189-be51-aba55385ad5d",
        Module_Type: moduletypeid.id,
        Nature: "BUSINESS",
        Source: detectedType === "visa" ? "CBZ" :
          detectedType === "mastercard" ? "CBZ" :
            "WALLET",
      };
      const response = await checkTaxCalclations(formData);
      setTaxes(response.result);
      setNetTotal(response.netTotal);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResertForm = () => {
    setSendForm({
      amount: "",
      bankName: "",
    });
    setPin("");
    handleProceed(0)
  };

  const handleCloseModal = () => {
    setRedirectOpen(false);
  };
  const handleCloseCardForm = () => {
    setIsCardFormOpen(false);
  };

  const cardTypeDetector = (cardNumber) => {
    const regexes = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
    };
    for (const [type, regex] of Object.entries(regexes)) {
      if (regex.test(cardNumber)) {
        return type;
      }
    }
    return '';
  };

  useEffect(() => {
    if (id) {
      setCurrentStep(2)
      fetchPaymentTransactionDetails();
      setSendForm((prevForm) => ({
        ...prevForm,
        bankName: "CBZCARD"
      }));
    }
  }, [id])

  const fetchPaymentTransactionDetails = async () => {
    try {
      const formData = { id: id }
      const response = await fetchmerchantRefundPaynmentDetails(formData)
      const result = JSON.parse(response.result.Tax_Details);
      setTaxes(result);
    } catch (error) {
      console.log(error);
    }
  }

  const fetcdynmicMethods = async () =>{
    try {
      const response = await getDynmicDepositMethods();
      setDynmicMethod(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const fetchGatewaysTypes = async (item) =>{
    try {
      const formData = {
        Module:item
      }
      const response = await fetchDynmicDepositType(formData)
      setDynmicTypes(response.result)
        } catch (error) {
      console.log(error);
    }
  }

  const componentRef = useRef();
  document.title = "GET Platform | Deposit";

  return (
    <>
      <RedirectPopup
        isRediectOpen={isRedirectOpen}
        redirectData={redirectData}
        handleCloseModal={handleCloseModal}
      />

      {isCbzRedirectOpen && (
        <Secure3DPage
          htmlContent={redirectData}
        />
      )}

      <PinModal
        isOpen={popupOpen}
        pin={pin}
        setPin={setPin}
        pinError={pinError}
        handleCheckAndDeposit={handleCheckAndDeposit}
        setPopupOpen={setPopupOpen}
        isPinButton={isPinButton}
      />
      <CardForm
        show={isCardFormOpen}
        handleClose={handleCloseCardForm}
        sendForm={sendForm}
        validationErrors={validationErrors}
        handleChange={handleChange}
        handleProceed={handleProceed}
        fecthTaxesFrombackend={fecthTaxesFrombackend}
      />
      <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Container className="mt-5">
          <Breadcrumbs title="GET" breadcrumbItem="Deposit" />
          <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <Row>
              <Col md={{ offset: 3, size: 6 }}>
                <h1 className="text-center mb-4 heading_for_progress_bar title-color-common">DEPOSIT</h1>
                <p className="text-center steps_text_progress_bar">Step {currentStep} of 3</p>
                <ProgressBar currentStep={currentStep} />
                {currentStep === 1 && (
                  <Step1
                    sendForm={sendForm}
                    handleChange={handleChange}
                    validationErrors={validationErrors}
                    valueForBankDetails={valueForBankDetails}
                    handleProceed={handleProceed}
                    fecthTaxesFrombackend={fecthTaxesFrombackend}
                    profileData={userDataa}
                    dynmicMethod={dynmicMethod}
                    dynmicTypes={dynmicTypes}
                  />
                )}
                {currentStep === 2 && (
                  <Step2
                    sendForm={sendForm}
                    handleProceed={handleProceed}
                    handlePopup={handlePopup}
                    netTotal={netTotal}
                    taxes={taxes}
                    handleResetForm={handleResertForm}
                    id={id}
                    userDataa={userDataa}
                  />
                )}
                {currentStep === 3 && (
                  <Step3
                    transactionDetails={transactionDetails}
                    netTotalAfterSuccess={netTotalAfterSuccess}
                    componentRef={componentRef}
                    handleResertForm={handleResertForm}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default DepositMoneyIndex;
