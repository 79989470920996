// import React from 'react';
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

// const ScanPayPopup = ({ isOpen, socketScanPay, onClose,}) => {
//   // const history = useHistory(); 

//   // const handleGoToPage = (item) => {
//   //   history.push(`/scan-pay/${item}`); 
//   // };

//   return (
//     <Modal isOpen={isOpen} toggle={onClose} centered>
//       <ModalHeader toggle={onClose}>New Scan Pay Request</ModalHeader>
//       <ModalBody>
//         <p>{socketScanPay?.message}</p>
//       </ModalBody>
//       <ModalFooter>
//         <Link to={`scan-pay/${socketScanPay?.Data?.id}`}>
//         <Button color="primary"
//         //  onClick={() =>handleGoToPage(socketScanPay?.Data?.id)}
//          >
//           Go to Payment Page
//         </Button>
//         </Link>
//         <Button color="secondary" onClick={onClose}>
//           Close
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default ScanPayPopup;


import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ScanPayPopup = ({ isOpen, socketScanPay, onClose, setShowPopup }) => {
  const history = useHistory();
  const handleGoToPage = (item) => {
    setShowPopup(false)
    history.push( `/scan-pay/${item}`)
    // window.history.pushState(null, '', `/scan-pay/${item}`);
    // window.location.replace(`/scan-pay/${item}`);
  };
  
  
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>New Scan Pay Request</ModalHeader>
      <ModalBody>
        <p>{socketScanPay?.message}</p>
      </ModalBody>
      <ModalFooter>
      <Button color="primary"
         onClick={() =>handleGoToPage(socketScanPay?.Data?.id)}
         >
          Go to Payment Page
        </Button>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScanPayPopup;

