import React, { useState, useEffect } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import Toggle from "react-toggle";
import { setDefaultCurrency } from "Context/ContextApi";

const HeaderBalance = () => {
    const {
        balance,
        userDataa,
        fetchUserProfile,
        gTotalTranscationPerDay,
        gNoOfTransaction,
        gTotalInsights,
    } = useData();
    const [isToggle, setIsToggle] = useState();
    useEffect(() => {
        if (userDataa?.Currency === "USD") {
            setIsToggle(true);
        } else if (userDataa?.Currency === "ZiG") {
            setIsToggle(false);
        }
    }, [userDataa?.Currency]);

    const handleToggle = async () => {
        const newCurrency = isToggle ? "ZiG" : "USD";
        const formData = {
            Currency: newCurrency,
        };
        setIsToggle(!isToggle);
        try {
            const response = await setDefaultCurrency(formData);
            toastr.success(response.message);
            fetchUserProfile();
            gTotalTranscationPerDay(), gNoOfTransaction(), gTotalInsights();
        } catch (error) {
            setIsToggle(!isToggle);

            toastr.error(error?.response?.data?.message);
        }
    };

    return (
        <>
            <div className="header-balance">
                <span>
                    ZiG
                </span>
                <Toggle
                    id="currency-toggle"
                    defaultChecked={isToggle}
                    aria-labelledby="currency-label"
                    checked={isToggle}
                    onChange={handleToggle}
                />
                <span>
                    USD
                </span>
            </div>
        </>
    );
};

export default HeaderBalance;
