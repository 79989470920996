import React from 'react';

const ScanPayOrderStatus = ({ data }) => {
    console.log(data);
    
    let statusMessage = '';
    let statusClass = '';
    switch (data.Status) {
        case 'Completed':
            statusMessage = 'Completed';
            statusClass = 'status-completed';
            break;
        case 'Cancelled':
            statusMessage = 'Cancelled';
            statusClass = 'status-failed';
            break;
        case 'Requested':
            statusMessage = 'Requested';
            statusClass = 'status-expired';
            break;
        case 'Retry':
            statusMessage = 'Retry';
            statusClass = 'status-pending';
            break;
        case 'Initiate':
            statusMessage = 'Initiate';
            statusClass = 'status-pending';
            break;
        default:
            statusMessage = 'Initiate';
            statusClass = 'status-unknown';
            break;
    }

    return (
        <h3 className={`refund_payment_details_status ${statusClass}`}>
            {statusMessage}
        </h3>
    );
};

export default ScanPayOrderStatus;