
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CaptureNationalID from "../../components/Verification/CaptureNationalID";
import LivenessCheck from "../../components/Verification/LivenessCheck";
import HoldNationalID from "../../components/Verification/HoldNationalID";
import { saveStepIndex, verifyTheNationalWithNumber } from 'Context/ContextApi';
import FinalStep from "../../components/Verification/FinalStep"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from 'Context/ContextProvider';

const NationalIdVerification = () => {
    const { userDataa, fetchData } = useData();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [nationalID, setNationalID] = useState(null);
    const [cameraAvailable, setCameraAvailable] = useState(true);

    useEffect(() => {
        checkCameraAvailability();
    }, []);

    useEffect(() => {
        if (userDataa?.KycStep) {
            setCurrentStepIndex(userDataa?.KycStep || 0);
        }
        if (userDataa?.kycFaceRetry > 3) {
            setCurrentStepIndex(3)
        }
    }, [userDataa?.KycStep])
    const checkCameraAvailability = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setCameraAvailable(videoDevices.length > 0);
        } catch (error) {
            console.error('Error checking camera availability', error);
            setCameraAvailable(false);
        }
    };

    const saveCurrentStepIndex = async () => {
        try {
            const formData = {
                KycStep: currentStepIndex
            }
            await saveStepIndex(formData);
        } catch (error) {
            console.error('Error saving current step index', error);
        }
    };

    const handleNext = () => {
        setCurrentStepIndex(prevIndex => Math.min(prevIndex + 1, steps.length - 1));
    };

    const handleNationalIDCapture = async (image) => {
        try {
            const byteString = atob(image.split(',')[1]);
            const mimeString = image.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });
            const file = new File([blob], 'captured-image.jpg', { type: mimeString });
            setNationalID(file);
            const isForm = {
                NationalIDImg1: nationalID || file
            }
            const formData = new FormData()
            for (const [key, value] of Object.entries(isForm)) {
                if (value instanceof File) {
                    formData.append(key, value);
                } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                    value.forEach((file, index) => {
                        formData.append(`${key}`, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
            const response = await verifyTheNationalWithNumber(formData)
            await saveCurrentStepIndex();
            toastr.success(response.message)

            handleNext();
        } catch (error) {
            if (error?.response?.data?.message) {
                toastr.error(error?.response?.data?.message)
                // handleNext();
                await saveCurrentStepIndex();
            } else {
                toastr.error(error?.message)
            }
            console.error('Error handling national ID capture', error);
            // toastr.error('Error capturing National ID');
        }
    };

    const handleLivenessCheckComplete = async () => {
        handleNext();
        setTimeout(() => { saveCurrentStepIndex(); }, 1000);
    };

    const handleIDHeldCapture = async (image) => {
        try {
            const isForm = {
                NationalIDImg1: nationalID
            };
            const formData = new FormData();
            for (const [key, value] of Object.entries(isForm)) {
                if (value instanceof File) {
                    formData.append(key, value);
                } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                    value.forEach((file) => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
            const response = await verifyTheNationalWithNumber(formData);
            toastr.success(response.message);
            handleNext();
        } catch (error) {
            if (error?.response?.data?.message) {
                toastr.error(error?.response?.data?.message);
            } else {
                toastr.error(error?.message);
            }
        }
    };

    const steps = [
        {
            name: 'CaptureNationalID',
            component: <CaptureNationalID onCapture={handleNationalIDCapture} />
        },
        ...(cameraAvailable ? [{
            name: 'LivenessCheck',
            component: <LivenessCheck nationalID={nationalID} onComplete={handleLivenessCheckComplete} />
        }] : []),
        {
            name: 'HoldNationalID',
            component: <HoldNationalID onCapture={handleIDHeldCapture} handleNextStep={handleLivenessCheckComplete} />
        },
        {
            name: 'Success',
            component: <FinalStep userDataa={userDataa} />
        }
    ];

    useEffect(() => {
        if (!cameraAvailable && currentStepIndex > 2) {
            setCurrentStepIndex(steps.length - 1);
        }
    }, [cameraAvailable, currentStepIndex]);

    const currentStep = steps[currentStepIndex];

    return (
        <Container className="app-container">
            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${(currentStepIndex / (steps.length - 1)) * 100}%` }}
                    />
                </div>
            </div>
            <div className="step-indicator">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={`step ${index < currentStepIndex ? 'completed' : index === currentStepIndex ? 'active' : ''}`}
                        >
                            {index + 1}
                        </div>
                        {index < steps.length - 1 && (
                            <div
                                className={`step-line ${index < currentStepIndex ? 'active' : ''}`}
                                style={{
                                    width: `${100 / (steps.length - 1)}%`,
                                    left: `${(index + 1) * (100 / (steps.length - 1))}%`
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div className="step-content">
                {currentStep?.component}
            </div>
        </Container>
    );
};

export default NationalIdVerification;
