// import React, { useRef, useState, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import axios from 'axios';
// import profileImage from "./ss.png";
// import { Input } from 'reactstrap';
// import { merchantFaceApiDE } from 'Context/ContextApi';
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";
// import Dropzone from 'react-dropzone';

// const HoldNationalID = ({ onCapture, handleNextStep }) => {
//   const webcamRef = useRef(null);
//   const [status, setStatus] = useState('');
//   const [hasCamera, setHasCamera] = useState(true);
//   const [imageSrc, setImageSrc] = useState(null);

//   useEffect(() => {
//     const checkCameraAvailability = async () => {
//       try {
//         const devices = await navigator.mediaDevices.enumerateDevices();
//         const videoDevices = devices.filter(device => device.kind === 'videoinput');
//         if (videoDevices.length === 0) {
//           setHasCamera(false);
//         }
//       } catch (error) {
//         console.error('Error checking camera availability', error);
//         setHasCamera(false);
//       }
//     };

//     checkCameraAvailability();
//   }, []);

//   const captureIDImage = async () => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       const blob = await fetch(imageSrc).then(res => res.blob());
//       const file = new File([blob], 'national_id.jpg', { type: 'image/jpeg' });

//       try {
//         const formData = new FormData();
//         formData.append("image", file);

//         const response = await merchantFaceApiDE(formData);
//         handleNextStep()
//         console.log(response);
//         setStatus('National ID captured successfully!');
//         if (onCapture) onCapture();
//       } catch (error) {
//         console.error('Error capturing National ID', error);
//         setStatus('Error capturing National ID. Please try again.');
//       }
//     }
//   };

//   const formatBytes = (bytes, decimals = 2) => {
//     if (bytes === 0) return "0 Bytes";
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//   };

//   const handleFileUpload = async (event) => {
//     const file = event.target.files[0];
//     const handleAcceptedFiles = useCallback((files) => {
//       files.forEach(file => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setImageSrc(reader.result);
//         };
//         reader.readAsDataURL(file);
//       });
//     }, []);


//     if (file) {
//       setImageSrc(URL.createObjectURL(file));
//       const formData = new FormData();
//       formData.append("image", file);

//       try {
//         const response = await merchantFaceApiDE(formData);
//         console.log(response);
//         handleNextStep()
//         toastr.success(response?.message)
//         setStatus('National ID uploaded successfully!');
//         if (onCapture) onCapture();
//       } catch (error) {
//         if (error?.response?.data?.message) {
//           toastr.error(error?.response?.data?.message)
//         } else {
//           toastr.error(error?.message)
//         }
//         console.error('Error uploading National ID', error.response);
//         setStatus('Error uploading National ID. Please try again.');
//       }
//     }
//   };

//   return (
//     <div className="hold-id-container">
//       {hasCamera ? (
//         <div className="webcam-wrapper">
//           <div className="guide-overlay">
//             <img src={profileImage} alt="Guide" />
//           </div>
//           <Webcam
//             audio={false}
//             ref={webcamRef}
//             screenshotFormat="image/jpeg"
//             width="80%"
//             height="80%"
//           />
//           <button onClick={captureIDImage}>Capture National ID</button>
//         </div>
//       ) : (
//         <div className="upload-wrapper">
//           <h2>Hold Your National ID</h2>
//           {/* <Input
//             type="file"
//             accept="image/*"
//             onChange={handleFileUpload}
//           /> */}

//           <Dropzone onDrop={handleFileUpload} multiple>
//             {({ getRootProps, getInputProps }) => (
//               <div className="dropzone" {...getRootProps()}>
//                 <input {...getInputProps()} />
//                 <div className="dz-message needsclick">
//                   <div className="mb-3">
//                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                   </div>
//                   <h4>Drop files here or click to upload.</h4>
//                 </div>
//               </div>
//             )}
//           </Dropzone>
//           {imageSrc && <img src={imageSrc} alt="Uploaded National ID" />}
//         </div>
//       )}
//       {status && <p>{status}</p>}
//     </div>
//   );
// };

// export default HoldNationalID;











import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import profileImage from './ss.png';
import { Button, Input, Spinner } from 'reactstrap';
import { merchantFaceApiDE } from 'Context/ContextApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Dropzone from 'react-dropzone';

const HoldNationalID = ({ onCapture, handleNextStep }) => {
  const webcamRef = useRef(null);
  const [status, setStatus] = useState('');
  const [hasCamera, setHasCamera] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        if (videoDevices.length === 0) {
          setHasCamera(false);
        }
      } catch (error) {
        console.error('Error checking camera availability', error);
      }
    };

    checkCameraAvailability();
  }, []);

  const captureIDImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      const blob = await fetch(imageSrc).then(res => res.blob());
      const file = new File([blob], 'national_id.jpg', { type: 'image/jpeg' });
      try {
        setImageSrc(file);
        const formData = new FormData();
        formData.append("image", file);
        const response = await merchantFaceApiDE(formData);
        if (response.message !== "Different Person") {
          handleNextStep();
          setStatus('National ID captured successfully!');
          setLoader(true)
        }
        toastr.success(response.message);
      } catch (error) {
        console.error('Error capturing National ID', error);
        setStatus('Error capturing National ID. Please try again.');
        setLoader(false)
      }
    }
  };

  const handleFileUpload = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      setImageSrc(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("kycFaceRetry", "1")
      formData.append("image", file);

      try {
        const response = await merchantFaceApiDE(formData);
        if (response.message !== "Different Person") {
          handleNextStep();
          setStatus('National ID captured successfully!');
          setLoader(true)
        }
        toastr.success(response?.message);
        window.location.reload();
        setLoader(false)
      } catch (error) {
        toastr.error(error?.response?.data?.message || error?.message);
        console.error('Error uploading National ID', error.response);
        setStatus('Error uploading National ID. Please try again.');
        setLoader(false)
      }
    }
  };

  return (
    <div className="hold-id-container">
      {hasCamera ? (
        <div className="webcam-wrapper">
          <div className="guide-overlay">
            <img src={profileImage} alt="Guide" />
          </div>
          {/* {!loader && */}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="80%"
            height="80%"
          />
          {/* } */}
          <Button onClick={captureIDImage}>Capture National ID</Button>
          {/* {loader &&<Spinner>
            Loading...
          </Spinner>} */}
        </div>
      ) : (
        <div className="upload-wrapper">
          <h2>Upload Your National ID</h2>
          <Dropzone onDrop={handleFileUpload} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          {imageSrc && <Spinner>
            Loading...
          </Spinner>}
          {imageSrc && <img src={imageSrc} alt="Uploaded National ID" />}
        </div>
      )}
      {status && <p>{status}</p>}
    </div>
  );
};

export default HoldNationalID;

