import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Cards from 'react-credit-cards';

const CardForm = ({ show, handleClose, sendForm, validationErrors, handleChange, handleProceed, fecthTaxesFrombackend, }) => {
    const [focus, setFocus] = useState('');

    const handleNext = () => {
        const isValid = handleProceed(2);
        if (!isValid) {
            fecthTaxesFrombackend();
        }
    };

    return (
        <Modal isOpen={show} centered>
            <ModalHeader toggle={handleClose}> Card Details</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="cardNumber">Card Number</Label>
                        <Input
                            type="text"
                            id="cardNumber"
                            name="CardNumber"
                            placeholder="Enter card number"
                            value={sendForm.CardNumber}
                            onChange={handleChange}
                            onFocus={(e) => setFocus(e.target.name)}
                        />
                        {validationErrors.CardNumber && (
                            <small className="text-danger">{validationErrors.CardNumber}</small>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label for="cardName">Card Name</Label>
                        <Input
                            type="text"
                            id="cardName"
                            name="CardName"
                            placeholder="Enter card name"
                            value={sendForm.CardName}
                            onChange={handleChange}
                            onFocus={(e) => setFocus(e.target.name)}
                        />
                        {validationErrors.CardName && (
                            <small className="text-danger">{validationErrors.CardName}</small>
                        )}
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="expiry">Expiry Date</Label>
                                <Input
                                    type="text"
                                    id="expiry"
                                    name="Expiry"
                                    placeholder="MM/YY"
                                    value={sendForm.Expiry}
                                    onChange={handleChange}
                                    onFocus={(e) => setFocus(e.target.name)}
                                />
                                {validationErrors.Expiry && (
                                    <small className="text-danger">{validationErrors.Expiry}</small>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="cvv">CVV</Label>
                                <Input
                                    type="text"
                                    id="cvv"
                                    name="Cvv"
                                    placeholder="CVV"
                                    value={sendForm.Cvv}
                                    onChange={handleChange}
                                    onFocus={(e) => setFocus(e.target.name)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Cards
                        number={sendForm.CardNumber}
                        name={sendForm.CardName}
                        expiry={sendForm.Expiry}
                        cvc={sendForm.Cvv}
                        focused={focus}
                    />
                </Form>
            </ModalBody>
            <ModalFooter> 
                {/* <Button color="secondary" onClick={handleClose}> Close </Button> */}
                <Button color="primary" onClick={handleNext}> Proceed </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CardForm;

























// import React from 'react';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label } from 'reactstrap';
// import { usePaymentInputs } from 'react-payment-inputs';
// import images from 'react-payment-inputs/images';

// const CardForm = ({ show, handleClose, sendForm, handleChange, handleProceed }) => {
//   const { getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps, meta } = usePaymentInputs();

//   return (
//     <Modal isOpen={show} centered>
//       <ModalHeader toggle={handleClose}>CBZ Card Details</ModalHeader>
//       <ModalBody>
//         <Form>
//           <FormGroup>
//             <Label for="cardNumber">Card Number</Label>
//             <div>
//               <svg {...getCardImageProps({ images })} />
//               <input
//                 {...getCardNumberProps({
//                   onChange: (e) => handleChange({ target: { name: 'CardNumber', value: e.target.value } }),
//                 })}
//                 value={sendForm.CardNumber}
//               />
//             </div>
//             {meta.isTouched && meta.error && <span>{meta.error}</span>}
//           </FormGroup>
//           <FormGroup>
//             <Label for="cardName">Card Name</Label>
//             <input
//               type="text"
//               id="cardName"
//               name="CardName"
//               placeholder="Enter card name"
//               value={sendForm.CardName}
//               onChange={handleChange}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="expiry">Expiry Date</Label>
//             <input
//               {...getExpiryDateProps({
//                 onChange: (e) => handleChange({ target: { name: 'Expiry', value: e.target.value } }),
//               })}
//               value={sendForm.Expiry}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="cvv">CVV</Label>
//             <input
//               {...getCVCProps({
//                 onChange: (e) => handleChange({ target: { name: 'Cvv', value: e.target.value } }),
//               })}
//               value={sendForm.Cvv}
//             />
//           </FormGroup>
//         </Form>
//       </ModalBody>
//       <ModalFooter>
//         <Button color="secondary" onClick={handleClose}>
//           Close
//         </Button>
//         <Button color="primary" onClick={() => handleProceed(2)}>
//           Proceed
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

// export default CardForm;

