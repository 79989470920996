import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, ModalHeader, Popover } from "reactstrap";
import { Label, Text } from "recharts";
import merchantLogo from "../../assets/images/NewImage/merchant_Portal_Logo.png"

export default function TaxDetailsShow({ data, setisTaxDetails, isTaxDetails }) {
    const parseData = data ?? [];
    const offerDetailsObject = parseData.find((item) => item.OfferDetails === 'Offer Details');
    const offerDetailsValue = offerDetailsObject ? offerDetailsObject?.Value : null;

    return (
            <Modal isOpen={isTaxDetails} size="lg" center toggle={() => setisTaxDetails(!isTaxDetails)}
            >
                <ModalHeader toggle={() => setisTaxDetails(!isTaxDetails)}>Tax Details</ModalHeader>
                <div className="m-auto pb-10 pt-10 tax_details_transaction_container ps-5 pe-5">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="tax_details_header_main mb-3">
                            <img src={merchantLogo} alt="icon" width={100} height={100} />
                        </div>
                        {parseData && parseData?.map((item, index) => (
                            <>
                                <div key={index} className={`tax-details-transacrtion-main-container ps-4 pe-4  ${item.Title === "Net Tax" ? 'net_tax_class' :
                                    item.TotalTitle === 'Net Refund' ? "net_total_class"
                                        : item.Title === 'Refund Net Tax'
                                            || item.TotalTitle === 'Net Total' ? "net_total_class"
                                            : item.TotalTitle === 'Net Refund' ? "net_total_class"
                                                : item.TotalTitle === 'Final Adjustment' ? "p-5"
                                                    : item.TotalTitle === 'Refund Net Total' ? ' deposite_money_second_step_text_border_line' : ''}`}>

                                    {item.Title ? (
                                        <span >{item.Title} {item.Title === "Discount Amount" && (<small className='tax_details_star'>***</small>)}</span>
                                    ) : (item.TotalTitle && <span>{item.TotalTitle} </span>
                                    )}
                                    {(typeof item.Value !== 'object' && item.Value !== undefined) && <div className="tax-details-Amount"><span>ZiG</span> <span>{parseFloat(item.Value).toFixed(2)}</span></div>}
                                </div>

                                <div className={`taxxxxx ${item.Title === "Tax Details" || item.Title === "Refund Tax Details" ||
                                    item.Title === "Refund Transaction Tax Details" ? 'transaction_details_background' : ""}`}>

                                    {item.Title === "Tax Details" || item.Title === "Refund Tax Details" || item.Title === "Refund Transaction Tax Details" && <label>Tax</label>}
                                    {item?.Tax && item?.Tax.map((taxItem, taxIndex) => (
                                        <div key={taxIndex} className="transaction_details ps-8 pe-4">
                                            <div className="transaction_detailsForTaxDetals">
                                                <div className="transaction_detail_refund">
                                                    <div className="d-flex gap-2">
                                                        <span>{taxItem.Particular}</span>
                                                        {taxItem.Percentage && <small>{taxItem.Percentage} %</small>}
                                                    </div>
                                                    <div>
                                                        {taxItem.RefundRate && <small className='ps-4'>Refund Rate ({taxItem.RefundRate} %)</small>}
                                                    </div>
                                                </div>

                                                <div className="transaction_detail_Amount">
                                                    <span> {parseFloat(taxItem.NetTotal).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}

                        {data?.Remarks && (
                            <div className="tax_details_remarks">
                                <span>Remarks : {data?.Remarks}</span>
                            </div>
                        )}

                        {offerDetailsValue && (
                            <div className="tax_details_offer_details">
                                <p><span className="tax_details_star">***</span><span>Discount Details</span></p>
                                <div className="tax_descount_details_main_container ms-6">
                                    {offerDetailsValue?.OffersName && <div className="tax_details_descount_details">
                                        <span>Offer Name:</span>
                                        <span>{offerDetailsValue?.OffersName}</span>
                                    </div>}
                                    {offerDetailsValue?.Type && <div className="tax_details_descount_details">
                                        <span>Type : </span>
                                        <span>{offerDetailsValue?.Type}</span>
                                    </div>}
                                    {offerDetailsValue?.Method && <div className="tax_details_descount_details">
                                        <span>Method : </span>
                                        <span>{offerDetailsValue?.Method}</span>
                                    </div>}
                                    {offerDetailsValue?.DiscountPercentage && <div className="tax_details_descount_details">
                                        <span>Discount % : </span>
                                        <span>{offerDetailsValue?.DiscountPercentage}</span>
                                    </div>}

                                    {offerDetailsValue?.DiscountAmount && <div className="tax_details_descount_details">
                                        <span>Amount :  </span>
                                        <span>{offerDetailsValue?.DiscountAmount}</span>
                                    </div>}

                                    {offerDetailsValue?.AllowedMaxDiscount && <div className="tax_details_descount_details">
                                        <span>Allowed Max % : </span>
                                        <span>{offerDetailsValue?.AllowedMaxDiscount}</span>
                                    </div>}
                                    {offerDetailsValue?.MinTransactionAmount && <div className="tax_details_descount_details">
                                        <span>Min Transaction Amount : </span>
                                        <span>{offerDetailsValue?.MinTransactionAmount}</span>
                                    </div>}
                                    {offerDetailsValue?.MaxTotalUsage && <div className="tax_details_descount_details">
                                        <span>Max Total Usage: </span>
                                        <span>{offerDetailsValue?.MaxTotalUsage}</span>
                                    </div>}
                                    {offerDetailsValue?.Status && <div className="tax_details_descount_details">
                                        <span>Status : </span>
                                        <span>{offerDetailsValue?.Status}</span>
                                    </div>}

                                    {offerDetailsValue?.StartDate && <div className="tax_details_descount_details">
                                        <span>Start Date : </span>
                                        <span>
                                            <time dateTime={formatDate(offerDetailsValue?.StartDate, 'YYYY-MM-DD')}>
                                                {formatDate(offerDetailsValue?.StartDate)}
                                            </time>
                                        </span>
                                    </div>}
                                    {offerDetailsValue?.EndDate && <div className="tax_details_descount_details">
                                        <span>End Date : </span>
                                        <span>
                                            <time dateTime={formatDate(offerDetailsValue?.EndDate, 'YYYY-MM-DD')}>
                                                {formatDate(offerDetailsValue?.EndDate)}
                                            </time>
                                        </span>
                                    </div>
                                    }
                                </div>
                            </div>
                        )}
                        <div className="tax_details_footer">
                            <img src={merchantLogo} alt="icon" width={40} height={40} />
                            <span>Taxes are subjected to the <Link to={"/"}>Terms & Conditions.</Link></span>
                            <span>GETransfer Pvt Ltd</span>
                        </div>
                    </div>
                </div>
            </Modal>
    );
}

function formatDate(dateString, format = 'YYYY-MM-DD HH:mm:ss') {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
}

