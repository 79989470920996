import React from "react";
import { Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import { Sendmoneyprint } from "pages/SendMoney/Sendmoneyprint";
import success from "../../../assets/images/NewImage/Invoice-Logo.png";
import { CURRENCY } from "components/Common/Currency";
import print from "../../../assets/images/NewImage/printing.png";

const Step3 = ({
  transactionDetails,
  netTotalAfterSuccess,
  componentRef,
  handleResertForm,
}) => {
  return (
    <div>
      <div className="deposite_money_step_three_main">
        <img
          src={success}
          alt="success"
          className="success_image"
        />
        <h5 className="text-center">Success!</h5>
        <p className="text-center">
          Money has been successfully deposited to your
          wallet. you can see the details under the
          transaction details.
        </p>
        <div className="deposit_money_step_three_box">
          <h5>Deposited money</h5>
          <h1 className="title-color-common" >{CURRENCY} {parseFloat(netTotalAfterSuccess)}</h1>
        </div>
        <div className="deposit_monet_step_three_button">
          <ReactToPrint
            trigger={() => (
              <Button type="button" className="deposit_monet_step_three_print procces_Button">
                <img src={print} alt="print" />
                Print code
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button className="deposit_monet_step_three_deposit_again" onClick={handleResertForm}>
            Deposit again
          </Button>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <Sendmoneyprint transactionDetails={transactionDetails} />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <ReactToPrint
          trigger={() => <Button color="primary">Print Receipt</Button>}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default Step3;
