
// import React, { useEffect, useState, useRef, useCallback } from 'react';
// import Webcam from 'react-webcam';
// import Cropper from 'react-cropper';
// import 'cropperjs/dist/cropper.css';
// import { Button, Input, Label } from 'reactstrap';
// import Dropzone from 'react-dropzone';

// const CaptureNationalID = ({ onCapture }) => {
//   const [imageSrc, setImageSrc] = useState(null);
//   const [hasCamera, setHasCamera] = useState(true);
//   const [capturing, setCapturing] = useState(false);
//   const [retry, setRetry] = useState(false);
//   const cropperRef = useRef(null);
//   const webcamRef = useRef(null);

//   useEffect(() => {
//     const checkCameraAvailability = async () => {
//       try {
//         const devices = await navigator.mediaDevices.enumerateDevices();
//         const videoDevices = devices.filter(device => device.kind === 'videoinput');
//         if (videoDevices.length === 0) {
//           setHasCamera(false);
//         }
//       } catch (error) {
//         console.error('Error checking camera availability', error);
//         setHasCamera(false);
//       }
//     };

//     checkCameraAvailability();
//   }, []);

//   useEffect(() => {
//     if (capturing && webcamRef.current) {
//       const interval = setInterval(() => {
//         captureImage();
//       }, 5000);
//       return () => clearInterval(interval);
//     }
//   }, [capturing]);

//   const captureImage = () => {
//     const screenshot = webcamRef.current.getScreenshot();
//     if (screenshot) {
//       setImageSrc(screenshot);
//       setCapturing(false);
//     }
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setImageSrc(reader.result);
//     };
//     reader.readAsDataURL(file);
//   };


//   const handleCrop = () => {
//     if (cropperRef.current) {
//       const croppedImage = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
//       onCapture(croppedImage);
//     }
//   };

//   const handleStartCapture = () => {
//     setCapturing(true);
//     setRetry(false);
//   };

//   const handleRetry = () => {
//     setImageSrc(null);
//     setRetry(true);
//     setCapturing(true);
//   };

//   const formatBytes = (bytes, decimals = 2) => {
//     if (bytes === 0) return "0 Bytes";
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
// };


//   const handleAcceptedFiles = useCallback(async (files) => {
//     const updatedFiles = files.map(file =>
//       Object.assign(file, {
//         preview: URL.createObjectURL(file),
//         formattedSize: formatBytes(file.size),
//       })
//     );
//     const img = new Image();
//     img.src = updatedFiles;
//     img.onload = () => {
//       console.log("1");
//       const width = img.naturalWidth;
//       const height = img.naturalHeight;
//       console.log(width, height);
//       if (width == height) {
//         return false;
//       }
//       return true;
//     }
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setImageSrc(reader.result);
//     };
//     reader.readAsDataURL(updatedFiles);
//     // setSelectedFiles(updatedFiles);
//   }, []);

//   return (
//     <div className="capture-container">
//       {imageSrc ? (
//         <div className="cropper-container">
//           <Cropper
//             src={imageSrc}
//             style={{ height: 400, width: '100%' }}
//             initialAspectRatio={16 / 9}
//             guides={false}
//             ref={cropperRef}
//           />
//           <Button onClick={handleCrop}>Crop & Upload</Button>
//           <Button onClick={handleRetry}>Retry</Button>
//         </div>
//       ) : hasCamera ? (
//         <div className="webcam-container">
//           <h2>Capture Your National ID</h2>
//           <h3>Instructions for Capturing Your National ID</h3>
//           <p>1. Hold your National ID card in front of the camera.</p>
//           <p>2. Ensure that the entire card is visible and centered in the frame.</p>
//           <p>3. Make sure the card is well-lit and free of reflections.</p>
//           <p>4. The system will automatically capture the image when the card is detected.</p>
//           <Webcam
//             audio={false}
//             screenshotFormat="image/jpeg"
//             width="100%"
//             height="100%"
//             ref={webcamRef}
//           />
//           {retry ? (
//             <p>Adjust the camera position and click "Start Capture" to try again.</p>
//           ) : (
//             <Button onClick={handleStartCapture}>Start Capture</Button>
//           )}
//         </div>
//       ) : (
//         <div className="upload-container">
//           <Label>Upload national Doc</Label>
//           {/* <Input type="file" accept="image/*" onChange={handleFileChange} /> */}
//           <Dropzone onDrop={handleAcceptedFiles} multiple>
//             {({ getRootProps, getInputProps }) => (
//               <div className="dropzone">
//                 <div
//                   className="dz-message needsclick"
//                   {...getRootProps()}
//                 >
//                   <input {...getInputProps()} />
//                   <div className="mb-3">
//                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                   </div>
//                   <h4>Drop files here or click to upload.</h4>
//                 </div>
//               </div>
//             )}
//           </Dropzone>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CaptureNationalID;















import React, { useEffect, useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Input, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';

const CaptureNationalID = ({ onCapture }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [hasCamera, setHasCamera] = useState(true);
  const [capturing, setCapturing] = useState(false);
  const [retry, setRetry] = useState(false);
  const cropperRef = useRef(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        if (videoDevices.length === 0) {
          setHasCamera(false);
        }
      } catch (error) {
        console.error('Error checking camera availability', error);
        setHasCamera(false);
      }
    };

    checkCameraAvailability();
  }, []);

  useEffect(() => {
    if (capturing && webcamRef.current) {
      const interval = setInterval(() => {
        captureImage();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [capturing]);

  const captureImage = () => {
    const screenshot = webcamRef.current.getScreenshot();
    if (screenshot) {
      setImageSrc(screenshot);
      setCapturing(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedImage = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
      onCapture(croppedImage);
    }
  };

  const handleStartCapture = () => {
    setCapturing(true);
    setRetry(false);
  };

  const handleRetry = () => {
    setImageSrc(null);
    setRetry(true);
    setCapturing(true);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = useCallback((files) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }, []);
  

  return (
    <div className="capture-container">
      {imageSrc ? (
        <div className="cropper-container">
          <Cropper
            src={imageSrc}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={16 / 9}
            guides={false}
            ref={cropperRef}
          />
          <Button color="primary" onClick={handleCrop}>Crop & Upload</Button>
          <Button color="secondary" onClick={handleRetry}>Retry</Button>
        </div>
      ) : hasCamera ? (
        <div className="webcam-container">
          <h2>Capture Your National ID</h2>
          <h3>Instructions for Capturing Your National ID</h3>
          <p>1. Hold your National ID card in front of the camera.</p>
          <p>2. Ensure that the entire card is visible and centered in the frame.</p>
          <p>3. Make sure the card is well-lit and free of reflections.</p>
          <p>4. The system will automatically capture the image when the card is detected.</p>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            width="100%"
            height="100%"
            ref={webcamRef}
          />
          {retry ? (
            <p>Adjust the camera position and click "Start Capture" to try again.</p>
          ) : (
            <Button color="primary" onClick={handleStartCapture}>Start Capture</Button>
          )}
        </div>
      ) : (
        <div className="upload-container">
          <Label>Upload National ID Document</Label>
          <Dropzone onDrop={handleAcceptedFiles} multiple>
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </div>
  );
};

export default CaptureNationalID;



























