import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody } from "reactstrap";
import { getDashboardWidget } from "Context/ContextApi";
import { useData } from "Context/ContextProvider";
import { CURRENCY } from "components/Common/Currency";


function MiniWidget() {
   const{ dashboardWidgetData, userDataa } = useData();
  const [widgetData, setWidgetData] = useState({
    NumberOfTransactions:"",
    TotalAmount:"",
    TotalPaymentAmount:"",
    TotalRefundAmount:"",
  })
useEffect(()=>{
  setWidgetData({
    NumberOfTransactions:dashboardWidgetData?.result?.NumberOfTransactions,
    TotalAmount:dashboardWidgetData?.result?.TotalAmount,
    TotalPaymentAmount:dashboardWidgetData?.result?.TotalPaymentAmount,
    TotalRefundAmount:dashboardWidgetData?.result?.TotalRefundAmount,
  })
},[dashboardWidgetData])

  const [data, setData] = useState({
    noOfTransaction: "",
    isNoOFTransaction: "true",

    TotalAmountFilter: "",
    TotalAmountStatus: "true",

    TotalPaymentAmountFilter: "",
    TotalPaymentAmountStatus: "true",

    TotalRefundAmountFilter: "",
    TotalRefundAmountStatus: "true",
  })
  useEffect(() => {
    gDashboardWidget();
  }, [userDataa])

  const gDashboardWidget = async () => {
    try {
      const formData = {
        noOfTransaction: data.noOfTransaction,
        isNoOFTransaction: data.isNoOFTransaction,
        TotalAmountFilter: data.TotalAmountFilter,
        TotalAmountStatus: data.TotalAmountStatus,
        TotalPaymentAmountFilter: data.TotalPaymentAmountFilter,
        TotalPaymentAmountStatus: data.TotalPaymentAmountStatus,
        TotalRefundAmountFilter: data.TotalRefundAmountFilter,
        TotalRefundAmountStatus: data.TotalRefundAmountStatus,
      }
      const response = await getDashboardWidget(formData)
      setWidgetData({...widgetData, ...response.result})
    } catch (error) {
      console.log(error);
    }
  }

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Volume Transactions",
      value: `${widgetData.NumberOfTransactions || 0}`,
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Total Inwards",
      value: `${userDataa.Currency} ${widgetData?.TotalAmount || 0}`,
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Payment Requests",
      value: `${userDataa.Currency} ${widgetData?.TotalPaymentAmount || 0}`,
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Total Refunds",
      value: `${userDataa.Currency} ${widgetData?.TotalRefundAmount || 0}`,
      color: "success",
      desc: "From previous period",
    },
  ];

  const handleClick = async (item, cardIndex, buttonIndex) => {

    const newActiveButtons = [...activeButtons];
    newActiveButtons[cardIndex] = buttonIndex;
    setActiveButtons(newActiveButtons);
    const newData = {  };
    switch (cardIndex) {
      case 0:
        data.noOfTransaction = item;
        data.isNoOFTransaction = "true";
        break;
      case 1:
        data.TotalAmountFilter = item;
        data.TotalAmountStatus = "true";
        break;
      case 2:
        data.TotalPaymentAmountFilter = item;
        data.TotalPaymentAmountStatus = "true";
        break;
      case 3:
        data.TotalRefundAmountFilter = item;
        data.TotalRefundAmountStatus = "true";
        break;
      default:
        break;
    }
   await setData(data)
    await gDashboardWidget()
  };
  const [activeButtons, setActiveButtons] = useState(Array(reports.length).fill(-1));
  useEffect(() => {
    const initialActiveButtons = Array(reports.length).fill(3);
    setActiveButtons(initialActiveButtons);
  }, []);
  return (
    <React.Fragment>
      {reports.map((report, index) => (
        <Col sm="3" key={index}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className={report.icon} />
                  </span>
                </div>
                <h5 className="font-size-18 mb-0 title-color-common" style={{ fontFamily: "Gilroy", 
                  // color: "#db9c45" // css change
                   }}>{report.title}</h5>
              </div>
              <div className="text-muted mt-4">
                <h4 className="value-animation" style={{ fontFamily: "Gilroy", fontSize: "21px" }}>
                  {report.value}{" "}
                  <i className="mdi mdi-chevron-up ms-1 text-success" />
                </h4>
                <div className="d-flex gap-2">
                  <span className={`widge_Button ${activeButtons[index] === 0 ? "widge_Button_Active" : ""}`} onClick={() => handleClick("1M", index, 0)}>1M</span>
                  <span className={`widge_Button ${activeButtons[index] === 1 ? "widge_Button_Active" : ""}`} onClick={() => handleClick("6M", index, 1)}>6M</span>
                  <span className={`widge_Button ${activeButtons[index] === 2 ? "widge_Button_Active" : ""}`} onClick={() => handleClick("1Y", index, 2)}>1Y</span>
                  <span className={`widge_Button ${activeButtons[index] === 3 ? "widge_Button_Active" : ""}`} onClick={() => handleClick("ALL", index, 3)}>ALL</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
};

export default MiniWidget;