import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import { updateTheUserProfile } from 'Context/ContextApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const LivenessCheck = ({ nationalID, onComplete }) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const detectInterval = useRef(null);
  const [status, setStatus] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [instructions, setInstructions] = useState('Center your face in the circle and follow the instructions.');
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(1);
  const [finalImage, setFinalImage] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false); 

  useEffect(() => {
    checkCameraAvailability();
  }, [onComplete]);

  const checkCameraAvailability = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      if (videoDevices.length === 0) {
        onComplete();
      }
    } catch (error) {
      console.error('Error checking camera availability', error);
      onComplete();
    }
  };

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
        await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
        setModelsLoaded(true);
      } catch (error) {
        console.error('Error loading models', error);
      }
    };
    loadModels();
  }, []);

  const detect = async () => {
    if (
      modelsLoaded &&
      webcamRef.current &&
      webcamRef.current.video.readyState === 4 &&
      canvasRef.current &&
      !verificationSent 
    ) {
      const video = webcamRef.current.video;
      const canvas = canvasRef.current;
      const displaySize = {
        width: video.width,
        height: video.height,
      };

      faceapi.matchDimensions(canvas, displaySize);
      const detections = await faceapi
        .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks();

      if (detections.length > 0) {
        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        faceapi.draw.drawDetections(canvas, resizedDetections);

        const face = detections[0].detection;
        const box = face.box;

        if (box && box.x !== null && box.y !== null && box.width !== null && box.height !== null) {
          const width = box.width;
          const height = box.height;
          const aspectRatio = width / height;

          if (aspectRatio >= 0.8 && aspectRatio <= 1.2) {
            setInstructions('Face detected! Please rotate your head and smile.');
            setProgress((prev) => Math.min(prev + 10, 100));

            if (progress >= 100) {
              if (step === 1) {
                setInstructions('Step 2: Hold your national ID in your hand and center it in the camera.');
                setStep(2);
                setProgress(0); // Reset progress for the next step
              } else if (step === 2) {
                setInstructions('Liveness check completed!');
                // Stop detection before verification
                clearInterval(detectInterval.current);
                setTimeout(() => {
                  handleVerification();
                }, 3000);
              }
            }
          } else {
            setInstructions('Please rotate your head to align with the center.');
          }
        } else {
          console.error('Invalid bounding box:', box);
          setInstructions('Face detected but bounding box is invalid. Please adjust your position.');
        }
      } else {
        setInstructions('No face detected. Please make sure your face is in the frame.');
      }
    }
  };

  useEffect(() => {
    detectInterval.current = setInterval(detect, 100);
    return () => clearInterval(detectInterval.current);
  }, [modelsLoaded, progress, step]);

  const handleVerification = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setIsVerifying(true);
      setVerificationSent(true); // Mark verification as sent
      try {
        const byteString = atob(imageSrc.split(',')[1]);
        const mimeString = imageSrc.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        const file = new File([blob], 'captured-image.jpg', { type: mimeString });
        console.log(imageSrc, "imageSrc liveness");

        const formData = new FormData();
        formData.append("ProfileImage", file);

        const response = await updateTheUserProfile(formData);
        toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });

        if (response) {
          setFinalImage(imageSrc);
          setStatus('Verification successful!');
          if (onComplete) onComplete();
        } else {
          setStatus('Verification failed. Please try again.');
          // Restart detection on failure
          detectInterval.current = setInterval(detect, 100);
        }
        window.location.reload();
      } catch (error) {
        console.error('Error during verification', error);
        setStatus('Error during verification. Please try again.');
        // Restart detection on error
        detectInterval.current = setInterval(detect, 100);
      } finally {
        setIsVerifying(false);
      }
    }
  };

  return (
    <div className="liveness-container">
      <div className="webcam-wrapper">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height="100%"
        />
        <canvas ref={canvasRef} className="overlay-canvas" />
        <div className="progress-container">
          <svg className="progress-circle" viewBox="0 0 100 100">
            <circle
              className="progress-circle-background"
              cx="50"
              cy="50"
              r="45"
            />
            <circle
              className="progress-circle-bar"
              cx="50"
              cy="50"
              r="45"
              strokeDasharray="282.6" // 2 * Math.PI * radius (45)
              strokeDashoffset={(282.6 * (100 - progress)) / 100}
            />
          </svg>
        </div>
      </div>
      <p>{instructions}</p>
      <button onClick={handleVerification} disabled={isVerifying || step !== 2}>
        {isVerifying ? 'Verifying...' : 'Finish Verification'}
      </button>
      {status && <p>{status}</p>}
      {finalImage && (
        <div className="final-image-container">
          <h3>Final Image</h3>
          <img src={finalImage} alt="Captured" className="final-image" />
        </div>
      )}
    </div>
  );
};

export default LivenessCheck;
