import axios from "axios";
import Cookies from "js-cookie";
import { generateSHA256Hash } from "./GenerateHash";
import { encryptAESKeyWithPublicKey, encryptData, encryptDataAES, encryptDataPublicKey } from "components/Common/encrypt";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const authUser = Cookies.get("authUsers")
const authUserData = authUser ? JSON.parse(authUser) : '';
const authToken = authUserData?.token ? authUserData?.token : '';
const publicKey = localStorage.getItem('PublicKey')

const secretKey = process.env.REACT_APP_API_KEY;

// ==================== ==================== All Api Function ==================== =================== //
export const checkAuthentication = async (token) => {
  return token ? Promise.resolve() : Promise.reject("Authentication failed");
};

const twoStepEncryptionApiRequest = async (url, method, formData, value = false) => {
  try {
    const token = JSON.parse(localStorage.getItem("authUsers"));
    await checkAuthentication(token);
    const secureHash = generateSHA256Hash(formData);
    let data = { ...formData, secureHash: secureHash };
    const { encryptedData, aesKey, iv } = await encryptDataAES(JSON.stringify(data));
    const encryptedAESKey = await encryptDataPublicKey(publicKey, aesKey);
    const cryptData = {
      data: encryptedData,
      aesKey: encryptedAESKey,
      iv: iv,
      aesKeys: aesKey
    };
    const result = value ? cryptData : formData;
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: result,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


const makeApiRequest = async (url, method, formData, value = false) => {

  try {
    const token = JSON.parse(localStorage.getItem("authUsers"));
    await checkAuthentication(authToken);

    const secureHash = generateSHA256Hash(formData);
    let data = { ...formData, secureHash: secureHash };
    const encryptedData = encryptData(JSON.stringify(data));
    const cryptData = { data: encryptedData };
    const result = value ? cryptData : formData;

    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: result,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

const makeNonAuthApiRequest = async (url, method, formData, value = false) => {
  try {
    const secureHash = generateSHA256Hash(formData);
    let data = { ...formData, secureHash: secureHash };
    const encryptedData = encryptData(JSON.stringify(data));
    const cryptData = { data: encryptedData };
    const result = value ? cryptData : formData;
    const response = await axios({
      method,
      url: `${API_BASE_URL}${url}`,
      data: result,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const fetchUserProfile = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantProfileData", "get");
};

// merchant profile data
export const fetchMerchantProfile = async () => {
  return makeApiRequest("/api/v2/get/getvault/gMerchantProfile", "get");
}

export const updateprofileData = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateprofileData", "put", formData);
}

// get all merchant payment
export const fetchmerchantPayment = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantPaymentTransactions", "post", formData, true)
}

export const fetchmerchantRefundPaynmentDetails = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantRefundPaymentDetails", "post", formData, true)
}

// ------------- withdrawal -----------------
// add bank account
export const addbankAccount = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/addMerchantBankDetails", "post", formData, true)
}

// get merchant bank details
export const getMerchantBankDetailsapi = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantsAllBankDetails", "get")
}

// delete merchant bank details 
export const deleteMerchantbankDetails = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteMerchantbankDetails", "delete", formData, true)
}

export const postWalletToBankTransfer = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantBankWithdrawal", "post", formData, true)
}

// get merchant bank names
export const getMerchantBank_names = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantBanks", "get")
}

// get all merchant bank details 
export const getMerchantBankDetailsForSelect = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantBankDetailsForInputSelect", "get",)
}

// get bank details using name
export const getMerchantBankDetailsUsingNameapi = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantssBankDetailsUsingBankName", "post", formData, true)
}

// deposit money
export const postBankToWallet = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/addmerchanttopupAmount", "post", formData, true)
}

// send mooney to user wallet
export const sendMoneyToUserWallet = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantSendMoney", "post", formData, true)
}

// Request money 
export const requestMoneyFromMerchantToUser = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/requestMoneyMerchantToUser", "post", formData, true)
}

// get All Merchant transaction history
export const getAllTransactionHistory = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getAllMerchantTransactionDetails", "post", formData, true)
}

// update the merchant profile
export const updateTheUserProfile = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateMerchantprofileImage", "put", formData)
}

// check merchant pin
export const fetchCheckmerchantSecreatePIn = async () => {
  return makeApiRequest("/api/v2/get/getvault/checkScreatePinSetOrNotForMerchant", "get")
}
// send otp by twilio 
export const generateotpForTheMerchantSecreatPin = async () => {
  return makeApiRequest("/api/v2/get/getvault/generateotpForTheMerchantSecreatPin", "get")
}
// create and set secrat pin

export const setAndUpdateThesecreatePin = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/createandSetscreatePinForMerchant", "post", formData, true)
}

// currency convert
export const currencyConvert = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/currencyConversion", "post", formData)
}

// update and change password
export const updateAndChangeThePassword = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/updateAndChangeMerchantLoginPassword", "put", formData, true)
}

// Reset merchant pin
export const ResetMerchantPin = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/resetMerchantTransactionPin", "put", formData, true)
}

// get deposit money history
export const getAllDeposithistory = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getdepositMoneyTransaction", "post", formData, true)
}

// update merchant logo
export const updateTheLogoOfMerchant = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateMerchantLogo", "put", formData)
}

// get logo of metrchant
export const fetchLogoOfmerchant = async () => {
  return makeApiRequest("/api/v2/get/getvault/merchantLogo", "get")
}

// lock the screen 
export const dashboardLockApi = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantVaultScreenLock", "put", formData, true)
}

// check lock screen status 
export const fetchForTheLockScreen = async () => {
  return makeApiRequest("/api/v2/get/getvault/merchantVaultLockAndUnlockCheck", "get")
}

//locl and password check
export const postApiForUnLockScreenAndPassword = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantVaultLockAndPasswordCompare", "post", formData, true)
}

// read notification
export const ReadNotificationStatus = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/readMerchantNotification", "put", formData, true)
}

// deposit money
export const checkTaxCalclations = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantTaxCalaculationWhileEnteringTheAmount", "post", formData, true)
}

// register Status Check
export const merchantVaulRegisterStatusCheck = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/merchantVaulRegisterStatus", "get", formData)
}

// update register form
export const updateMerchantRegisterForm = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateRegisterForm", "post", formData)
}

// set default currency
export const setDefaultCurrency = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/setMerchantDefaultCurrency", "post", formData, true)
}

export const refundAmount = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/refundAmount", "post", formData, true)
}

// dashboard
export const getDashboardWidget = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getDashboardWidget", "post", formData, true)
}

export const gTotalTranscationPerDay = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/getTotalTranscationPerDay", "get")
}

export const gTotalInsights = async () => {
  return makeApiRequest("/api/v2/get/getvault/getTotalInsights", "get")
}

export const gNoOfTransaction = async () => {
  return makeApiRequest("/api/v2/get/getvault/getNoOfTransaction", "get")
}

// Refund Tax calculation
export const refundTaxCalclations = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getTaxCalaculationForRefund", "post", formData, true)
}

// add and create merchant role`
export const AddAndCreatemerchantRoles = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantRoles", "post", formData, true)
}

// get Merchant Roles
export const fMerchantRoles = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantRoles", "get")
}

// merchant role delete
export const merchantRoleDelete = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteMerchantRoles", "delete", formData, true)
}

// fetch edit data
export const fMerchantRoleEditData = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantEditRole", "post", formData, true)
}

// get Roles For Select box
export const fRolesForSelectBox = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantRolesForSelectBox", "get",)
}

// get Roles For Select box
export const merchantPermissionsForSubAdmin = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/merchantPermission", "post", formData)
}


export const fMerchantPermission = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantPermission", "get",)
}

// get permission for Edit
export const getPermissionForEdit = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantEditPermission", "post", formData, true)
}

// delete permission
export const deletePermission = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteMerchantPermission", "delete", formData, true)
}

// add And update Offers
export const updateAndAddMerchantOffers = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantOffersAddAndUpdate", "post", formData)
}

export const gMerchantType = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantType", "get")
}

// get Merchant offers
export const gMerchantOffers = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantOffers", "post", formData, true)
}

// get Merchant offers for Edit
export const gMerchantOffersForEdit = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantOffersForEdit", "post", formData, true)
}

// Update offers
export const updateTheCampaignStatus = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/updateCampaignStatus", "put", formData, true)
}

// delete Offers
export const deleteOffers = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteCampaign", "delete", formData, true)
}


export const gMerchantOfferRedeemed = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantRedeemedHistory", "post", formData, true)
}

// get merchant privacy and polict

// export const gMerchantPolicy = async (formData) =>{
//   return makeNonAuthApiRequest("/api/v2/get/getvault/getMerchantPolicy","post", formData)
// }

export const gMerchantPolicy = async (formData) => {
  return makeNonAuthApiRequest("/api/v2/get/getvault/getlegal", "post", formData, true)
}

// merchant pdf
export const sendEmailFromMerchant = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/merchantSendPdf", "post", formData)
}

// reset merchant subAdmin password
export const resetMerchantSubAdminPassword = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/resetSubAdminPasswords", "post", formData, true)
}

export const updateSubAdminProfileData = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/updateSubProfileData", "put", formData);
}

// Sub Admin update and change password
export const updateAndChangeSubAdminPassword = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/updateAndChangeSubAdminLoginPassword", "put", formData, true)
}


export const gMerchantActiveLog = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantActiveLog", "post",)
}

// get statement
export const getStatementHistory = async () => {
  return makeApiRequest("/api/v2/get/getvault/getMerchantStatementHistory", "get",)
}

// merchant statement money 
export const handleMerchantSransactionStamentPay = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/merchantSransactionStamentPay", "post", formData, true)
}

// add & update get way
export const addAndUpdatePaynowGetways = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/addAndUpdateMGetWays", "post", formData, true)
}

// fetch get ways 
export const fetchGetWaysData = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/fetchGetWays", "post", formData, true)
}

// paynow deposit
export const paynowDeposit = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/gateway/merchantPaynowDeposit", "post", formData, true)
}

//fetch proxy url
export const fetchProxuUrl = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/getProxyUrl", "post", formData)
}

// CBZ initaite transaction
export const initiateCbzTransaction = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/gateway/MerchantInitiateCbzTransaction", "post", formData, true)
}

// EFT initaite transaction
export const initiateEFTCopyAndPayTransaction = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/gateway/merchantEFTCopyAndPay", "post", formData, true)
}

// re-verify document
export const reVerifyMerchantDocuments = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/reVerifyMerchantDocuments", "put", formData)
}

// CABS COPY AND PAY
export const initiateCABSCopyAndPayTransaction = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/gateway/merchantCABSCopyAndPay", "post", formData, true)
}

// fetch dynmic deposit method
export const getDynmicDepositMethods = async () => {
  return makeApiRequest("/api/v2/get/getvault/fetchMerchantDynmicDepositMethods", "get",)
}

// get gateways types
export const fetchDynmicDepositType = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantGatewaysTypes", "post", formData, true)
}

// get gateways types
export const verifyTheNationalWithNumber = async (formData) => {
  return makeApiRequest("/api/v2/get/getvault/merchantDocVerify", "post", formData)
}

export const merchantFaceApiDE = async (formData) => {
  return makeApiRequest("/api/v2/get/faceAPI/merchantFaceApiDE", "post", formData)
}

// save Step Index
export const saveStepIndex = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/saveStepIndix", "post", formData, true)
}

// create  & update merchant themes

export const createAndUpdateMerchantTheme = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/createAndUpdateMerchantTheme", "post", formData, true)
}

// Get themes
export const getThemesList = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantThemes", "post", formData, true)
}

// UPDATE themes
export const updateThemeStatus = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/updateThemeStatus", "post", formData, true)
}

// delete permission
export const deleteTheme = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteMerchantThemes", "delete", formData, true)
}

// Request money through scan pay
export const requestMoneyThroughScanPay = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/requestAmountThroughScanPay", "post", formData, true)
}

// Request money through scan pay
export const cancelPaymentRequest = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/cancelPaymentRequest", "delete", formData, true)
}

// upodate sesssion payment request
export const updateSessionPaymentReuest = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/updatePaymentReuestSession", "put", formData, true)
}

// GET admin campaigns & merchant Offers
export const getAdminCampaignsAndMerchantOffers = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantOffersAndAdminCampaings", "post", formData, true)
}

// GET admin campaigns & merchant Offers
export const calaculateTaxScanPay = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantScanPayTaxCalaculation", "post", formData, true)
}

// GET admin campaigns & merchant Offers
export const calaculateDayanmicTaxScanPay = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantScanPayDayanmicTaxCalaculation", "post", formData, true)
}

// Get Scan Pay Order table
export const fetchScanPayOrderTable = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getMerchantScanPayOrderTable", "post", formData, true)
}

// Edit Request money 
export const editRequestMoney = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/getScanPayRequestusngId", "post", formData, true)
}

// start scan pay request 
export const startPaymentRequest = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/startScanPaymentRequest", "post", formData, true)
}

// f QR CODE LIST 
export const fQrCodeList = async () => {
  return makeApiRequest("/api/v2/get/getvault/getQrCodes", "get")
}

// Create qr code sub admin
export const createQrCodeAndCounterForSubAdmin = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/createQrAndCounterForsubAdmin", "post", formData, true)
}

// Delete m Counter QR code
export const deleteMCounterQRCode = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/deleteMerchantCounterQRCode", "delete", formData, true)
}

// Create qr code sub admin
export const requestPaymentForExternal = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/requestAmountThroughScanPayForExternal", "post", formData, true)
}

// send qr code to user
export const sendQrToUserForPayment = async (formData) => {
  return twoStepEncryptionApiRequest("/api/v2/get/getvault/sendScanPayRequestToUser", "post", formData, true)
}


// remove vpa assign
export const removeAssigednVpa = async () => {
  return makeApiRequest("/api/v2/get/getvault/removedSubAdminAssigednVpa", "get")
}
