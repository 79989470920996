import React, { useEffect } from 'react';

const Secure3DPage = ({ htmlContent }) => {
    useEffect(() => {
        const redirectInSameTab = (htmlContent) => {
            if (htmlContent) {
                const modifiedHtml = htmlContent.split("</title>")[0] +
                    `</title><base href="https://portal.host.iveri.com" />` +
                    htmlContent.split("</title>")[1];

                const blob = new Blob([modifiedHtml], { type: 'text/html' });
                const url = URL.createObjectURL(blob);
                window.location.href = url;
            } else {
                console.error('htmlContent is undefined or null.');
            }
        };

        redirectInSameTab(htmlContent);
    }, [htmlContent]);

    return null;
};

export default Secure3DPage;
