
import { createAndUpdateMerchantTheme, merchantPermissionsForSubAdmin } from "Context/ContextApi";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Row } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import MerchantColorTheme from "./Themes.Setting";
import { useData } from "Context/ContextProvider";

const dataThme = {
    "vertical-menu": {
        "background": "linear-gradient(to right, #000000, #000033)"
    },
    "navbar-brand-box": {
        "background": "linear-gradient(to right, #000000, #000033)"
    },
    "menu-title": {
        "color": "#427bb1"
    },

    "side-menu-item-color ": {
        "background" : "#427bb1" 
    },
    "side-menu-item-color": {
        "ul": {
            "li": {
                "a": {
                    "color": "#545a6d",
                    "i": {
                        "color": "#545a6d"
                    },
                    "&:hover": {
                        "color": "#ffff",
                        "i": {
                            "color": "#ffff"
                        }
                    }
                },
                "ul.sub-menu": {
                    "li": {
                        "a": {
                            "color": "#545a6d",
                            "&:hover": {
                                "color": "#ffff"
                            }
                        }
                    }
                }
            }
        }
    },
    "title-color-common": {
        "color": "#ff0000"
    },
    "description-color": {
        "color": "#2a4497"
    },
    "procces_Button": {
        "background-color": "#ff0000",
        "&:hover": {
            "background-color": "#bd1b1bb0"
        }
    },
    "pagination": {
        "li.active a": {
            "background": "#ff0000 !important",
            "border-color": "#ff0000 !important"
        }
    }
}


const ThemeForm = ({ data }) => {
    const history = useHistory();
    const {fetchUserProfile} = useData();
    const [validationErrors, setValidationErrors] = useState({});
    const [theme, setTheme] = useState();
    const [isForm, setIsForm] = useState({
        Name: "",
        ApplicableOn: "",
        Status: "",
        Themes: "",
        ThemeJSON: "",
    });
    useEffect(() => {
        if (data) {
            setIsForm({ ...data, ApplicableOn: data?.ApplicableOn && data?.ApplicableOn.slice(0, 16), ThemeJSON: data?.ThemeJSON && JSON.parse(data?.ThemeJSON) })
        } else {
            setIsForm({ ...isForm, ThemeJSON: dataThme })
        }
    }, [data]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setIsForm({ ...isForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }


    const validateForm = () => {
        let errors = {};
        if (!isForm.Name) {
            errors.Name = "Name is required";
        }
        if (!isForm.ApplicableOn) {
            errors.ApplicableOn = "Applicable on is required";
        }
        if (!isForm.Status) {
            errors.Status = "Status is required";
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async () => {
        try {
            const isValid = validateForm();
            if (isValid) {
                const data = {
                    ...isForm,
                    Themes: theme
                }
                console.log(data);
                const response = await createAndUpdateMerchantTheme(data);
                fetchUserProfile();
                history.push("/themes-list")
                toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChaneTheme = (item) => {
        setTheme(item)
    }

    return (
        <React.Fragment>
            <div className="mb-3 row">
                <label htmlFor="example-text-input" className="col-md-3 col-form-label fomr_in_offer_label"> Name </label>
                <div className="col-md-9">
                    <input
                        className="form-control form_in_offer_input_box"
                        type="text"
                        placeholder="Enter Name"
                        name="Name"
                        value={isForm.Name}
                        onChange={handleChanges}
                        required
                    />
                    {/* {validationErrors.UserName && <span className="Onbording-error-message">{validationErrors.UserName}</span>} */}
                </div>
            </div>
            <div className="mb-3 row">
                <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                >
                    Applicable On
                </label>
                <div className="col-md-9">
                    <input
                        className="form-control form_in_offer_input_box"
                        type="datetime-local"
                        placeholder="Enter Date"
                        name="ApplicableOn"
                        value={isForm.ApplicableOn}
                        onChange={handleChanges}
                        required
                    />
                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                </div>
            </div>
            <div className="mb-3 row">
                <label
                    htmlFor="example-text-input"
                    className="col-md-3 col-form-label fomr_in_offer_label"
                >
                    Status
                </label>
                <div className="col-md-9">
                    <select
                        className="form-select form_in_offer_input_box"
                        name="Status"
                        value={isForm.Status}
                        onChange={handleChanges}
                        required
                    >
                        <option value="" defaultValue hidden>
                            Select
                        </option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                    </select>
                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="example-text-input" className="col-md-3 col-form-label fomr_in_offer_label" >
                    Themes
                </label>
                <div className="col-md-9">
                    <MerchantColorTheme data={isForm.ThemeJSON} onChangeTheme={handleChaneTheme} />
                </div>
            </div>
            <Row className="mt-5 mb-5">
                <Col lg="9">
                    <div style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "40%",
                    }}
                    >
                        <Link to="/themes-list">
                            <Button className="procces_Button">Cancel</Button>
                        </Link>
                        <Button
                            className="procces_Button"
                            onClick={handleFormSubmit}
                        >
                            {data?.id ? "Update" : "Create"}
                        </Button>
                    </div>
                </Col>

            </Row>
        </React.Fragment>
    );
};

export default ThemeForm;
