import Fingerprint2 from 'fingerprintjs2';

export const GenerateDeviceId = () => {
    const storedDeviceId = localStorage.getItem('deviceId');
    if (storedDeviceId) {
        return storedDeviceId
    } else {
        Fingerprint2.get((components) => {
            const values = components.map(component => component.value);
            const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
            return deviceId
            // localStorage.setItem('deviceId', deviceId);
        });
    }
};
