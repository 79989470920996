import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import wheelLogo from "../../../../assets/images/NewImage/Vault_Wheel.png"
import { cancelPaymentRequest, requestMoneyThroughScanPay, requestPaymentForExternal, startPaymentRequest } from "Context/ContextApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumbs from "components/Common/Breadcrumb";
import { ScanPayCampaigns } from "pages/ScanPay/scanPay.calcualtion";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const InitiateExternalScanPay = ({ data, iPAddress, mData, userDataa }) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [sendForm, setSendForm] = useState({
        Currency: "",
        Amount: "",
        Remarks: "",
        InvoiceId: "",
        OfferCode: "",
        CampaignsId: "",
        NetTotal: "",
        OfferPercentage: "",
        OfferAmount: "",
    });

    useEffect(() => {
        if (data?.Data && data?.Data?.Status === 'Completed') {
            setSendForm({
                Currency: "",
                Amount: "",
                Remarks: "",
                InvoiceId: "",
                Campaigns: "",
            })
            history.push(`/payment-details/${data?.Data?.TransactionId}`)
        }
        if (data?.Type === 'REQUEST') {
            setSendForm({ ...sendForm, Currency: data?.Data?.Currency })
        }
        if (data?.Data) {
            setSendForm({ ...sendForm, ...data?.Data })
            if (data?.Data?.Status === 'Requested') {
                setLoader(true)
                setIsDisabled(true)
            }
        }
    }, [data?.Data])

    const [validationErrors, setValidationErrors] = useState({});

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setSendForm((prevForm) => {
            let updatedForm = { ...prevForm, [name]: value };
            if (name === "OfferPercentage" && value !== "") {
                updatedForm.OfferAmount = null;
            } else if (name === "OfferAmount" && value !== "") {
                updatedForm.OfferPercentage = null;
            } else if (name === "CampaignsId" && value !== "") {
                updatedForm.OfferPercentage = null;
                updatedForm.OfferCode = null;
                updatedForm.OfferPercentage = null;
            }
            return updatedForm;
        });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };


    const validateForm = () => {
        let errors = {};

        if (!sendForm.Currency) {
            errors.Currency = "Please select a valid Currency.";;
        }
        if (!sendForm.Amount || isNaN(sendForm.Amount) || sendForm.Amount <= 0) {
            errors.Amount = "Please enter a valid amount greater than zero.";
        }
        if (!sendForm.InvoiceId) {
            errors.InvoiceId = "Please enter a valid Invoice Id.";
        }
        if (!sendForm.Remarks) {
            errors.Remarks = "Enter Transaction description";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleScanPay = async (item, status) => {
        try {
            const isValid = validateForm();
            if (!isValid) return;
            setLoader(true)
            const formData = {
                Amount: sendForm?.Amount || 0,
                Currency: sendForm?.Currency || 'USD',
                Remarks: sendForm?.Remarks,
                InvoiceId: sendForm?.InvoiceId,
                IPAddress: iPAddress,
                id: data?.Data?.id,
                UserId: data?.Data?.UserId,
                Type: item,
                OfferCode: sendForm?.OfferCode,
                CampaignsId: sendForm?.CampaignsId,
                OfferPercentage: sendForm?.OfferPercentage || 0,
                OfferAmount: sendForm?.OfferAmount || 0,
                Status: status
            };
            const response = await requestPaymentForExternal(formData)
            setIsDisabled(true)
            if (response.status === "success") {
                toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            } else if (response.status === 'failed') {
                toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right" });
                setIsDisabled(false)
            }
        } catch (error) {
            setLoader(false)
            setIsDisabled(false)
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }

    const handleCancel = async () => {
        try {
            const formData = {
                IPAddress: iPAddress,
                id: data?.Data?.id,
                UserId: data?.Data?.UserId,
            };
            const response = await cancelPaymentRequest(formData)
            history.push("/orders")
            if (response.status === "success") {
                toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            } else if (response.status === 'failed') {
                toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right" });
            }
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }

    document.title = "GET Platform  | Scan pay"
    return (
        <React.Fragment>
            <div className="page-content" >
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Scan pay" />
                    <Row className="justify-content-center mb-5">
                        <Col xl="6">
                            <Card className="p-4">
                                {data && Object.keys(data).length === 0 ? (
                                    <div className="d-flex justify-content-between aligh-center">
                                        <p>No Request Available</p>
                                    </div>
                                ) : (
                                    <>
                                        <hr />
                                        <h5 className="card-title">User Details</h5>
                                        <Row className="justify-content-center scan-pay-user-details">
                                            <Col xl="4">
                                                <div style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    position: "relative",
                                                }}
                                                >
                                                    <img
                                                        src={data?.Data?.ProfileImage ? `${API_BASE_URL}/${data?.Data?.ProfileImage}` : wheelLogo}
                                                        alt="profile"
                                                        className="avatar-md rounded-circle img-thumbnail fullwidth"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "fit",
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl="8">
                                                <p>Name : <span>{data?.Data?.FirstName || ""} {data?.Data?.LastName || ""}</span></p>
                                                <p>Mobile : <span>{data?.Data?.Mobile || ""}</span></p>
                                                <p>VPA : <span>{data?.Data?.VPA || ""}</span></p>
                                                <p>Date : <span>{new Date(data?.Data?.TimeStamp).toLocaleString() || ""}</span></p>
                                                <p>Status : <span className={` ${data?.Data?.Status === 'Completed' ? "status-completed" : 'status-pending'}`}>{data?.Data?.Status || ""}</span></p>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <div className="heading_container">
                                            <h5 className="card-title">User Request</h5>
                                            {/* {loader &&  */}
                                            <Button className="procces_Butto mt-3" onClick={() => setIsDisabled(!isDisabled)}>Edit</Button>
                                            {/* } */}
                                        </div>
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit">
                                                <Label for="currency">Currency</Label>
                                                <Input type="select" id="currency" name="Currency"
                                                    disabled={isDisabled}
                                                    required
                                                    value={sendForm.Currency} onChange={handleChanges}
                                                    invalid={!!validationErrors.Currency}                                    >
                                                    <option defaultValue hidden>Select currency</option>
                                                    <option value="USD">USD</option>
                                                    <option value="ZiG">ZiG</option>
                                                </Input>
                                                {validationErrors?.Currency && <div className="text-danger">{validationErrors?.Currency}</div>}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit">
                                                <Label for="amount" >Amount</Label>
                                                <Input
                                                    type="number"
                                                    id="amount"
                                                    name="Amount"
                                                    required
                                                    placeholder="Enter amount"
                                                    value={sendForm.Amount}
                                                    onChange={handleChanges}
                                                    invalid={!!validationErrors.Amount}
                                                    disabled={isDisabled}
                                                    minLength={0}
                                                />
                                                {validationErrors?.Amount && <div className="text-danger">{validationErrors.Amount}</div>}
                                            </div>
                                        </FormGroup>
                                        <ScanPayCampaigns sendForm={sendForm} userDataa={userDataa} handleChanges={handleChanges} setSendForm={setSendForm} />
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit">
                                                <Label for="InvoiceId" >Invoice id</Label>
                                                <Input
                                                    type="text"
                                                    id="InvoiceId"
                                                    name="InvoiceId"
                                                    required
                                                    placeholder="Enter Invoice id"
                                                    value={sendForm.InvoiceId}
                                                    onChange={handleChanges}
                                                    invalid={!!validationErrors.InvoiceId}
                                                    disabled={isDisabled}
                                                />
                                                {validationErrors?.InvoiceId && <div className="text-danger">{validationErrors?.InvoiceId}</div>}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit">
                                                <Label for="remarks">Remarks</Label>
                                                <Input
                                                    type="textarea"
                                                    id="remarks"
                                                    name="Remarks"
                                                    required
                                                    placeholder="Enter a note"
                                                    value={sendForm.Remarks}
                                                    onChange={handleChanges}
                                                    invalid={!!validationErrors.Remarks}
                                                    disabled={isDisabled}
                                                />
                                                {validationErrors?.Remarks && <div className="text-danger">{validationErrors?.Remarks}</div>}
                                            </div>
                                        </FormGroup>
                                        {(data?.Data?.Status === 'Initiate' || data?.Data?.Status === 'Requested') && (
                                            <Button className="procces_Button" disabled={loader} onClick={() => handleScanPay('REQUEST', 'Requested')}>
                                                {loader ? (<Spinner size="sm">Payment Requested Successfully</Spinner>
                                                ) : (<span>Request Payment</span>)}
                                            </Button>
                                        )}
                                        {(data?.Data?.Status === 'Initiate' || data?.Data?.Status === 'Requested') && (
                                            <Button className="procces_Button mt-3" onClick={() => handleCancel('UPDATED')}>Cancel Request</Button>
                                        )}
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
}
