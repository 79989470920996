import React from "react";
import { useData } from "Context/ContextProvider";

const Balance = () => {
  const { userDataa, balance } = useData();
  return (
      <div className="header-balance-span">
        <span>
          {balance?.Currency || userDataa?.Currency} {balance?.Balance || userDataa?.Balance}{" "}
        </span>
      </div>
  );
};

export default Balance;
