import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Row } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory } from "react-router-dom";
import { useData } from "Context/ContextProvider";
import logosrc from "../../../assets/images/NewImage/Invoice-Logo.png";
import { merchanInformation, validateCaptchaOtp } from "Auth/ApiFunction";
import Cookies from "js-cookie";
import CaptchaLoginFooter from "components/Common/captcha-login-otp";
import { GenerateDeviceId } from "components/Common/generateDeviceId";

const CaptchOTP = () => {
    document.title = "GET Platform | Captcha OTP";
    const history = useHistory();
    const { iPAddress, userInfo } = useData();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [deviceId, setDeviceId] = useState('')

    useEffect(() => {
        const deviceData = GenerateDeviceId();
        setDeviceId(deviceData);
    }, [!deviceId]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value === "" || /^[a-zA-Z0-9]$/.test(value)) {
            const newOtp = otp.slice();
            newOtp[index] = value;
            setOtp(newOtp);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
            if (newOtp.every(char => char !== "")) {
                handleSubmit(newOtp);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const newOtp = otp.slice();
            newOtp[index] = "";
            setOtp(newOtp);
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, 6);
        if (/^[a-zA-Z0-9]{1,6}$/.test(pasteData)) {
            const newOtp = [...pasteData.split("")];
            setOtp(newOtp);
            if (newOtp.every(char => char !== "")) {
                handleSubmit(newOtp);
            }
        }
        e.preventDefault();
    };


    const handleSubmit = async (otpValueArray) => {
        try {
            const otpValue = otpValueArray.join("");
            if (otpValue.length < 6) {
                toastr.error("Please enter the full OTP.");
                return;
            }
            const formData = {
                OTP: otpValue,
                IPAddress: iPAddress,
                Latitude: userInfo?.location?.lat,
                Longitude: userInfo?.location?.lon,
                DeviceId: deviceId,
            };
            const response = await validateCaptchaOtp(formData);
            if (response && response.token) {
                const expirationDate = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
                // const expirationDate = new Date(new Date().getTime() + 6 * 60 * 1000);
                const authUserData = {
                    token: response.token,
                    expires: expirationDate.toISOString(),
                    IPAddress: iPAddress,
                };
                Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
                localStorage.setItem("authUsers", JSON.stringify(response.token));
                localStorage.setItem("PublicKey", response?.PublicKey);
                let data = {
                    MerchantId: response?.result?.result?.id,
                    SubAdminId: response?.result?.subResult?.id,
                    Status: "ACTIVE",
                    LastInactiveDateTime: '',
                    DateTime: new Date(),
                    ...userInfo
                };
                await merchanInformation(data);
                if (response.result?.result?.Register_Status === "COMPLETED") {
                    if (response.result?.result?.kycVerified === "ReVerify") {
                        history.push("/re-verify");
                    } else {
                        history.push("/get-dashboard");
                    }
                } else {
                    history.push("/registration-form2");
                }
            }
        } catch (error) {
            console.log(error);
            toastr.error(error?.response?.data?.message || error?.message);
            setOtp(new Array(6).fill(""));
        }
    };

    return (
        <React.Fragment>
            <div className="registration-main-section">
                <div className="captcha_otp_logo mt-5"> <a href="login"> <img src={logosrc} alt="logo" /> </a> </div>
                <Row>
                    <div className="login-form-heading-text-center mt-3"> <h1> <b> Check your app for a code </b></h1></div>
                    <div className="otp-box mt-5 ">
                        <Form className="captcha-container">
                            <FormGroup className="d-flex justify-content-center captcha-otp-input" onPaste={handlePaste}>
                                {otp.map((data, index) => (
                                    <Input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={data}
                                        onChange={(e) => handleChange(e, index)}
                                        onFocus={(e) => e.target.select()}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        className="otp-field text-center"
                                    />
                                ))}
                            </FormGroup>
                        </Form>
                    </div>
                </Row>
            </div>
            <CaptchaLoginFooter />
        </React.Fragment>
    );
};

export default CaptchOTP;
