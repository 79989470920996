import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import { useState } from "react";
import { updateTheUserProfile, updateprofileData } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import { useData } from "Context/ContextProvider";

const API_BASE_URL = process.env.REACT_APP_API_URL;
 export const UpdateMerchantForm = ({mData}) => {
    const [userData, setUserData] = useState({});
    const [actionData, setActionData] = useState({})
 
    const [formDatass, setFormData] = useState({
        Title: "",
        FullName: "",
        Alias: "",
        Email: "",
        Mobile: "",
        NationalIDno: "",
        AddressProofNo: "",

        PersonalAddressLine1: "",
        PersonalAddressLine2: "",
        FlatNo: "",
        PersonalNearby: "",
        PersonalCity: "",
        PersonalProviance: "",
        PersonalCountry: "",
        PersonalZipcode: "",

        BusinessName: "",
        BusinessAddressLine1: "",
        BusinessAddressLine2: "",
        BusinessNearby: "",
        BusinessCity: "",
        BusinessProviance: "",
        BusinessCountry: "",
        BusinessZipcode: "",

    });

    const onChange = e => {
        setFormData({ ...formDatass, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        setUserData(mData?.result);
        setFormData({ ...formDatass, ...mData?.result });
        let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
        setActionData(jsonparse)
    }, [mData?.subResult?.Permissions])

    const handleUpdateTheData = async (type) => {
        try {
            const formData = {
                type: type,
                ...formDatass
            }
            const response = await updateprofileData(formData);
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            setUserData(response?.result);
            setFormData({ ...formDatass, ...response?.result });
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <React.Fragment>
                <Card className="p-3">
                    <h4 className="personal_info_use_profile mb-2 mt-3 ms-4 title-color-common">
                        Personal
                    </h4>
                    <CardBody>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="From_register_Gropu_for_input">
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="FullName"
                                            value={formDatass.FullName}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.FullName ? 'Active' : ''}>FullName</label>
                                    </div>
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="Alias"
                                            value={formDatass.Alias}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.Alias ? 'Active' : ''}>Alias</label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="Email"
                                            value={formDatass.Email}
                                            // onChange={onChange}
                                            disabled
                                        />
                                        <label className={formDatass.Email ? 'Active' : ''}>
                                            Email
                                        </label>
                                    </div>
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="Mobile"
                                            value={formDatass.Mobile}
                                            // onChange={onChange}
                                            disabled
                                        />
                                        <label className={formDatass.Mobile ? 'Active' : ''}>Phone Number</label>
                                    </div>
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="NationalIDno"
                                            value={formDatass.NationalIDno}
                                            // onChange={onChange}
                                            disabled
                                        />
                                        <label className={formDatass.NationalIDno ? 'Active' : ''}>
                                            National ID
                                        </label>
                                    </div>
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="AddressProofNo"
                                            value={formDatass.AddressProofNo}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.AddressProofNo ? 'Active' : ''}>
                                            Address Proof NO
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </CardBody>
                    {actionData === null || (actionData?.MProfile?.action === true) ? (
                        <Col md="3" className="ms-3">
                            <Button
                                style={{ fontSize: "18px" }}
                                className="procces_Button"
                                onClick={() => handleUpdateTheData('personal1')}
                            >
                                Update
                            </Button>
                        </Col>
                    ) : null}
                </Card>
                <Card className="p-3">
                    <h4 className="personal_info_use_profile mb-2 mt-3 ms-4 title-color-common">
                        Address
                    </h4>
                    <CardBody>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="From_register_Gropu_for_input">
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"

                                            name="PersonalAddressLine1"
                                            value={formDatass.PersonalAddressLine1}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.PersonalAddressLine1 ? 'Active' : ''}>
                                            Personal Address Line 1
                                        </label>
                                    </div>
                                    {/* <div className="Form_register_Input">
                        <input
                          type="text"

                          name="PersonalAddressLine2"
                          value={formDatass.PersonalAddressLine2}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalAddressLine2 ? 'Active' : ''}>
                          Personal Address Line 2
                        </label>
                      </div> */}
                                    {/* <div className="Form_register_Input">
                        <input
                          type="text"
                          name="FlatNo"
                          value={formDatass.FlatNo}
                          onChange={onChange}
                        />
                        <label className={formDatass.FlatNo ? 'Active' : ''}>
                          Flat No
                        </label>
                      </div> */}
                                    {/* <div className="Form_register_Input">
                        <input
                          type="text"
                          name="PersonalNearby"
                          value={formDatass?.PersonalNearby || ''}
                          onChange={onChange}
                        />
                        <label className={formDatass.PersonalNearby ? 'Active' : ''}>
                          Landmark / Nearby Area
                        </label>
                      </div> */}

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="PersonalCity"
                                            value={formDatass.PersonalCity}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.PersonalCity ? 'Active' : ''}>
                                            City
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="PersonalProviance"
                                            value={formDatass.PersonalProviance}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.PersonalProviance ? 'Active' : ''}>
                                            Proviance
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            name="PersonalZipcode"
                                            value={formDatass.PersonalZipcode}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.PersonalZipcode ? 'Active' : ''}>
                                            Zipcode
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="PersonalCountry"
                                            value={formDatass.PersonalCountry}
                                        // onChange={onChange}
                                        />
                                        <label className={formDatass.PersonalCountry ? 'Active' : ''}>
                                            Country
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    {actionData === null || (actionData?.MProfile?.action === true) ? (
                        <Col md="3" className="ms-3">
                            <Button
                                style={{ fontSize: "18px" }}
                                className="procces_Button"
                                onClick={() => handleUpdateTheData('personal')}
                            >
                                Update
                            </Button>
                        </Col>
                    ) : null}
                </Card>
                <Card className="p-3">
                    <h4 className="personal_info_use_profile mb-2 mt-3 ms-4 title-color-common">
                        Business
                    </h4>
                    <CardBody>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="From_register_Gropu_for_input">
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"

                                            name="BusinessName"
                                            value={formDatass.BusinessName}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessName ? 'Active' : ''}>
                                            Business Name / Company Name
                                        </label>
                                    </div>
                                    <div className="Form_register_Input">
                                        <input
                                            type="text"

                                            name="BusinessAddressLine1"
                                            value={formDatass.BusinessAddressLine1}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessAddressLine1 ? 'Active' : ''}>
                                            Business Address Line 1
                                        </label>
                                    </div>

                                    {/* <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessAddressLine2"
                          value={formDatass.BusinessAddressLine2}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessAddressLine2 ? 'Active' : ''}>
                          Business Address Line 2
                        </label>
                      </div> */}

                                    {/* <div className="Form_register_Input">
                        <input
                          type="text"
                          name="BusinessNearby"
                          value={formDatass.BusinessNearby}
                          onChange={onChange}
                        />
                        <label className={formDatass.BusinessNearby ? 'Active' : ''}>
                          BusinessNearby
                        </label>
                      </div> */}

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="BusinessCity"
                                            value={formDatass.BusinessCity}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessCity ? 'Active' : ''}>
                                            City
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="BusinessProviance"
                                            value={formDatass.BusinessProviance}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessProviance ? 'Active' : ''}>
                                            Proviance
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="BusinessZipcode"
                                            value={formDatass.BusinessZipcode}
                                            onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessZipcode ? 'Active' : ''}>
                                            Zipcode
                                        </label>
                                    </div>

                                    <div className="Form_register_Input">
                                        <input
                                            type="text"
                                            name="BusinessCountry"
                                            value={formDatass.BusinessCountry}
                                        // onChange={onChange}
                                        />
                                        <label className={formDatass.BusinessCountry ? 'Active' : ''}>
                                            Country
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    {actionData === null || (actionData?.MProfile?.action === true) ? (
                        <Col md="3" className="ms-3">
                            <Button
                                style={{ fontSize: "18px" }}
                                className="procces_Button"
                                onClick={() => handleUpdateTheData('business')}
                            >
                                Update
                            </Button>
                        </Col>
                    ) : null}
                </Card>
            </React.Fragment>
        </>
    );
}

