import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Container, Row, Col, CardBody, Card, Button, Label, } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// import images
import { postApiForUnLockScreenAndPassword } from "Context/ContextApi";
import { useHistory } from "react-router-dom";
import { useData } from "Context/ContextProvider";
import Merchanttlogo from "../../assets/images/NewImage/merchant_Portal_Logo.png";
import getLogo from "../../assets/images/NewImage/Vault_Wheel.png";
import LockScreenFooter from "components/Common/LockScreenFooter";
import { io } from "socket.io-client";

const API_BASE_URL = process.env.REACT_APP_API_URL
const LockScreen = () => {
  const { userDataa, userInfo } = useData();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  // meta title
  document.title = "GET Platform | Lock Screen";

  const handleLogout = async () => {
    let data = {
      MerchantId: userDataa.id,
      Status: "INACTIVE",
      LastInactiveDateTime: new Date(),
      DateTime: '',
      ...userInfo
    }
    const socket = io(API_BASE_URL);
    socket.emit("merchantInfo", JSON.stringify(data));
    localStorage.removeItem("paths")
    window.location.reload();
  }

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "#393838", minHeight: "100vh" }}>
        <div className="lock_Screen_logout">
          <div>{''}</div>
          <Link to="/logout" onClick={handleLogout}>
            <span>logout</span>
          </Link>
        </div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col md="8" lg="6" xl="5">
                <Card className="overflow-hidden">
                  <div style={{ backgroundColor: "black" }}>
                    <Row>
                      <Col xs="12">
                        <div className="heading_main_container">
                          <div className="text-primary text-center p-4 auth-lock-screen-heading" >
                            <h5 >{userDataa?.BusinessName}</h5>
                            <div className="between_line"></div>
                            <p>PORTAL</p>
                          </div>
                          <div className="heading_image_container">
                            <img src={Merchanttlogo} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-0">
                          <span
                            className="avatar-title rounded-circle bg-light"
                          >{userDataa?.MerchantLogo ? (
                            <img
                              src={`${API_BASE_URL}/${userDataa?.id}/profile/${userDataa?.MerchantLogo}`}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          ) : (
                            <img
                              src={getLogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          )}
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-0">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                        })}
                        onSubmit={async (values) => {
                          try {
                            const formData = {
                              Password_: values.password,
                              ScreenLock: "UNLOCK"
                            }
                            const response = await postApiForUnLockScreenAndPassword(formData);
                            toastr.success(response.message)
                            history.push("/get-dashboard");
                            if (response.status === 'failed') {
                              toastr.warning(response.message)
                            }
                          } catch (error) {
                            console.error("API error", error);
                            if (error.response.data.message) {
                              toastr.warning(error.response.data.message)
                            }
                          }
                        }}
                      >
                        {({ errors, touched }) => (
                          <Form className="form-horizontal">
                            <div className="user-thumb text-center mb-1">
                              <img
                                src={`${API_BASE_URL}/${userDataa?.id}/profile/${userDataa?.ProfileImage}`}
                                className="rounded-circle img-thumbnail avatar-md"
                                alt="thumbnail"
                              />
                              <h5 className="font-size-18 mt-3">{userDataa?.FullName}</h5>
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  autoComplete="true"
                                  className="form-control form_control_login"
                                // className={
                                //     "form-control form_control_login" +
                                //     (errors.password && touched.password
                                //     ? " is-invalid"
                                //     : "")
                                // }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "mdi mdi-eye-off-outline"
                                        : "mdi mdi-eye-outline"
                                    }
                                  ></i>
                                </button>
                              </div>

                              {/* <Field
                                name="password"
                                placeholder="Enter Password"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              /> */}
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="text-end">
                              <Col xs="12" className="text-end">
                                <Button
                                  className="procces_Button  w-md"
                                  type="submit"
                                >
                                  Unlock
                                </Button>
                              </Col>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <LockScreenFooter />
      </div>
    </React.Fragment>
  );
};

export default LockScreen;
