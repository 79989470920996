import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import { gMerchantOffers, updateTheCampaignStatus } from "Context/ContextApi";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import Toggle from "react-toggle";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const campaigns = () => {
  const [productData, setProductData] = useState([]);
  const [status, setStatus] = useState("")
  const effact = "effact"
  useEffect(() => { handleFetchMerchantOffer() }, [effact])

  const handleFetchMerchantOffer = async (Status) => {
    try {
      const formData = { Status: Status }
      const response = await gMerchantOffers(formData)
      setProductData(response.result)
    } catch (error) {
      console.log(error);
    }
  }

  const handleToggle = async (id, isChecked) => {
    try {
      const formData = {
        id: id,
        Active: `${isChecked ? "ENABLE" : "DISABLE"}`
      }
      const response = await updateTheCampaignStatus(formData)

      toastr.success(response.message)
    } catch (error) {
      console.log(error);
    }
  };
  
  const columns = [
    {
      dataField: "OffersName",
      text: "Offer name",
      sort: true,
    },
    {
      dataField: "Type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "Method",
      text: "Method",
      sort: true,
    },
    {
      dataField: "DiscountPercentage",
      text: "%",
      sort: true,
    },
    {
      dataField: "StartDate",
      text: "Start date",
      sort: true,
      formatter: (cell, row) => (
        <div>{new Date(cell).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        })}</div>
      ),
    },
    {
      dataField: "EndDate",
      text: "End date",
      sort: true,
      formatter: (cell, row) => (
        <div>{new Date(cell).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        })}</div>
      ),
    },
    {
      dataField: "MaxTotalUsage",
      text: "Total usage",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Valid",
      sort: true,
      formatter: (cell, row) => (
        <span style={{ color: row.Status === "ACTIVE" ? "green" : "red", fontFamily: "Gilroy", fontWeight: "600", fontSize: "18px" }}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "Active",
      text: "ON/OFF",
      sort: true,
      formatter: (cell, row) => (
        <div className="campaign_check_box_Active_InActive">
          <Toggle
            aria-labelledby='biscuit-label'
            id={`toggle-${row.id}`}
            defaultChecked={row.Active === "ENABLE"}
            onChange={(e) => handleToggle(row.id, e.target.checked)}
          />
        </div>
      ),
    },
    {
      dataField: "Action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => (
        <div className="d-flex gap-2">
          <Link to={`/create-offer/${row?.id}`}>
            <p className="Details_popup">
              <img src={arrowicon} alt="icon" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length,
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;
  document.title = "GET Platform | Campaigns - create & manage";
  
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="Campaigns - Create & Manage" />
          <Row>
            <Col md={{ offset: 3, size: 6 }}>
              <h1 className="text-center all_newPaeHeading">CAMPAIGNS - CREATE & MANAGE</h1>
              <h5 className="text-center all_sub_new_heading">
                List of all payments you received from customers
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Link to="/create-offer">
                <Button
                  className="procces_Button"
                  style={{
                    fontFamily: "Gilroy",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  Create offer
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <h5 className="mb-3 sub_heading_left_side_the_page">
                Campaigns
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0px",
                  width: "100%",
                }}
              >
                <div style={{ width: "80%", display: "flex", gap: "10px" }}>
                  <select
                    className="filtter_select_box"
                    value={status}
                    // onChange={(e)=>setStatus(e.target.value)}
                    onChange={(e) => {
                      const selectedStatus = e.target.value;
                      setStatus(selectedStatus);
                      handleFetchMerchantOffer(selectedStatus);
                    }}
                  >
                    <option selected hidden>
                      All status
                    </option>
                    <option value="">All</option>
                    <option value="ACTIVE">Active</option>
                    <option value="EXPIRED"> Expired</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "20px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Gilroy",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Reset
                  </span>
                  <Button
                    type="submit"
                    className="procces_Button"
                    style={{
                      fontFamily: "Gilroy",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Apply filter
                  </Button>
                </div>
              </div>
              <Card className="mt-5">
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {/* <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    style={{
                                      fontWeight: "Gilroy",
                                    }}
                                    headerClasses={"custom-header-class"}
                                    rowStyle={{
                                      fontSize: "15px",
                                      fontFamily: "Gilroy",
                                      fontWeight: "400"
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default campaigns;
