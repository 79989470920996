import React from "react";
import { Button } from "reactstrap";

const FinalStep = ({ userDataa }) => {
    console.log(userDataa);
    return (
        <>
            {userDataa?.kycFaceRetry > 3 ? (
                <div className="success-page">
                    <h2>KYC Process Pending</h2>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYBV9ZgWBPEaNlmOOrmOYq-xvkjpQeyOYmfA&s" alt="pending" />
                    <p>KYC Processed with Manual Verification, Subjected to GETransfer's Team, Please check your email for more details</p>
                </div>
            ) : (
                <div className="success-page">
                    <h2>KYC Process Completed Successfully!</h2>
                    <img src="https://cdn-icons-png.flaticon.com/128/5709/5709755.png" alt="Success" />
                    <p>Your KYC process has been completed successfully. You can now proceed to the dashboard.</p>
                    <Button color="primary" onClick={() => window.location.href = '/get-dashboard'}>
                        Go to Dashboard
                    </Button>
                </div>
            )}
        </>
    )
}

export default FinalStep;