import { CURRENCY } from "components/Common/Currency";
import React from "react";
import { Button, Table } from "reactstrap";

const Step2 = ({
  sendForm,
  handleProceed,
  handlePopup,
  netTotal,
  taxes,
  handleResetForm,
  id,
  userDataa
}) => {
  console.log(taxes);
  return (
    <div>
      <div className="deposit_money_secondStep_form">
        <div className="deposit_money_secondStep_form mt-3">
          <div className="deposite_money_second_step_text  ">
            <span>{(taxes[0]?.Title)} </span>
            <span>{userDataa.Currency} {parseFloat(taxes[0]?.Value)}</span>
          </div>
          <div className="deposite_money_second_step_text  ">
            <span>Tax </span>
          </div>
          {taxes[1]?.Tax?.map((item, index) => (
            <div className="transaction_details ps-4" key={index}>
              <div className="transaction_detailsForTaxDetals">
                <div className="transaction_detail_Title_name">
                  <label>{item.Particular}</label>
                  {item.Percentage && (
                    <small>{item.Percentage} %</small>
                  )}
                </div>
                <div className="transaction_detail_Amount">
                  <span>{userDataa.Currency} {parseFloat(item.NetTotal).toFixed(2)}</span>
                </div>
              </div>
            </div>
          ))}
          <div className="transaction_details deposite_money_second_step_text_border_line ">
            <span>Net Tax </span>
            <span>{userDataa.Currency} {parseFloat(taxes[2]?.Value)}</span>
          </div>
          <div className="transaction_details deposite_money_second_step_text_border_line">
            <span>Net Total </span>
            <span>{userDataa.Currency} {parseFloat(taxes[3]?.Value)}</span>
          </div>
        </div>
        <Button className="procces_Button mt-3" onClick={() => { handlePopup() }}>Confirm & Deposit</Button>
        {id && (
          <Button className="procces_Button mt-3" onClick={() => { handlePopup() }}> Retry Deposit</Button>
        )}
        <span className="text-center" onClick={() => handleProceed(1)} > Back</span>
      </div>
    </div>
  );
};


export default Step2;
