import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const token = JSON.parse(localStorage.getItem("authUsers"));
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  const token = JSON.parse(localStorage.getItem("authUsers"));
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
  return axios
    .post(url, data, {headers})
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw err.message
    })
}

// Login Method
const postFakeLogin = data => post(`${API_BASE_URL}/api/v2/get/webmerchant/loginadmin`, data)
//const postFakeLogin = data => post("http://62.138.3.174:4900/api/v2/get/webmerchant/loginadmin", data)


// postForgetPwd valid
// const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)
const postFakeForgetPwd = (data) => {
  return post(url.POST_FAKE_PASSWORD_FORGET, data)
    .then((response) => {
      toastr.success(response.message);
      return response.message;
    })
    .catch((err) => {
      toastr.error(err?.response?.data?.message || err?.message);
      handleAxiosError(err);
    });
};


// postForgetPwd valid
const getProfileData = data => get(url.USER_PROFILE_DATA, data)
// Edit profile


// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.message >= 200 || response.message <= 299) return response.result
      throw response.result
    })
    .catch(err => {
      var message
      toastr.warning(err.message)
      if (err.response && err.response.message) {
        switch (err.response.message) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw err.message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  getProfileData
}
