
import React, { useEffect, useState } from "react";
import {Button, Card, CardBody, Col, Row,} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import { checkTaxCalclations, getStatementHistory } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search,} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import StatementAmount from "./statementPay";
import downloads from "../../assets/images/NewImage/download.png"
import { useData } from "Context/ContextProvider";

const CURRENCY = process.env.CURRENCY;
const API_BASE_URL = process.env.REACT_APP_API_URL;

const Statement = () => {
    const {mData, userDataa} = useData();
    const [datas, setData] = useState([])
    const [months, setMonths] = useState("")
    const [tags, setTags] = useState([])
    const [taxes, setTaxes] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [amount, setAmount] = useState()

    useEffect(() => {
        fetchStatement()
    }, [userDataa])

    const fetchStatement = async () => {
        try {
            const response = await getStatementHistory()
            setData(response.result);
            setTags(response.Tags)
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownload = async (fileName) => {
        try {
            const fileUrl = `${API_BASE_URL}/${mData.result?.id}/statement/${fileName}`
            const response = await fetch(fileUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf', 
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); 
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    
    const columns = [
        {
            dataField: "TimeStamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "TimeStamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "StartDate",
            text: "Start Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "EndDate",
            text: "End Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },

        {
            dataField: "FileName",
            text: "Download",
            formatter: (cell, row) => (
                <div>
                    <p
                        className="Details_popup"
                        onClick={() => handleDownload(cell)}
                    >
                        <img src={downloads} alt="icon" width={50} />
                    </p>
                </div>
            ),
        }
    ];


    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: datas.length,
        custom: true,
    };

    const selectRow = {
        mode: "checkbox",
    };

    const { SearchBar } = Search;
    
    const fecthTaxesFrombackend = async () => {
        try {
            const amountArray = tags.find((item) => item.value === months)
            if (amountArray && amountArray.amount) {
                const numericString = amountArray.amount.replace(/\D/g, '');
                const extractedAmount = numericString.substring(0, 3);
                setAmount(extractedAmount)
                const formData = {
                    Amount: extractedAmount,
                    Module: '78770589-ac50-4192-9052-ab29516ac523',
                    Module_Type: '2802edda-e292-4c36-8dc0-dcdabe0ff5c4',
                    Nature: 'PERSONAL'
                }
                const response = await checkTaxCalclations(formData)
                setTaxes(response)
                setIsOpen(true)
            } else {
                console.log("No valid amount found in the array");
            }
        } catch (error) {
            console.log(error);
        }
    }

    function toggle() {
        setIsOpen(!isOpen)
    }

    return (
        <React.Fragment>
            <StatementAmount amount={amount} taxes={taxes} isOpen={isOpen} toggle={toggle} months={months} fetchStatement={fetchStatement} />
            <div className="page-content"
                style={{ marginLeft: "5%", marginRight: "5%" }}>
                <Breadcrumbs title="GET" breadcrumbItem="Statement" />
                <Row>
                    <Col md={{ offset: 3, size: 6, }} >
                        <h2 className="text-center mt-5 mb-4 title-color-common " style={{ fontFamily: "Gilroy" }} >
                            STATEMENTS
                        </h2>
                        <p className="text-center heading_description mb-5 description-color">
                            History of all statement in your account
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            gap: "50px"
                        }}
                            className="mb-4"
                        >
                            <div style={{ display: "flex", gap: "10px", }}>
                                <select className="filtter_select_box" name="months" value={months} onChange={(e) => setMonths(e.target.value)}>
                                    <option selected hidden>Select  </option>
                                    {tags?.map((item, index) => (
                                        <option key={index} value={item?.value}
                                            style={{ width: "100%", }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                                gap: "20px"
                                            }}
                                            >
                                                <p>{item.label}</p>
                                                <p>{item.amount}</p>
                                            </div>

                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                gap: "20px",
                            }}>
                                <Button type="submit" style={{ fontSize: "16px", fontFamily: "Gilroy" }} className="procces_Button" onClick={fecthTaxesFrombackend}>
                                    Pay Amount
                                </Button>
                            </div>
                        </div>
                        <Card className="mt-5">
                            <CardBody>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField="id"
                                    columns={columns}
                                    // data={products}
                                    data={datas}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            columns={columns}
                                            data={datas}
                                            search
                                            exportCSV
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    //   selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    style={{
                                                                        fontWeight: "Gilroy",
                                                                    }}
                                                                    headerClasses={"custom-header-class"}
                                                                    rowStyle={{
                                                                        fontSize: "15px",
                                                                        fontFamily: "Gilroy",
                                                                        fontWeight: "400",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};




export default Statement;
