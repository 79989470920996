import React, { useState, useEffect } from 'react';

const RedirectPopup = ({ isRedirectOpen, redirectData, handleCloseModal }) => {
    const [redirectUrl, setRedirectUrl] = useState('');
    const [popupWindow, setPopupWindow] = useState(null);

    useEffect(() => {
        const handleApiResponse = async (response) => {
            if (response?.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
        };
        handleApiResponse(redirectData);
    }, [redirectData]);


    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (redirectData?.pollUrl && popupWindow) {
                try {
                    const response = await fetch(redirectData.pollUrl);
                    const data = await response.json();
                    if (data) {
                        setTimeout(() => {
                            window.close();
                        }, 3000);
                    }
                } catch (error) {
                    console.error('Error checking payment status:', error);
                }
            }
        };
        checkPaymentStatus();
    }, [redirectData, popupWindow]);
    return null;
};
export default RedirectPopup;
