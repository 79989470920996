import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/GET-logo.png";
import getvault from "../../assets/images/NewImage/GETVault_LOGO.png";
import "./profile.css";
import Merchanttlogo from "../../assets/images/NewImage/Invoice-Logo.png";
import getLogo from "../../assets/images/NewImage/Vault_Wheel.png";
import LoginFooter from "components/Common/LoginFooter";
import PolicyFooter from "components/Common/TermsAndconditionFooter";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  handleLinkClick() {
    const url = "https://hexims.it/";
    window.open(url, "_blank");
  }

  render() {
    document.title = "GET Platform | Forget Password";
    console.log(this.props);
    
    return (
      <React.Fragment>
        <div className="Login_header">
          <img src={Merchanttlogo} alt="image" />
          <Link to="login">
            <span>Login</span>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5 ">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={8} xl={6}>
                <div className="Sign-in-page">
                  <img src={getLogo} alt="image" />
                  <span>
                    <b>Having trouble to sign in? Reset your password.</b>
                  </span>
                </div>
                <div className="p-4">
                  {/* {this.props.forgetError && this.props.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {this.props.forgetError}
                    </Alert>
                  ) : null} */}

                  {/* {this.props.forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {this.props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  {this.props.forgetError && this.props.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {this.props.forgetError}
                    </Alert>
                  ) : null} */}

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: (this.state && this.state.email) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().required("Please Enter Your Email"),
                    })}
                    onSubmit={(values) => {
                      this.props.userForgetPassword(values, this.props.history);
                    }}
                  >
                    {({ errors, status, touched }) => (
                      <Form className="form-horizontal ">
                        <div className="mb-3">
                          <Label
                            for="email"
                            className="form-label form_label_login"
                          >
                            Email
                          </Label>
                          <Field
                            name="email"
                            type="text"
                            placeholder="Enter email"
                            className={
                              "form-control form_control_login" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className=" d-grid">
                          <button
                            className="btn btn-primary w-md get_login_Button"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                        <PolicyFooter />
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ paddingTop: "70px" }}>
          <LoginFooter />
        </div>
      </React.Fragment>
    );
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.func,
  forgetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userForgetPassword: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);

// import PropTypes from "prop-types";
// import React, { Component } from "react";
// import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

// // Redux
// import { connect } from "react-redux";
// import { Link, withRouter } from "react-router-dom";

// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";

// // action
// import { userForgetPassword } from "../../store/actions";

// // import images
// import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/GET-logo.png";
// import getvault from "../../assets/images/NewImage/GETVault_LOGO.png"
// import "./profile.css"

// class ForgetPasswordPage extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     return (
//       <React.Fragment>
//          <div className="background-login-image">
//         <div className="home-btn d-none d-sm-block">
//           <Link to="/" className="text-dark">
//             <i className="bx bx-home h2" />
//           </Link>
//         </div>
//         <div className="account-pages my-5 pt-sm-5">
//           <Container>
//             <Row className="justify-content-center">
//               <Col md={8} lg={6} xl={5}>
//                 <Card className="overflow-hidden">
//                 <div
//                   //  className="bg-primary bg-soft"
//                   style={{backgroundColor:"#000033"}}
//                    >
//                     <Row>
//                       <Col className="col-12">
//                         <div className="text-primary text-center  pt-4 pb-4">
//                           <h4  className="login-top-card-text-head">GET Vault</h4>
//                           <h4  className="login-top-card-login-text">Forget</h4>
//                         </div>
//                       </Col>
//                       {/* <Col className="col-5 align-self-end">
//                         <img src={profile} alt="" className="img-fluid" />
//                       </Col> */}
//                     </Row>
//                   </div>
//                   <CardBody className="pt-0">
//                     <div>
//                       <Link to="/">
//                         <div className="avatar-md profile-user-wid mb-4">
//                           <span className="avatar-title rounded-circle bg-light">
//                             <img
//                               src={getvault}
//                               alt=""
//                               className="rounded-circle"
//                               height="52"
//                             />
//                           </span>
//                         </div>
//                       </Link>
//                     </div>
//                     <div className="p-2">
//                       {this.props.forgetError && this.props.forgetError ? (
//                         <Alert color="danger" style={{ marginTop: "13px" }}>
//                           {this.props.forgetError}
//                         </Alert>
//                       ) : null}
//                       {this.props.forgetSuccessMsg ? (
//                         <Alert color="success" style={{ marginTop: "13px" }}>
//                           {this.props.forgetSuccessMsg}
//                         </Alert>
//                       ) : null}

//                       <Formik
//                         enableReinitialize={true}
//                         initialValues={{
//                           email:
//                             (this.state && this.state.email) || "",
//                         }}
//                         validationSchema={Yup.object().shape({
//                           email: Yup.string().required(
//                             "Please Enter Your Email"
//                           ),
//                         })}
//                         onSubmit={values => {
//                           this.props.userForgetPassword(values, this.props.history);
//                         }}
//                       >
//                         {({ errors, status, touched }) => (
//                           <Form className="form-horizontal ">
//                             <div className="mb-3">
//                               <Label for="email" className="form-label form_label_login">
//                                 Email
//                               </Label>
//                               <Field
//                                 name="email"
//                                 type="text"
//                                 placeholder="Enter email"
//                                 className={
//                                   "form-control form_control_login" +
//                                   (errors.email && touched.email
//                                     ? " is-invalid"
//                                     : "")
//                                 }
//                               />
//                               <ErrorMessage
//                                 name="email"
//                                 component="div"
//                                 className="invalid-feedback"
//                               />
//                             </div>
//                             <div className="text-end">
//                               <button
//                                 className="btn btn-primary w-md get_vault_button"
//                                 type="submit"
//                               >
//                                 Send link
//                               </button>
//                             </div>
//                           </Form>
//                         )}
//                       </Formik>
//                     </div>
//                   </CardBody>
//                 </Card>
//                 <div className="mt-5 text-center">
//                   <p style={{fontFamily:"Gilroy", fontSize:"16px", color:"white" }}>
//                     Go back to{" "}
//                     <Link to="login" className="fw-medium " style={{fontFamily:"Gilroy", fontSize:"16px", color:"white"}}>
//                       Login
//                     </Link>{" "}
//                   </p>

//                 </div>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// ForgetPasswordPage.propTypes = {
//   forgetError: PropTypes.func,
//   forgetSuccessMsg: PropTypes.string,
//   history: PropTypes.object,
//   userForgetPassword: PropTypes.any,
// };

// const mapStateToProps = state => {
//   const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
//   return { forgetError, forgetSuccessMsg };
// };

// export default withRouter(
//   connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
// );
