import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Route, Redirect } from "react-router-dom";
import { useData } from "Context/ContextProvider";
import { compileString } from "sass";
import localScssText from '../../src/default-theme.scss';

const API_BASE_URL = process.env.REACT_APP_API_URL;


const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const { mData } = useData();
  const Register_Status = mData?.result?.Register_Status ? mData?.result?.Register_Status === "COMPLETED" : true;
  const ScreenLock = mData?.result?.ScreenLock ? mData?.result?.ScreenLock === "UNLOCK" : true;
  const kycStatus = mData?.result?.KycStepStatus ? mData?.result?.KycStepStatus === "COMPLETED" : true;

  useEffect(() => {
    const compileAndInjectScss = (scssText) => {
      try {
        // if (typeof scssText !== 'string') {
        //   throw new Error("SCSS content is not a valid string.");
        // }

        const result = compileString(scssText);
        const style = document.createElement("style");
        style.innerHTML = result.css;
        document.head.appendChild(style);

        const colorMatch = result.css.match(/\.title-color-common\s*{\s*color:\s*(#[0-9a-fA-F]{6});\s*}/);
        if (colorMatch && colorMatch[1]) {
          localStorage.setItem('iconColor', colorMatch[1]);
        }
      } catch (error) {
        // console.error("Failed to compile SCSS: ", error);
      }
    };

    const fetchAndApplyTheme = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/${mData?.result?.id}/profile/${mData?.result?.Themes}`);
        if (!response.ok) {
          // throw new Error(`Failed to fetch theme SCSS: ${response.statusText}`);
        }

        const scssText = await response.text();
        compileAndInjectScss(scssText);
      } catch (error) {
        // console.error("Failed to fetch or compile SCSS, using local default theme: ", error);
        compileAndInjectScss(localScssText);
      }
    };

    if (mData?.result?.Themes) {
      fetchAndApplyTheme();
    } else {
      compileAndInjectScss(localScssText);
    }
  }, [mData?.result]);

  // useEffect(() => {
  //   const compileAndInjectScss = (scssText) => {
  //     try {
  //       const result = compileString(scssText);
  //       const style = document.createElement("style");
  //       style.innerHTML = result.css;
  //       document.head.appendChild(style);
  //       const colorMatch = result.css.match(/\.title-color-common\s*{\s*color:\s*(#[0-9a-fA-F]{6});\s*}/);
  //       if (colorMatch && colorMatch[1]) {
  //         localStorage.setItem('iconColor', colorMatch[1]);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetch(`${API_BASE_URL}/${mData?.result?.id}/profile/${mData?.result?.Themes}`)
  //     .then(response => response.text())
  //     .then(scssText => {
  //       if (typeof scssText === 'string') {
  //         compileAndInjectScss(scssText);
  //       } else {
  //         throw new Error("Fetched SCSS is not a string.");
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Failed to fetch SCSS file from API, using local SCSS file instead: ", error);
  //       compileAndInjectScss(localScssText);
  //     });
  // }, [mData?.result]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUsers")) {
          return (
            <Redirect
              to={{ pathname: "/captch-otp", state: { from: props.location } }}
            />
          );
        } else if (!ScreenLock && props.location.pathname !== "/auth-lock-screen") {
          return (
            <Redirect
              to={{ pathname: "/auth-lock-screen", state: { from: props.location } }}
            />
          );
        }
        else if (!Register_Status && props.location.pathname !== "/registration-form2") {
          return (
            <Redirect
              to={{ pathname: "/registration-form2", state: { from: props.location } }}
            />
          );
        } else if (Register_Status && !kycStatus && props.location.pathname !== "/verification") {
          return (
            <Redirect
              to={{ pathname: "/verification", state: { from: props.location } }}
            />
          );
        };
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
};

export default AppRoute;








// import React from "react"
// import PropTypes from 'prop-types'
// import { Route, Redirect } from "react-router-dom"
// let isLocked = ""
// const AppRoute = ({
//   component: Component,
//   layout: Layout,
//   isAuthProtected,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={props => {
//       if (isAuthProtected && !localStorage.getItem("authUsers")&& !isLocked) {
//         return (
//           <Redirect
//             to={{ pathname: "/login", state: { from: props.location } }}
//           />
//         )
//       }
//       return (
//         <Layout>
//           <Component {...props} />
//         </Layout>
//       )
//     }}
//   />
// )

// AppRoute.propTypes = {
//   isAuthProtected: PropTypes.bool,
//   component: PropTypes.any,
//   location: PropTypes.object,
//   layout: PropTypes.any
// }

// export default AppRoute





// <Row className="justify-content-center scan-pay-user-details">
// <Col xl="4">
//     <h4 className="card-title">Scan Pay</h4>
// </Col>
// <Col xl="4"></Col>
// <Col xl="4">
//     {socketScanPay && (
//         <div className="timer-container">
//             <p>Time Left: {formatTime(timeLeft)}</p>
//         </div>
//     )}
// </Col>
// </Row>