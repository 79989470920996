import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import PropTypes from 'prop-types';
import "toastr/build/toastr.min.css";

// i18n
import { withTranslation } from "react-i18next";
import { ReadNotificationStatus } from "Context/ContextApi";
import { useData } from "Context/ContextProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const NotificationDropdown = ({ t }) => {
  const history = useHistory()
  const { notifications } = useData();
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(prevMenu => !prevMenu);
  };

  dayjs.extend(relativeTime);

  const unreadCount = notifications.filter(item => item.Status === 'Unread').length;
  const handleReRenderToPage = async (item) => {
    try {
      const formData = {
        id: item.id
      }
      const response = await ReadNotificationStatus(formData)
      if (item.Type === "CREDIT") {
        history.push("/payments")
      } else if (item.Type === "DEBIT") {
        history.push("/transaction")
      } else if (item.Type === "REQUEST") {
        history.push("/request-money-history")
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleTonotification = async () => {
    try {
      const response = await ReadNotificationStatus()
      history.push("/Notifications")
    } catch (error) {
      console.log(error);
    }
  }

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };


  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill" >{unreadCount}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{t("Notifications")}</h6>
              </Col>
              <div className="col-auto">
                <a onClick={handleTonotification} className="small">
                  {" "}
                  {t("View All")}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "250px" }}>
            <div className="text-reset notification-item" >
              {notifications?.map((item, index) => (
                <div className="d-flex" key={index} onClick={() => handleReRenderToPage(item)}>
                  <div className="avatar-sm me-2" style={{ height: "28px" }}>
                    <span className="avatar-title bg-success rounded-circle font-size-18">
                      <i className="bx bx-badge-check" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="mt-0 mb-1" style={{ fontFamily: "Gilroy" }}>
                      {item.Title}
                    </h5>
                    <div className="font-size-13 text-muted">
                      <p className="mb-1" style={{ fontFamily: "Gilroy" }}>
                        {truncateText(item?.Message, 80)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {dayjs(item.Timestamp).fromNow(true)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more" onClick={handleTonotification} >{t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
