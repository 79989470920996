import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "flatpickr/dist/themes/material_blue.css";
import { gMerchantOfferRedeemed, } from "Context/ContextApi";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useData } from "Context/ContextProvider";
import { io } from "socket.io-client";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import eyeIcon from "../../assets/images/NewImage/icons8-eye-64.png"

const API_BASE_URL = process.env.REACT_APP_API_URL
const CampaignRedeemed = () => {
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { userDataa } = useData();
    const [productData, setProductData] = useState([]);
    const [status, setStatus] = useState("")
    const effact = "effact"
    useEffect(() => { handleFetchMerchantOffer() }, [effact])

    const handleFetchMerchantOffer = async (Status) => {
        try {
            const formData = { Status: Status }
            const response = await gMerchantOfferRedeemed(formData)
            setProductData(response.result)
            const result = response.result.map(item => {
                const offerDetailsArray = [];
                if (item.Tax_Details) {
                    const taxDetails = JSON.parse(item.Tax_Details);
                    taxDetails.forEach(detail => {
                        if (detail.OfferDetails && detail.Value) {
                            offerDetailsArray.push({ offerDetails: detail.Value });
                        }
                    });
                }
                return { ...item, detail: offerDetailsArray };
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const socket = io(`${API_BASE_URL}`);
        const userId = userDataa?.id;
        socket.emit('userConnected', userId);
        socket.on('merchantRedeemed', (data) => {
            setProductData(prev => [...data, ...prev]);
        });
        return () => {
            socket.disconnect();
        };
    }, [!userDataa?.id]);

    const columns = [
        {
            dataField: "Transaction_date",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "Tax_Details",
            text: "Offer Name",
            sort: true,
            formatter: (cell, row) => {
                const offerDetails = JSON.parse(cell);
                if (Array.isArray(offerDetails)) {
                    return offerDetails.map((item, index) => (
                        <div key={index}>
                            {item.OfferDetails && (
                                <div>
                                    {item.Value.OffersName}
                                </div>
                            )}
                        </div>
                    ));
                } else {
                    return null;
                }
            }
        },
        {
            dataField: "Tax_Details",
            text: "Start / End Date",
            sort: true,
            formatter: (cell, row) => {
                const offerDetails = JSON.parse(cell);
                if (Array.isArray(offerDetails)) {
                    return offerDetails.map((item, index) => (
                        <div key={index}>
                            {item.OfferDetails && (
                                <div>
                                    <div>{new Date(item.Value.StartDate).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        hour12: false,
                                    })}</div>
                                    <div> {new Date(item.Value.EndDate).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        hour12: false,
                                    })}</div>
                                </div>
                            )}
                        </div>
                    ));
                } else {
                    return null;
                }
            }
        },
        {
            dataField: "sender_name",
            text: "User Name",
            sort: true,
        },
        {
            dataField: "Sender_vpa",
            text: "VPA",
            sort: true,
        },
        {
            dataField: "Amount",
            text: "Amount",
            sort: true,
        },
        {
            dataField: "DiscountAmount",
            text: "Discount Amount",
            sort: true,
        },
        {
            dataField: "details",
            text: "Details",
            sort: false,
            formatter: (cell, row) => (
                <div>
                    <Link to={`/payment-details/${row?.id}`}>
                        <p
                            className="Details_popup"
                        // onClick={() => togglePopup(row)}
                        >
                            <img src={eyeIcon} alt="icon" />
                        </p>
                    </Link>
                </div>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length,
        custom: true,
    };

    const selectRow = {
        mode: "checkbox",
    };

    const { SearchBar } = Search;
    document.title = "GET Platform | Redeemed"
    return (
        <React.Fragment>
            <div
                className="page-content"
                style={{ marginLeft: "5%", marginRight: "5%" }}
            >
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem=" Redeemed" />
                    <Row>
                        <Col md={{ offset: 3, size: 6 }}>
                            <h1 className="text-center all_newPaeHeading title-color-common">CAMPAIGNS - REDEEMED</h1>
                            <h5 className="text-center all_sub_new_heading description-color">
                                List of all payments you received from customers
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <h5 className="mb-3 sub_heading_left_side_the_page">
                                All list of Redeemed
                            </h5>
                            <Card className="mt-5">
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={productData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={productData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        // selectRow={selectRow}
                                                                        classes={"table align-middle table-nowrap"}
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        style={{
                                                                            fontWeight: "Gilroy",
                                                                        }}
                                                                        headerClasses={"custom-header-class"}
                                                                        rowStyle={{
                                                                            fontSize: "15px",
                                                                            fontFamily: "Gilroy",
                                                                            fontWeight: "400"
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CampaignRedeemed;
