import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import lock from "../../assets/images/NewImage/verrouiller-alt.png";
import edit from "../../assets/images/NewImage/editer.png";
import "./UserProfile.css";
import { useState } from "react";
import userEditProfile from "../../assets/images/NewImage/user-avatar.png";
import cancelImg from "../../assets/images/NewImage/x-button.png";
import { ResetMerchantPin, generateotpForTheMerchantSecreatPin, updateAndChangeThePassword, updateTheUserProfile, updateprofileData } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./PrintCode";
import wheelLogo from "../../assets/images/NewImage/Vault_Wheel.png"
import { CURRENCY } from "components/Common/Currency";
import { useData } from "Context/ContextProvider";
import { UpdateMerchantForm } from "./profileform";
import UserprofileHeader from "./profileHeader";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function Userprofile() {
  const { mData } = useData();
  const [userData, setUserData] = useState({});
  const [isPassword, setIsPassword] = useState(false);
  const [isChangePin, setIsChangePin] = useState(false)
  const [isButtonVisible, setButtonVisibility] = useState(true)
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [actionData, setActionData] = useState({})
  const [changePasswordForm, setChangePasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    applicationPin: "",
  })
  const [changeResetPinForm, setChangeResetPinForm] = useState({
    newPin: "",
    confirmPin: "",
    changePinOTP: ""
  })
  const [formErrors, setFormErrors] = useState({});
  const [formErrorsPin, setFormErrorsPin] = useState({})

  const [formDatass, setFormData] = useState({
    Title: "",
    FullName: "",
    Alias: "",
    Email: "",
    Mobile: "",
    NationalIDno: "",
    AddressProofNo: "",

    PersonalAddressLine1: "",
    PersonalAddressLine2: "",
    FlatNo: "",
    PersonalNearby: "",
    PersonalCity: "",
    PersonalProviance: "",
    PersonalCountry: "",
    PersonalZipcode: "",

    BusinessName: "",
    BusinessAddressLine1: "",
    BusinessAddressLine2: "",
    BusinessNearby: "",
    BusinessCity: "",
    BusinessProviance: "",
    BusinessCountry: "",
    BusinessZipcode: "",

  });

  useEffect(() => {
    setUserData(mData?.result);
    setFormData({ ...formDatass, ...mData?.result });
    let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
    setActionData(jsonparse)
  }, [mData?.subResult?.Permissions])


  // validation for change password model 
  const handleChangeForPassword = e => {
    const { name, value, } = e.target;
    setChangePasswordForm({ ...changePasswordForm, [name]: value });
  }
  const validateForChangePassword = () => {
    let errors = {};
    if (!changePasswordForm.oldPassword) {
      errors.oldPassword = "Please enter old password";
    }
    if (!changePasswordForm.newPassword) {
      errors.newPassword = "Please enter a password";
    } else if (changePasswordForm.newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters long";
    } else if (
      !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        changePasswordForm.newPassword
      )
    ) {
      errors.newPassword =
        "Password should be a combination of alphabets, numbers, special characters, minimum 8 characters";
    }
    if (!changePasswordForm.confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    if (!changePasswordForm.applicationPin) {
      errors.applicationPin = "Enter application pin"
    }
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const handleChangePassword = async () => {
    try {
      if (!validateForChangePassword()) {
        return;
      }
      const formData = {
        oldPassword: changePasswordForm.oldPassword,
        newPassword: changePasswordForm.newPassword,
        applicationPin: changePasswordForm.applicationPin
      }
      const response = await updateAndChangeThePassword(formData)
      toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true,
        closeDuration: 700,
        positionClass: "toast-top-right"
      });
      setIsPassword(false)
      setChangePasswordForm({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        applicationPin: ""
      })
    } catch (error) {
      console.log(error);
      toastr.warning(error.message)
    }
  }

  // generate otp
  const handleGenerateOTP = async () => {
    try {
      const response = await generateotpForTheMerchantSecreatPin()
      toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true,
        closeDuration: 700,
        positionClass: "toast-top-right"
      });
      setButtonVisibility(false);
      setSecondsRemaining(60);
      startCountdown();
    } catch (error) {
      console.log(error);
    }
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    setTimeout(() => {
      setButtonVisibility(true);
      clearInterval(countdownInterval);
    }, 60000);
  };

  useEffect(() => {
    return () => {
      clearInterval(startCountdown);
    };
  }, []);

  // validation for change Reset pin modal 
  const handleChangeForResetPin = e => {
    const { name, value, } = e.target;
    setChangeResetPinForm({ ...changeResetPinForm, [name]: value });
  }
  const validateForResetPin = () => {
    let errors = {};
    if (!changeResetPinForm.newPin) {
      errors.newPin = "Please enter a pin";
    } else if (changeResetPinForm.newPin.length < 4) {
      errors.newPin = "Pin must be at least 4 characters long";
    } else if (!/^\d{4}$/.test(changeResetPinForm.newPin)) {
      errors.newPin = "Pin should be numeric and exactly 4 characters long";
    }

    if (!changeResetPinForm.confirmPin) {
      errors.confirmPin = "Please confirm your pin";
    } else if (changeResetPinForm.newPin !== changeResetPinForm.confirmPin) {
      errors.confirmPin = "Pins do not match";
    }
    if (!changeResetPinForm.changePinOTP) {
      errors.changePinOTP = "Enter application otp"
    }
    setFormErrorsPin(errors);

    return Object.keys(errors).length === 0;
  };

  const handleChangeResetPin = async () => {
    try {
      if (!validateForResetPin()) {
        return;
      }
      const formData = {
        pin: changeResetPinForm.newPin,
        otp: changeResetPinForm.changePinOTP
      }
      const response = await ResetMerchantPin(formData)
      toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true,
        closeDuration: 700,
        positionClass: "toast-top-right"
      });
      setIsChangePin(false)
      setChangeResetPinForm({
        newPin: "",
        confirmPin: "",
        changePinOTP: ""
      })
    } catch (error) {
      console.log(error);
      toastr.warning(error.message)
    }
  }
  document.title = "GET Platform  | Profile";


  return (
    <>
      <Modal
        isOpen={isPassword}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="isDEfault_popup_content ">
            <div className="is_default_heading">
              <h3 className="title-color-common">Change passsword</h3>
              <img
                src={cancelImg}
                alt="cancel"
                onClick={() => setIsPassword(false)}
              />
            </div>
            <div className="idefault_popup_form_group mt-3">
              <label>Old password</label>
              <input type="password" placeholder="Old password"
                name="oldPassword"
                value={changePasswordForm.oldPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, oldPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.oldPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>New passsword</label>
              <input type="password" placeholder="New password"
                name="newPassword"
                value={changePasswordForm.newPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, newPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.newPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Confirm passsword</label>
              <input type="password" placeholder="Confirm password"
                name="confirmPassword"
                value={changePasswordForm.confirmPassword}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, confirmPassword: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.confirmPassword}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Application Pin</label>
              <input type="password" placeholder="Enter application pin"
                name="applicationPin"
                value={changePasswordForm.applicationPin}
                onChange={e => {
                  handleChangeForPassword(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, applicationPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrors.applicationPin}
              </span>
              <Button className="procces_Button mt-3" onClick={handleChangePassword}>Save change</Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* change pin model */}
      <Modal
        isOpen={isChangePin}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="isDEfault_popup_content ">
            <div className="is_default_heading">
              <h3 className="title-color-common">Reset Pin</h3>

              <img
                src={cancelImg}
                alt="cancel"
                onClick={() => setIsChangePin(false)}
              />
            </div>

            <div className="idefault_popup_form_group mt-2">
              <label>New Pin</label>
              <input type="number" placeholder="New Pin"
                name="newPin"
                value={changeResetPinForm.newPin}
                maxLength={4}
                onChange={e => {
                  handleChangeForResetPin(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, newPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrorsPin.newPin}
              </span>
            </div>
            <div className="idefault_popup_form_group mt-2">
              <label>Confirm pin</label>
              <input type="number" placeholder="Confirm Pin"
                name="confirmPin"
                maxLength={4}
                value={changeResetPinForm.confirmPin}
                onChange={e => {
                  handleChangeForResetPin(e);
                  if (e.target.value.trim() !== "") {
                    setFormErrors({ ...formErrors, confirmPin: "" });
                  }
                }} />
              <span className="warning_message">
                {formErrorsPin.confirmPin}
              </span>

            </div>
            <div className="idefault_popup_form_group mt-3">
              <label>Request OTP</label>
              <div className="request_OTP_input">
                <input type="number" placeholder="Enter OTP"
                  name="changePinOTP"
                  maxLength={6}
                  value={changeResetPinForm.changePinOTP}
                  onChange={e => {
                    handleChangeForResetPin(e);
                    if (e.target.value.trim() !== "") {
                      setFormErrors({ ...formErrors, changePinOTP: "" });
                    }
                  }} />

                <Button className={`procces_Button ${isButtonVisible ? "disable_button" : ""}`} onClick={handleGenerateOTP}>Request OTP</Button>
              </div>
              <span className="warning_message">
                {formErrorsPin.changePinOTP}
              </span>
              {!isButtonVisible && (
                <small className="generate_pin_timer">{secondsRemaining > 0 ? `${secondsRemaining} sec` : ''}</small>
              )}
            </div>
            <div className="idefault_popup_form_group">
              <Button className="procces_Button mt-3" onClick={handleChangeResetPin}>Save change</Button>

            </div>
          </div>
        </div>
      </Modal>
      {/* )} */}
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Breadcrumbs title="GET" breadcrumbItem="Profile" />
            <Row>
              <Col
                md={{
                  offset: 3,
                  size: 6,
                }}
              >
                <h1 className="text-center all_newPaeHeading title-color-common">Your profile</h1>
                <p className="text-center all_sub_new_heading description-color">
                  View, edit or manage all your account related data in one
                  place
                </p>
              </Col>
            </Row>
            <UserprofileHeader mData={mData} />
            <UpdateMerchantForm mData={mData} />
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default Userprofile;

