import { CURRENCY } from "components/Common/Currency";
import React, { useEffect } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";

const Step1 = ({
  sendForm,
  handleChange,
  validationErrors,
  valueForBankDetails,
  handleProceed,
  fecthTaxesFrombackend,
  profileData,
  dynmicMethod,
  dynmicTypes
}) => {
  const handleNext = () => {
    const isValid = handleProceed(2);
    if (!isValid) {
      fecthTaxesFrombackend();
    }
  };

  useEffect(() => {
    if (profileData.Currency && !sendForm.currency) {
      handleChange({ target: { name: 'currency', value: profileData.Currency } });
    }
  }, [profileData.Currency, sendForm.currency]);



  return (
    <div>
      <div className="form_gruap_for_deposit">
        <FormGroup>
          {/* <div className="form_group_for_inside_in_deposit">
            <label>Currency</label>
            <select name="currency" value={sendForm.currency} onChange={handleChange}>
              <option defaultValue hidden >Select Currency</option>
              <option value="USD">USD</option>
              <option value="ZiG">ZiG</option>
            </select>
            {validationErrors.currency && (
              <span className="error-message">{validationErrors.currency}</span>
            )}
          </div> */}
        </FormGroup>
        <FormGroup>
          <div className="form_group_for_inside_in_deposit">
            <Label for="amount">Amount</Label>
            <Input
              type="number"
              name="amount"
              id="amount"
              placeholder="Enter amount"
              value={sendForm.amount}
              onChange={handleChange}
            />
            {validationErrors.amount && (
              <small className="text-danger">{validationErrors.amount}</small>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <div className="form_group_for_inside_in_deposit">
            <Label for="bankName">Method</Label>
            <select name="bankName" value={sendForm.bankName} onChange={handleChange}>
              <option defaultValue hidden>Select Method</option>
              {dynmicMethod?.map((item, index) => (
                <option key={index} value={item.Module}>{(item.Module).toUpperCase()}</option>
              ))}
              {/* <option value="Paynow" >Paynow</option>
              <option value="CBZ" >CBZ</option>
              <option value="CABS" >CABS</option> */}
              {/* <option value="EFT" >EFT</option>
              <option value='CABS' >CABS</option>
              <option value='VMC' >CABS VMC</option> */}
            </select>
            {validationErrors.bankName && (
              <small className="text-danger">{validationErrors.bankName}</small>
            )}
          </div>
        </FormGroup>
        <FormGroup>
          <div className="form_group_for_inside_in_deposit">
            <Label for="bankName">Type</Label>
            <select name="Type" value={sendForm.Type} onChange={handleChange}>
              <option defaultValue hidden>Select Type</option>
              {dynmicTypes?.map((item, index) => (
                <option key={index} value={item.Type}>{(item.Type).toUpperCase()}</option>
              ))}
            </select>
            {validationErrors.bankName && (
              <small className="text-danger">{validationErrors.bankName}</small>
            )}
          </div>
        </FormGroup>
        <Button color="primary" className="procces_Button" onClick={handleNext}>
          Next
        </Button>
      </div>

    </div>
  );
};

export default Step1;
