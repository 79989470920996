'use client';

import { useEffect, useState } from 'react';

export default function MerchantColorTheme({ data = {}, onChangeTheme }) {
    const [selectedCategory, setSelectedCategory] = useState();
    const [localColors, setLocalColors] = useState(data);

    useEffect(() => {
        setLocalColors(data);
    }, [data]);

    useEffect(() => {
        setTimeout(() => {
            onChangeTheme(localColors);
        }, 1000);
    }, [localColors])

    const handleChange = (category, key, value) => {
        const keys = category.split(' > ');

        setLocalColors((prevState) => {
            const updatedState = { ...prevState };

            let currentLevel = updatedState;
            for (let i = 0; i < keys.length - 1; i++) {
                const part = keys[i];
                currentLevel[part] = { ...currentLevel[part] };
                currentLevel = currentLevel[part];
            }

            currentLevel[keys[keys.length - 1]] = { ...currentLevel[keys[keys.length - 1]], [key]: value };

            return updatedState;
        });
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const renderCategoryInputs = (category, obj) => {
        return Object.keys(obj).map(key => {
            if (typeof obj[key] === 'object') {
                return (
                    <div key={key} >
                        <h3>{key}</h3>
                        {renderCategoryInputs(`${category} > ${key}`, obj[key])}
                    </div>
                );
            } else {
                return (
                    <div key={key} className='flex gap-2 flex-wrap items-center'>
                        <title as="h6" className="font-semibold">
                            {key} :
                        </title>
                        <div className='d-flex gap-3'>
                        <input
                            className="form-control form_in_offer_input_box"
                            style={{height:"40px"}}
                            type="color"
                            value={obj[key]}
                            onChange={(e) => handleChange(category, key, e.target.value)}
                        />
                        <input
                            className="form-control form_in_offer_input_box"
                            type="text"
                            placeholder='enter color code'
                            value={obj[key]}
                            onChange={(e) => handleChange(category, key, e.target.value)}
                        />
                        </div>
                    </div>
                );
            }
        });
    };

    return (
        <div >
            <div style={{ marginBottom: '20px', width: "100%" }}>
                <title as="h6" className="font-semibold">Select Category</title>
                <select
                    className="form-select form_in_offer_input_box"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                >
                    {Object.keys(localColors).map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "start" }}>
                {selectedCategory && renderCategoryInputs(selectedCategory, localColors[selectedCategory])}
            </div>
        </div>
    );
}
