import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import { Provider } from "react-redux";

import store from "./store";
import { DataProvider } from "Context/ContextProvider";
import 'react-credit-cards/es/styles-compiled.css';
import { GoogleOAuthProvider } from "@react-oauth/google";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId='718045377770-najjd732hrbdugtse6e6n6lss6vhqhu8.apps.googleusercontent.com'>
    <BrowserRouter>
      <DataProvider>
        <Provider store={store}>
          <React.Fragment>
            <App />
          </React.Fragment>
        </Provider>
      </DataProvider >
    </BrowserRouter>
  </GoogleOAuthProvider>




  // </React.StrictMode>
);
serviceWorker.unregister();
