// import { useState, useEffect, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
// import Cookies from "js-cookie";
// import { Button, Modal } from 'reactstrap';
// import React from 'react';
// import { useData } from 'Context/ContextProvider';
// import { io } from 'socket.io-client';

// const authUser = Cookies.get("authUsers")
// const authUserData = authUser ? JSON.parse(authUser) : '';
// const UserToken = authUserData ? authUserData : '';

// const API_BASE_URL = process.env.REACT_APP_API_URL;

// const AuthChecker = () => {
//   const { mData, userDataa, userInfo , socket} = useData();
//   const history = useHistory();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [timeRemaining, setTimeRemaining] = useState(null);


//   useEffect(() => {
//     const checkAuthExpiration = async () => {
//       const authUserToken = Cookies.get("authUsers");
//       if (!authUserToken) {
//         socket.emit("merchantInfo", JSON.stringify(data));
//         let data = {
//           MerchantId: userDataa.id,
//           Status: "INACTIVE",
//           LastInactiveDateTime: new Date(),
//           DateTime: '',
//           ...userInfo
//         }
        

//         history.push('/logout');
//         localStorage.removeItem("paths");
//       } else {
//         const authUserData = JSON.parse(authUserToken);
//         const sessionExpiry = new Date(authUserData.expires);
//         const currentTime = new Date();
//         const timeLeft = sessionExpiry - currentTime;
//         setTimeRemaining(timeLeft);
//         // Show modal if time remaining is less than or equal to 5 minutes (300000 ms)
//         if (timeLeft <= 300000 && timeLeft > 0) {
//           setIsModalOpen(true);
//         } else {
//           setIsModalOpen(false);
//         }
//       }
//     };

//     checkAuthExpiration();
//     const intervalId = setInterval(checkAuthExpiration, 5000);
//     return () => clearInterval(intervalId);
//   }, [history]);

//   const extendSession = () => {
//     const expirationDate = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
//     const authUserData = {
//       token: UserToken?.token,
//       expires: expirationDate.toISOString(),
//       IPAddress: UserToken?.IPAddress,
//     };
//     Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
//     setIsModalOpen(false);
//   };

//   const handleLogout = () => {
//     history.push('/logout');
//     localStorage.removeItem("paths")
//     window.location.reload();
//   };

//   return (
//     <React.Fragment>
//       <Modal
//         isOpen={isModalOpen}
//         role="dialog"
//         autoFocus={true}
//         centered
//       >
//         <div className="modal-content">
//           <div className="isDEfault_popup_content">
//             <p>Your session is going to expire within 5 minutes. Click "Yes" to extend the session.</p>
//             <small>time left : {timeRemaining}</small>
//             <div className="popup_button_for_session">
//               <Button className="procces_Button mt-3" onClick={extendSession}>Yes</Button>
//               <Button className="procces_Button mt-3" onClick={handleLogout}>Logout</Button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </React.Fragment>
//   );
// };

// export default AuthChecker;


import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";
import { Button, Modal } from 'reactstrap';
import React from 'react';
import { useData } from 'Context/ContextProvider';
import { removeAssigednVpa } from 'Context/ContextApi';

const authUser = Cookies.get("authUsers")
const authUserData = authUser ? JSON.parse(authUser) : '';
const UserToken = authUserData ? authUserData : '';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const AuthChecker = () => {
  const { mData, userDataa, userInfo , socket } = useData();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const formatTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const checkAuthExpiration = async () => {
      const authUserToken = Cookies.get("authUsers");
      if (!authUserToken) {
        await removeAssigednVpa()
        let data = {
          MerchantId: userDataa.id,
          Status: "INACTIVE",
          LastInactiveDateTime: new Date(),
          DateTime: '',
          ...userInfo
        };
        if(socket){
        socket.emit("merchantInfo", JSON.stringify(data));
        }
        history.push('/logout');
        localStorage.removeItem("paths");
      } else {
        const authUserData = JSON.parse(authUserToken);
        const sessionExpiry = new Date(authUserData.expires);
        const currentTime = new Date();
        const timeLeft = sessionExpiry - currentTime;

        if (timeLeft > 0) {
          setTimeRemaining(formatTimeRemaining(timeLeft));
        }
        
        if (timeLeft <= 300000 && timeLeft > 0) {
          setIsModalOpen(true);
        } else {
          setIsModalOpen(false);
        }
      }
    };

    checkAuthExpiration();
    const intervalId = setInterval(checkAuthExpiration, 1000);
    return () => clearInterval(intervalId);
  }, [history, socket, userDataa.id, userInfo]);

  const extendSession = () => {
    const expirationDate = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
    const authUserData = {
      token: UserToken?.token,
      expires: expirationDate.toISOString(),
      IPAddress: UserToken?.IPAddress,
    };
    Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
    setIsModalOpen(false);
  };

  const handleLogout = async () => {
    await removeAssigednVpa()
    history.push('/logout');
    localStorage.removeItem("paths");
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpen}
        role="dialog"
        autoFocus={true}
        centered
      >
        <div className="modal-content">
          <div className="isDEfault_popup_content">
            <p>Your session is going to expire within 5 minutes. Click "Yes" to extend the session.</p>
            {timeRemaining && <small>Time left: {timeRemaining}</small>}
            <div className="popup_button_for_session">
              <Button className="procces_Button mt-3" onClick={extendSession}>Yes</Button>
              <Button className="procces_Button mt-3" onClick={handleLogout}>Logout</Button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AuthChecker;

