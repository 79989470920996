import React, { useState } from "react";
import "./registration.scss";
import logosrc from "../../assets/images/NewImage/Invoice-Logo.png";
import { Col, Row } from "reactstrap";
import { merchanInformation, merchanLoginWithEmail } from "Auth/ApiFunction";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom.min";
import "react-phone-number-input/style.css";
import UnderlineShape from "./underLineSvg";
import LoginWithGoogle from "./login-with-google";
import { useData } from "Context/ContextProvider";
import Cookies from "js-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { io } from "socket.io-client";


const API_BASE_URL = process.env.REACT_APP_API_URL;



const NewLogin = () => {
  const history = useHistory();
  const { iPAddress, userInfo } = useData();
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = {
        Email: values.Email,
        Password_: values.password,
        IPAddress: iPAddress,
      };
      const response = await merchanLoginWithEmail(formData);
      const socket = io(API_BASE_URL);
      toastr.success(response.message);
      if (response && response.token) {
        const expirationDate = new Date(
          new Date().getTime() + 2 * 60 * 60 * 1000
        );
        const authUserData = {
          token: response.token,
          expires: expirationDate.toISOString(),
          IPAddress: iPAddress,
        };
        Cookies.set("authUsers", JSON.stringify(authUserData), {
          expires: expirationDate,
        });
        localStorage.setItem("authUsers", JSON.stringify(response.token));

        let data = {
          MerchantId: response.result.id,
          Status: "ACTIVE",
          LastInactiveDateTime: '',
          DateTime: new Date(),
          ...userInfo
        }
        await merchanInformation(data);
        await socket.emit("merchantInfo", JSON.stringify(data));
        if (response.result?.Register_Status === "COMPLETED") {
          if (response.result?.kycVerified === "ReVerify") {
            history.push("/re-verify");
          } else {
            history.push("/get-dashboard");
          }
        } else {
          history.push("/registration-form2");
        }
      }
    } catch (error) {
        toastr.warning(error?.response?.data?.message || error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  document.title = "GET Platform | Login";

  return (
    <React.Fragment>
      <div className="registration-main-section">
        <div className="registration_logo mb-4">
          <a href="login">
            <img src={logosrc} alt="logo" />
          </a>
          <Link to="/registration">
            <span>Sign Up</span>
          </Link>
        </div>
        <Row>
          <Col lg="4" md="4" sm="12" xs="12">
            <div className="login-form-heading-text">
              <h1>
                <b>
                  Welcome back! Please
                  <span className="text-relative ">
                    Sign in to
                    <UnderlineShape className="text-absolute -bottom-5 start-0 h-2.5 w-24 text-blue md:w-28 xl:-bottom-1.5 xl:w-36" />
                  </span>{" "}
                  continue.
                </b>
              </h1>
              <p>
                By signing up, you will gain access to exclusive content,
                special offers, and be the first to hear about exciting news and
                updates.
              </p>
            </div>
            <LoginWithGoogle />
            <div className="google-or-line-container">
              <div className="line"></div>
              <div className="google-or-line-text">OR</div>
              <div className="line"></div>
            </div>
            <Formik
              initialValues={{ Email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className="pt-2">
                      <div className="registration_form_group">
                        <label>
                          <span>*</span>Email
                        </label>
                        <Field
                          type="text"
                          name="Email"
                          placeholder="Enter your email"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="Email"
                          component="span"
                          className="Onbording-error-message"
                        />
                      </div>
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                      <div className="registration_form_group">
                        <label>
                          <span>*</span>Password
                        </label>
                        <div className="input-group auth-pass-inputgroup">
                          <Field
                            name="password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="true"
                            className="form-control form_control_login"
                          // className={
                          //     "form-control form_control_login" +
                          //     (errors.password && touched.password
                          //     ? " is-invalid"
                          //     : "")
                          // }
                          />
                          <button
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={
                                showPassword
                                  ? "mdi mdi-eye-off-outline"
                                  : "mdi mdi-eye-outline"
                              }
                            ></i>
                          </button>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="span"
                          className="Onbording-error-message"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between mt-2 mb-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      //   checked={rememberMe}
                      //   onChange={this.handleRememberMeChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                        style={{
                          fontFamily: "Gilroy",
                          fontSize: "18px",
                          color: "#000033",
                        }}
                      >
                        Remember me
                      </label>
                    </div>
                    <div className=" text-center">
                      <Link
                        to="/forgot-password"
                        style={{
                          fontFamily: "Gilroy",
                          fontSize: "16px",
                          color: "#000033",
                        }}
                      >
                        <i className="mdi mdi-lock me-1" /> Forgot your
                        password?
                      </Link>
                    </div>
                  </div>
                  <div className="registration_button">
                    <button type="submit" disabled={isSubmitting}>
                      Login
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="do-you-have-account-text text-center">
              <span>
                Don’t have an account? <Link to="registration">Sign Up</Link>
              </span>
            </div>
          </Col>
          <Col lg="1" md="1"></Col>
          <Col lg="7" md="7" sm="12" xs="12">
            <div className="form-right-banner-image">
              <div className="form-left-heading-text mb-3 pt-4">
                <h2>
                  <b>The simplest way to manage your workspace.</b>
                </h2>
              </div>
              <div>
                <img
                  src="https://isomorphic-furyroad.s3.amazonaws.com/public/auth/sign-up.webp"
                  alt="banner"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default NewLogin;












































































