import { addAndUpdatePaynowGetways, fetchGetWaysData } from "Context/ContextApi";
import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Label, Row } from "reactstrap";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

const GetWaysDetails = () => {
    document.title = "GET Platform  | Getways";
    const [validationErrors, setValidationErrors] = useState({});
    const [isForm, setIsForm] = useState({
        id: "",
        Getway_Name: "",
        Method: "",
        Email: "",
        Mobile: "",
        PaynowKey: "",
        PaynowId: "",
    })

    const handlechanges = (e) => {
        const { name, value } = e.target;
        setIsForm({ ...isForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const validateForm = () => {
        let errors = {};
        if (!isForm.Getway_Name.trim()) {
            errors.Getway_Name = "Getway Name is required";
        }
        if (!isForm.Method.trim()) {
            errors.Method = "Method is required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!isForm.Email.trim() || !emailRegex.test(isForm.Email.trim())) {
            errors.Email = "Enter a valid email address";
        }
        const mobileRegex = /^\d{10}$/;
        if (!isForm.Mobile.trim() || !mobileRegex.test(isForm.Mobile.trim())) {
            errors.Mobile = "Enter a valid 10-digit mobile number";
        }
        const minMobileLength = 10;
        const maxMobileLength = 15;
        if (isForm.Mobile.trim().length < minMobileLength || isForm.Mobile.trim().length > maxMobileLength) {
            errors.Mobile = `Mobile number must be between ${minMobileLength} and ${maxMobileLength} digits`;
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmitForm = async () => {
        try {
            const isValid = validateForm();
            if (isValid) {
                const objectData = {
                    Email: isForm.Email,
                    Mobile: isForm.Mobile,
                    ...(isForm.Method === "PaynowAccount" && {
                        PaynowKey: isForm.PaynowKey,
                        PaynowId: isForm.PaynowId,
                    }),
                };
                const formData = {
                    id: isForm?.id | "",
                    Getway_Name: isForm.Getway_Name,
                    Method: isForm.Method,
                    Details: JSON.stringify(objectData)
                }
                const response = await addAndUpdatePaynowGetways(formData)
                toastr.success(response.message)
            }
        } catch (error) {
            toastr.warning(error.message)
        }
    }

    useEffect(() => {
        if (isForm.Method && isForm.Getway_Name) {
            fData(isForm.Getway_Name, isForm.Method)
        }
    }, [isForm?.Getway_Name, isForm?.Method])

    const fData = async (name, method) => {
        try {
            const formData = {
                Getway_Name: name,
                Method: method
            }
            const response = await fetchGetWaysData(formData)
            const result = JSON.parse(response.result.Details)
            setIsForm({ ...response.result, ...result })
        } catch (error) {
            toastr.warning(error?.message)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }} >
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Getways" />
                    <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                        <Row>
                            <Col md={{ offset: 3, size: 6, }}>
                                <h2 className="text-center mb-4 heading_for_progress_bar title-color-common">
                                    PAYMENT GETWAYS
                                </h2>
                                <div className="getWay-formgroup mt-4">
                                    <Label>Getways</Label>
                                    <select name="Getway_Name" value={isForm.Getway_Name} onChange={handlechanges}>
                                        <option selected defaultValue hidden>Select</option>
                                        <option value="Paynow">Paynow</option>
                                    </select>
                                    {validationErrors.Getway_Name && <span >{validationErrors.Getway_Name}</span>}
                                </div>
                                <div className="getWay-formgroup mt-4">
                                    <Label>Method</Label>
                                    <select name="Method" value={isForm.Method} onChange={handlechanges}>
                                        <option selected defaultValue hidden>Select</option>
                                        <option value="PaynowMobile">Paynow Mobile</option>
                                        <option value="PaynowAccount">Paynow Account</option>
                                    </select>
                                    {validationErrors.Method && <span >{validationErrors.Method}</span>}
                                </div>
                                <Row>
                                    {isForm.Method === "PaynowMobile" || isForm.Method === "PaynowAccount" ? (
                                        <>
                                            <Col lg="6" md="6" sm="6" xs="12">
                                                <div className="getWay-formgroup mt-4">
                                                    <Label>Email</Label>
                                                    <Input type="email" placeholder="Enter paynow email" name="Email" value={isForm.Email} onChange={handlechanges} />
                                                    {validationErrors.Email && <span >{validationErrors.Email}</span>}
                                                </div>
                                            </Col>
                                            <Col lg="6" md="6" sm="6" xs="12">
                                                <div className="getWay-formgroup mt-4">
                                                    <Label>Mobile</Label>
                                                    <Input type="number" placeholder="Enter paynow mobile" name="Mobile" value={isForm.Mobile} onChange={handlechanges} />
                                                    {validationErrors.Mobile && <span >{validationErrors.Mobile}</span>}
                                                </div>
                                            </Col>
                                        </>
                                    ) : null}
                                    {isForm.Method === "PaynowAccount" ? (
                                        <>
                                            <Col lg="6" md="6" sm="6" xs="12">
                                                <div className="getWay-formgroup mt-4">
                                                    <Label>Paynow Key</Label>
                                                    <Input type="number" placeholder="Enter paynow key" name="PaynowKey" value={isForm.PaynowKey} onChange={handlechanges} />
                                                </div>
                                            </Col>
                                            <Col lg="6" md="6" sm="6" xs="12">
                                                <div className="getWay-formgroup mt-4">
                                                    <Label>Paynow Id</Label>
                                                    <Input type="text" placeholder="Enter paynow id" name="PaynowId" value={isForm.PaynowId} onChange={handlechanges} />
                                                </div>
                                            </Col>
                                        </>
                                    ) : null}
                                </Row>
                                <Button className="w-100 mt-4 procces_Button" style={{ fontSize: "18px" }} onClick={handleSubmitForm} >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GetWaysDetails